import { Injectable } from '@angular/core';

export enum Language {
  English = 'en',
  Spanish = 'es'
}

@Injectable({
  providedIn: 'root'
})
export class InternationalizationService {

  private language: Language;

  public set lang(lang: Language) { this.language = lang; }
  public get lang(): Language { return this.language; }

  public constructor() {
    this.language = Language.English;
  }

  public switch(): void {
    this.language = (this.language === Language.English) ? Language.Spanish : Language.English;
  }
}
