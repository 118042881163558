<div (click)="onClose()" class="overlay centered">
    <div (click)="onListClick($event)" class="list scrollable large">
        <div
            (click)="onSelectValue(option.value)"
            *ngFor="let option of options; let i = index"
            [ngClass]="{ 'first': i === 0 }"
            class="entry">
            {{ option.text }}
        </div>
    </div>
</div>