<app-header
    [canNavigateBack]="true"
    [showLanguage]="true"
    [redBorder]="true" >
</app-header>
<div class="bundles page d-flex flex-column justify-content-between">
    <!-- <app-carousel
        [items]="bundles"
        [hasGroup]="hasGroup"
        [defaultSlide]="currentBundle"
        (next)="onNext()"
        (swiped)='onChangeIndex($event)'>
    </app-carousel> -->
    <hr />
    <!-- <app-list
        [title]="'RENT.BUNDLES.LIST_TITLE'"
        [items]="currentBundleProducts">
    </app-list>
    <app-progress-tracker [progress]="progress"></app-progress-tracker> -->
</div>
