import { Injectable } from '@angular/core'
import { environment } from '@environment/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, of, zip } from 'rxjs'
import { map } from 'rxjs/operators'
import { Deserialize } from 'cerialize'
import { Card } from 'src/app/domain/card.model'
import { BayBeamStatus, BayDoorStatus, BeamStatus } from 'src/app/domain/bay-beam-status.model'
import { CardTracks } from 'src/app/domain/card-tracks.model'
import { getTestingCardData, getDummyCardTracksData } from 'src/testing/dummy-data'

const INITIAL_BAY_STATUS_STATISTICS_SCOPE = 5

const KIOSK_ID_KEY = 'kiosk_id'
const KIOSK_TOKEN_KEY = 'kiosk_token'

@Injectable({
  providedIn: 'root'
})
export class KioskServerService {

  private pendingUpdate: boolean = false

  public get kioskDevMode(): boolean { return !environment.kiosk }
  public get shouldUpdate(): boolean { return !this.kioskDevMode && this.pendingUpdate }

  public constructor(private http: HttpClient) { }

  public readCard(): Observable<any> {
    if (this.kioskDevMode) {
      return of(getTestingCardData())
    }
    return this.http.get(environment.kioskUrl + 'cards/read').pipe(
      map((card: Card) => {
        return Deserialize(card, Card)
      }))
  }

  public setPendingUpdate() {
    if (this.kioskDevMode) {
      return
    }
    this.pendingUpdate = true
  }

  public openBay(bayId: number): Observable<any> {
    if (this.kioskDevMode) {
      return of({})
    }
    return this.http.get(environment.kioskUrl + 'locks/' + bayId + '/unlock')
  }

  public openAll(numberOfBays: number): Observable<any> {
    if (this.kioskDevMode) {
      return of({})
    }
    return this.http.get(environment.kioskUrl + 'locks/unlock?number_of_bays=' + numberOfBays)
  }

  public closeBay(bayId: number): Observable<any> {
    if (this.kioskDevMode) {
      return of({})
    }
    return this.http.get(environment.kioskUrl + 'locks/' + bayId + '/lock')
  }

  public closeAll(numberOfBays: number): Observable<any> {
    if (this.kioskDevMode) {
      return of({})
    }
    return this.http.get(environment.kioskUrl + 'locks/lock?number_of_bays=' + numberOfBays)
  }

  public statusAll(numberOfBays: number, numberOfBeams: number): Observable<any> {
    if (this.kioskDevMode) {
      return of({})
    }
    return this.http.get(environment.kioskUrl + 'locks/status?number_of_bays=' + numberOfBays + '&number_of_beams=' + numberOfBeams)
  }

  public bayStatus(bayId: number, capacity?: number): Observable<BayBeamStatus> {
    if (this.kioskDevMode) {
      const bayStatus = new BayBeamStatus()
      bayStatus.bayId = bayId
      bayStatus.doorStatus = BayDoorStatus.Closed
      bayStatus.beamStatus = [BeamStatus.Filled]
      return of(bayStatus)
    }
    return this.http.get(environment.kioskUrl + 'locks/' + bayId + '/status?number_of_beams=' + (capacity || 1)).pipe(
      map((bayStatus: BayBeamStatus) => {
        return Deserialize(bayStatus, BayBeamStatus)
      }
      ))
  }

  public initialBayStatus(bayId: number, capacity?: number): Observable<BayBeamStatus> {
    if (this.kioskDevMode) {
      const bayStatus = new BayBeamStatus()
      bayStatus.bayId = bayId
      bayStatus.doorStatus = BayDoorStatus.Closed
      bayStatus.beamStatus = [BeamStatus.Filled]
      return of(bayStatus)
    }
    const statuses: Observable<BayBeamStatus>[] = []
    for (let i = 0; i < INITIAL_BAY_STATUS_STATISTICS_SCOPE; i++) {
      statuses.push(this.bayStatus(bayId, capacity))
    }
    return new Observable<BayBeamStatus>(observer => {
      zip(...statuses).subscribe((results: BayBeamStatus[]) => {
        const instances = []
        results.forEach((result: BayBeamStatus) => {
          let existing = false
          instances.forEach(instance => {
            if (result.compareBeams(instance.value)) {
              instance.count++
              existing = true
            }
          })
          if (!existing) {
            instances.push({ value: result, count: 1 })
          }
        })
        let resultFound = false
        instances.forEach(instance => {
          if (instance.count >= INITIAL_BAY_STATUS_STATISTICS_SCOPE - 1) {
            observer.next(instance.value)
            observer.complete()
            resultFound = true
          }
        })
        if (!resultFound) {
          observer.error('Incohenert data')
        }
      }, error => {
        observer.error(error)
      })
    })
  }

  public readTracks(): Observable<CardTracks> {
    if (this.kioskDevMode) {
      return of(getDummyCardTracksData().deserializedObject)
    }
    return this.http.get(environment.kioskUrl + 'cards/tracks').pipe(
      map((cardTracks: CardTracks) => {
        return Deserialize(cardTracks, CardTracks)
      }))
  }

  public shutDown(): Observable<any> {
    return this.http.get(environment.kioskUrl + 'browser/kill')
  }

  public saveKioskConfig(jde: string, token: string, kioskId: number, kioskEnvironment: string): Observable<any> {
    return this.http.post(environment.kioskUrl + 'kiosk/configuration', { jde, token, kioskId, kioskEnvironment })
  }

  public getKioskConfig(): Observable<any> {
    return this.http.get(environment.kioskUrl + 'kiosk/configuration')
  }
}
