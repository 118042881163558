import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@shared/services';
import { CartService } from '@shared/stores/cart.service';
import { StockService } from '@shared/stores/stock.service';
import { Bundle } from 'src/app/domain/bundle.model';
import { RentalProduct } from 'src/app/domain/rental-product';
import { PresentationItem } from 'src/app/domain/presentation-item.model';
import { ProductGroup, ProductType } from 'src/app/domain/product-type.model';

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {
    public kioskJde: string;
    public get progress(): number { return this.cartService.progress; }
    public get hasGroup(): boolean { return this.cartService.hasGroup(ProductGroup.Attachments); }
    public get cartEmpty(): boolean { return this.cartService.isEmpty; }
    public get cartItems(): PresentationItem <RentalProduct>[] {
        return (this.cartService.items) ? this.cartService.items.map(item => new PresentationItem(item)) : [];
    }
    public get items(): PresentationItem <ProductType>[] {
        return (this.stockService.attachments) ? this.stockService.attachments.map(item => new PresentationItem(item)) : [];
    }
    // public get bundles(): PresentationItem <Bundle>[] {
    //     return (this.stockService.bundles) ? this.stockService.bundles.map(item => new PresentationItem(item)) : [];
    // }

    public constructor(
        private cartService: CartService,
        private stockService: StockService,
        private routingService: RoutingService
    ) { }

    public ngOnInit(): void {
      this.kioskJde = localStorage.getItem('kiosk_jde');
        if (this.cartEmpty && !this.cartService.rental.id) {
            this.stockService.restock().subscribe();
        }
    }

    public onRemove(index: number): void {
        this.cartService.removeAtIndex(index);
        this.routingService.navigateToRent(ProductGroup.Machines);
    }

    // public onSelectBundle(index: number): void {
    //     this.routingService.navigateToBundle(index);
    // }

    public onBack(): void {
        this.routingService.navigateToRent(ProductGroup.Machines);
    }

    public onNext(): void {
        this.routingService.navigateToRent(ProductGroup.Solutions);
    }
}
