<div class="header d-flex justify-content-between align-items-center" [ngClass]="{ 'test-mode': testMode }">
  <div class="back d-flex" >
    <div class="d-flex" (click)="onBack()" *ngIf="canNavigateBack">
      <img *ngIf="!testMode" src="/assets/img/arrow-back.svg" alt="">
      <img *ngIf="testMode" src="/assets/img/arrow-back-white.svg" alt="">
      <p>{{ ( customBackText || "GLOBAL.BACK" ) | translate}}</p>
    </div>
  </div>
  <div class="logo">
    <img *ngIf="!testMode" src="/assets/img/logo.svg" alt="RugDoctor">
    <span *ngIf="testMode" class="test-mode-title">TestMode</span>
  </div>
  <div *ngIf="!testMode" (click)="onSwitchLanguage()" class="right-panel d-flex justify-content-end align-items-center">
    <div *ngIf="showLanguage" class="panel-button" [ngClass]="{ 'red': redBorder }">
      {{ 'GLOBAL.LANGUAGE_SHORT' | translate }}
    </div>
  </div>
  <div *ngIf="testMode" (click)="onExitTestMode()" class="right-panel d-flex justify-content-end align-items-center">
    <div class="panel-button">
      EXIT
    </div>
  </div>
</div>
