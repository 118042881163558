import { autoserialize, autoserializeAs } from 'cerialize'
import { Price } from './price.model'

export enum ProductGroup {
  Machines = 'machine',
  Attachments = 'attachment',
  Solutions = 'solution',
  Bundle = 'bundle'
}

export enum ProductEnvironment {
  Indoor = 'indoor',
  Outdoor = 'outdoor'
}

export class ProductType {
  @autoserialize id: number
  @autoserializeAs('quantity_available') quantity: number
  // @autoserializeAs('actual_quantity_available') actual_quantity: number = 0;
  @autoserializeAs('actual_quantity_available') actual_quantity: number = 1;
  @autoserializeAs('quantity_available') quantity_available: number
  @autoserializeAs('name') name: string
  @autoserializeAs('subtitle') subtitle: string
  @autoserializeAs('details') details: string
  @autoserializeAs('image_url') imageName: string
  @autoserializeAs('upc_number') upcNumber: string
  @autoserializeAs('sku_number') skuNumber: string
  @autoserializeAs('product_code') productCode: string
  @autoserializeAs('product_number') productNumber: string
  @autoserializeAs('product_group') productGroup: ProductGroup
  @autoserializeAs(Price, 'price') priceDetails: Price
  @autoserializeAs('description_1') description1: string
  @autoserializeAs('description_2') description2: string
  @autoserializeAs('description_3') description3: string
  public imageUrl: string
  public environment: ProductEnvironment
  public compatibleWith: ProductType[]
  public get price(): number { return this.priceDetails?.retailPrice }
  public constructor() { }
  public static OnSerialized(instance: ProductType, json: any): void {
    json.indoor = instance.environment === ProductEnvironment.Indoor
  }
  public static OnDeserialized(instance: ProductType, json: any): void {
    instance.imageUrl = json.photo?.url
    instance.environment = (json.indoor) ? ProductEnvironment.Indoor : ProductEnvironment.Outdoor
  }
}
