import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Deserialize } from 'cerialize';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service';
import { Coupon } from 'src/app/domain/coupon.model';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  public constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  public getCouponsByCode(code: string): Observable<Coupon[]> {
    return this.http.get(environment.baseUrl + 'coupons?coupon_code=' + code, { headers: this.auth.kioskAuthHeader } ).pipe(
      map((coupon: Coupon) => {
          return Deserialize(coupon, Coupon);
        }
    ));
  }
}
