import { Component, Output, EventEmitter } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { AdminService } from '@shared/services/admin.service'
import { Kiosk } from 'src/app/domain/kiosk.model'
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'
import { KioskServerService } from '@shared/services/kiosk-server.service'
import { environment } from '@environment/environment'

const ERROR_MESSAGE = 'Cannot find kiosk with this JDE.'

@Component({
  selector: 'app-update-kiosk-jde',
  templateUrl: './update-kiosk-jde.component.html'
})
export class UpdateKioskJdeComponent {

  @Output() completed: EventEmitter<void>
  public error: string
  public input: FormControl

  public constructor(
    private adminService: AdminService,
    private authService: AuthenticationService,
    private kioskServerService: KioskServerService
  ) {
    this.completed = new EventEmitter()
    this.input = new FormControl('', [Validators.required])
  }

  public onRegisterKiosk(): void {
    if (!this.input.valid) {
      return
    }
    this.adminService.findKioskByJDE(this.input.value).subscribe((kiosks: Kiosk[]) => {
      if (kiosks.length > 0) {
        this.error = null
        this.authService.registerKioskId(kiosks[0].id.toString()).subscribe(data => {
          localStorage.setItem('kiosk_jde', this.input.value.toString())

          this.kioskServerService.saveKioskConfig(data.jde_number.toString(), data.authorization.token.toString(), data.id, environment.name)
            .subscribe(next => {
              this.completed.emit()
              // reload to reestablish socket connection to backend
              window.location.reload()
            },
              error => {
                this.completed.emit()
                window.location.reload()
              })
        })
      } else {
        this.error = ERROR_MESSAGE
      }
    })
  }
}
