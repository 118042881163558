import { BayType } from "./bay.model"
import { RentalProduct } from './rental-product'

export enum BayTransactionActionType {
  PickFromBay = 'pickFromBay',
  ReturnToBay = 'returnToBay',
  PickUpShelf = 'pickFromShelf'
}

export class BayTransactionAction {

  public type: BayTransactionActionType
  public items: RentalProduct[]
  public get name(): string { return (this.items.length > 0 && this.items[0].type) ? this.items[0].type.name : null }
  public get quantity(): number { return (this.items.length > 0) ? this.items[0].quantity : 0 }
  public get number(): number { return (this.items.length > 0 && this.items[0].bay) ? this.items[0].bay.number : null }
  public get bay_type(): BayType { return (this.items.length > 0 && this.items[0].bay) ? this.items[0].bay.type : null }
  public get capacity(): number { return (this.items.length > 0 && this.items[0].bay) ? this.items[0].bay.capacity : 1 }
  // Used to calculate expected change in currently occupied beams in a bay
  // used with BayBeamStatus's beamStatusCount to determine (pick up / return) validity
  public get expectedBeamDiff(): number {
    if (this.type === BayTransactionActionType.PickUpShelf) {
      return 0
    }
    const total: number = this.items.reduce((result, item: RentalProduct) => result += item.quantity, 0)
    return (this.type === BayTransactionActionType.PickFromBay) ? -1 * total : total
  }
  public get displayText(): string {
    return (this.quantity >= 2) ? this.quantity + ' X ' + this.name : this.name
  }

  constructor(
    type?: BayTransactionActionType,
    items?: RentalProduct[]
  ) {
    this.type = type
    this.items = items
  }
}
