<app-header
    (back)="onBack()"
    [customBackNavigation]="true"
    [canNavigateBack]="true"
    [showLanguage]="true"
    [redBorder]="true">
</app-header>
<div class="attachments page d-flex flex-column justify-content-between">
    <app-carousel
        [items]="items"
        [hasGroup]="hasGroup"
        (next)="onNext()" >
    </app-carousel>
    <hr />
    <!-- <app-list
        *ngIf="cartEmpty"
        [title]="'RENT.BUNDLES.LIST_TITLE'"
        [items]="bundles"
        [selectable]="true"
        (selected)="onSelectBundle($event)" >
    </app-list> -->
    <app-list
        *ngIf="!cartEmpty"
        [autoScroll]="true"
        [title]="'RENT.CART.TITLE'"
        [items]="cartItems"
        [pluses]="true"
        [removable]="true"
        (remove)="onRemove($event)">
    </app-list>
    <!-- <app-progress-tracker [progress]="progress"></app-progress-tracker> -->
    <div class="contact d-flex justify-content-between">
      <!--     TODO (877) 343-0588 is correct but admin should be able to change it-->
      <!--     TODO 8774704111 = mrent is correct but admin should be able to change it-->
            <p>For assistance call: (877) 343-0588</p>
            <p>Kiosk JDE: {{kioskJde ? kioskJde : '/'}}</p>
      </div>
</div>
