import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Bay, BayState } from 'src/app/domain/bay.model';
import { BayStatus } from 'src/app/domain/bay-status.model';
import { AdminService } from '@shared/services/admin.service';

const UNKNOWN_STATE = 'Unknown';

@Component({
    selector: 'app-bay-configuration',
    templateUrl: './bay-configuration.component.html',
    styleUrls: ['./bay-configuration.component.scss']
})
export class BayConfigurationComponent implements OnInit {

    @Output() baySelected: EventEmitter<BayStatus>;
    @Output() newBay: EventEmitter<boolean>;
    public bayStatus: BayStatus;
    public productBays: BayStatus[];
    public loading = false;

    public constructor(
        private adminService: AdminService
    ) {
        this.baySelected = new EventEmitter();
        this.newBay = new EventEmitter();
    }

    public ngOnInit(): void {
      this.loading = true;
        this.adminService.getBays().subscribe((kioskBays: Bay[]) => {
            const bays: any = {};
            kioskBays.forEach(bay => bays[bay.id] = new BayStatus(bay));
            this.adminService.getProductsPerBays().subscribe(productsPerBays => {
                productsPerBays.forEach(bayStatus => {
                    if (bays[bayStatus.bay.id]) {
                        bays[bayStatus.bay.id].products.push(...bayStatus.products);
                    }
                });
                this.productBays = Object.keys(bays).map(key => bays[key]).sort((a, b) => a.bay.number - b.bay.number);
                this.loading = false;
            });
        });
    }

    public onSelectBay(bayStatus: BayStatus): void {
        this.baySelected.emit(bayStatus);
    }

    public onNewBay(): void {
      this.newBay.emit(true);
    }

    public formatState(state: BayState): string {
        if (!state) {
            return UNKNOWN_STATE;
        }
        return state.charAt(0).toUpperCase() + state.substr(1);
    }
}
