<div class="gallery">
    <div class="empty d-flex justify-content-center" *ngIf="!items">
        <h1 class="text-center"> {{ 'RENT.EMPTY_LIST' | translate | uppercase}} </h1>
    </div>
    <div *ngIf="items" class="line">
        <h1 [ngClass]="{ 'short':!gridView }">{{ 'RENT.SOLUTIONS.TITLE' | translate | uppercase }}</h1>
    </div>
    <div *ngIf="items" class="d-flex justify-content-between switch-header">
        <div class="d-flex justify-content-between filters">
            <label
                id="filterIndoor"
                *ngIf="filtersEnabled && view === 'grid'"
                (click)="onApplyFilter('indoor')"
                [ngClass]="{ 'active':filter === 'indoor' }"
                class="filter d-flex align-items-center justify-content-center">
                {{ 'RENT.SOLUTIONS.INDOOR' | translate | uppercase }}
            </label>
            <label
                id="filterOutdoor"
                *ngIf="filtersEnabled && view === 'grid'"
                (click)="onApplyFilter('outdoor')"
                [ngClass]="{ 'active':filter === 'outdoor' }"
                class="filter d-flex align-items-center justify-content-center">
                {{ 'RENT.SOLUTIONS.OUTDOOR' | translate | uppercase }}
            </label>
        </div>
        <div class="d-flex justify-content-between switcher">
            <div id="viewGrid" (click)="onChangeView('grid')" class="d-flex align-items-center justify-content-center btn">
                <img [src]="(view === 'grid') ? 'assets/img/grid-white.svg' : 'assets/img/grid-gray.svg'" alt="grid"/>
            </div>
            <div id="viewGallery" (click)="onChangeView('gallery')" class="d-flex align-items-center justify-content-center btn">
                <img [src]="(view === 'gallery') ? 'assets/img/gallery-white.svg' : 'assets/img/gallery-gray.svg'" alt="gallery"/>
            </div>
            <div class="indicator" [ngClass]="{ 'right': view === 'gallery' }"></div>
        </div>
    </div>
    <div *ngIf="items && view === 'grid'" class="grid-container">
        <div (scroll)="onGridScroll($event)" class="grid-scroller scrollable large">
            <div class="grid">
                <div *ngFor='let item of filtered; let i = index' class="item">
                    <div>
                        <p class="title">{{ item.name | uppercase }}</p>
                        <p class="price">${{ item.price }}</p>
                    </div>
                    <div class="btn-rug rent-button" [ngClass]="{ 'quantity-mode': quantity(i) > 0 }" (click)="onAdd(i)">
                        <div class="add-button d-flex justify-content-center align-items-center" *ngIf="quantity(i) === 0">
                            {{ 'RENT.ADD_TO_CART' | translate | uppercase }}
                        </div>
                        <div class="quantity d-flex justify-content-between align-items-center" *ngIf="quantity(i) > 0">
                            <span id="lower" class="change d-flex justify-content-center" [ngClass]="{ 'disabled': quantity(i) === 1 }" (click)="changeQuantity(i, -1)"> - </span>
                            <span class="value">{{quantity(i)}}</span>
                            <span id="raise" class="change d-flex justify-content-center" (click)="changeQuantity(i, 1)"> + </span>
                        </div>
                    </div>
                    <img class="image" [src]="item.imageUrl" alt="items"/>
                </div>
            </div>
        </div>
        <div class="curtain upper" [ngClass]=" { 'hidden': gridScrollHitTop } "></div>
        <div class="curtain lower" [ngClass]=" { 'hidden': gridScrollHitBottom } "></div>
    </div>
    <ngx-slick-carousel
        *ngIf="items && view === 'gallery'"
        (beforeChange)="onBeforeChange($event)"
        class="carousel"
        #slickGallery="slick-carousel"
        [config]="config"
    >
        <div *ngFor="let item of filtered; let i = index; trackBy: slidesTrackBy" ngxSlickItem class="slide" [ngClass]="{'active': i === currentSlide}">
            <div class="panel">
                <div>
                    <p class="title">{{ item.name | uppercase }}</p>
                    <p class="subtitle" *ngIf="item.subtitle">{{ item.subtitle | uppercase }}</p>
                    <p class="price">${{ item.price }}</p>
                    <ul class="bullet-points" *ngIf="item.description1 || item.description2 || item.description3">
                      <li *ngIf="item.description1" [innerHTML]="item.description1"></li>
                      <li *ngIf="item.description2" [innerHTML]="item.description2"></li>
                      <li *ngIf="item.description3" [innerHTML]="item.description3"></li>
                    </ul>
                    <button class="btn-rug learn-more" (click)="openDescription(item)">Learn More</button>
                </div>
                <div class="d-flex justify-content-center">
                    <span class="btn-rug rent-button left" [ngClass]="{ 'quantity-mode': quantity(i) > 0 }" (click)="onAdd(i)">
                        <div class="add-button d-flex" *ngIf="quantity(i) === 0">
                            {{ 'RENT.ADD_TO_CART' | translate | uppercase }}
                            <img src="assets/img/shopping-bag.svg" alt="cart"/>
                        </div>
                        <div class="d-flex justify-content-between align-items-center quantity"  *ngIf="quantity(i) > 0">
                            <span>{{ 'RENT.QUANTITY' | translate | uppercase }}</span>
                            <div class="amount">
                                <span id="lower" [ngClass]="{ 'disabled': quantity(i) === 1 }" (click)="changeQuantity(i, -1)"> - </span>
                                <span class="value">{{quantity(i)}}</span>
                                <span id="raise" (click)="changeQuantity(i, 1)"> + </span>
                            </div>
                        </div>
                    </span>
                </div>
                <img class="image" [src]="item.imageUrl" alt="item"/>
            </div>
        </div>
    </ngx-slick-carousel>
    <div *ngIf="items && view === 'gallery' && currentSlide !== 0" (click)="toPreviousSlide()" class="btn-rug d-flex justify-content-center align-items-center arrow left">
        <img src="assets/img/arrow-left-white-48.svg" alt="left"/>
    </div>
    <div  *ngIf="items && view === 'gallery' && currentSlide !== items.length - 1" (click)="toNextSlide()" class="btn-rug d-flex justify-content-center align-items-center arrow right">
        <img src="assets/img/arrow-right-white-48.svg" alt="right"/>
    </div>
    <div *ngIf="items" class="d-flex flex-column justify-content-start align-items-center footer" [ngClass]="{ 'short': view === 'grid' }">
        <div class="bullets">
            <app-bullets *ngIf="view === 'gallery'" [index]="currentSlide" [length]="items.length"></app-bullets>
        </div>
        <div class="d-flex flex-column justify-content-center buttons" [ngClass]="{ 'has-group': hasGroup }">
            <span
                id="skipStep"
                *ngIf="!hasGroup"
                (click)="onNext()"
                class="btn-rug hollow skip-step d-flex justify-content-center align-items-center">
                {{ 'RENT.SKIP_STEP' | translate | uppercase }}
            </span>
            <span
                id="nextStep"
                *ngIf="hasGroup"
                (click)="onNext()"
                class="btn-rug checkout d-flex justify-content-center align-items-center"
                [ngClass]="{ 'short': view === 'grid' }">
                {{ 'RENT.CHECKOUT' | translate | uppercase }}
                <img src="assets/img/arrow-right-white.svg" alt="right" />
            </span>
        </div>
    </div>
</div>
<app-popup [presentationItem]="descriptionItem" [visible]="showDescription" (hide)="onHideDescription()"></app-popup>
