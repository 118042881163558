import {autoserialize, autoserializeAs} from 'cerialize';

export class Price {
    @autoserialize id: number;
    @autoserializeAs('retail_price') retailPrice: number;
    @autoserializeAs('wholesale_price') wholesalePrice: number;
    public description: string[];
    public price: number;
    public constructor(
        id?: number,
        retailPrice?: number,
        wholesalePrice?: number
    ) {
        this.id = id;
        this.retailPrice = retailPrice;
        this.wholesalePrice = wholesalePrice;
    }
}
