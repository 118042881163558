<div class="summary-price">
    <div class="d-flex justify-content-between align-items-center">
        <span class="label">{{ 'RENT.SUMMARY.SUBTOTAL' | translate | uppercase }}</span>
        <span class="amount">${{ subtotal | number:'0.2-2' }}</span>
    </div>
    <div class="d-flex justify-content-between align-items-center">
        <span class="label">{{ 'RENT.SUMMARY.COUPON' | translate | uppercase }}</span>
        <span class="amount">${{ coupon | number:'0.2-2' }}</span>
    </div>
    <div class="d-flex justify-content-between align-items-center">
        <span class="label">{{ 'RENT.SUMMARY.TAX' | translate | uppercase }}</span>
        <span class="amount">${{ tax | number:'0.2-2' }}</span>
    </div>
    <hr/>
    <div class="d-flex justify-content-between align-items-center">
        <span class="label">{{ 'RENT.SUMMARY.TOTAL' | translate | uppercase }}</span>
        <span class="amount">${{ total | number:'0.2-2' }}</span>
    </div>
    <div *ngIf="loadingPrice" class="modal-summary-price">
        <div class="modal-spinner-container">
            <div class="modal-spinner"></div>
        </div>
    </div>
</div>
