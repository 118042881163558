<app-header [canNavigateBack]="false" [showLanguage]="true"></app-header>
<div class="content d-flex flex-column justify-content-between align-items-baseline">
    <div class="scrollable">
        <h1 class="heading">{{ "TUTORIAL.HOW_TO_USE.TITLE" | translate }}</h1>
        <div class="video-box">
            <video *ngIf="lang == 'en'" autoplay class="video" loop>
                <source src="assets/video/htu-en.mp4" type="video/mp4">
                <source src="assets/video/htu-en.webm" type="video/webm">
            </video>
            <video *ngIf="lang == 'es'" autoplay class="video" loop>
                <source src="assets/video/htu-es.mp4" type="video/mp4">
                <source src="assets/video/htu-es.webm" type="video/webm">
            </video>
        </div>
    </div>
    <button (click)="routingService.navigateBack()" class="btn-rug">
        <p>{{ "GLOBAL.BACK" | translate }}</p>
    </button>
</div>
