<app-header
  [canNavigateBack]="true"
  [showLanguage]="true"
  [redBorder]="true"></app-header>
<div class="page d-flex flex-column justify-content-between">
    <div class="coupon d-flex flex-column justify-content-between">
        <h1>{{ 'RENT.COUPON.TITLE' | translate | uppercase }}</h1>
        <div *ngIf="!error && !discount" class="w-100 d-flex flex-column input-label">
            <label class="d-flex align-items-center justify-content-center" [ngClass]="{ 'placeholder': input.value.length === 0 }">
                {{input.value || ('RENT.COUPON.PLACEHOLDER' | translate)}}
            </label>
        </div>
        <div *ngIf="error" class="w-100 d-flex flex-column align-items-center error">
            <img src="assets/img/error.svg" alt="error"/>
            <label class="d-flex flex-column">
                {{ error | translate }}
            </label>
        </div>
        <div *ngIf="discount" class="w-100 d-flex flex-column align-items-center amount">
          <label *ngIf="couponErrorMsg !== ''" class="d-flex flex-column align-items-center">
            <span class="value">{{ couponErrorMsg }}</span>
        </label>
          <label *ngIf="couponErrorMsg === ''" class="d-flex flex-column align-items-center">
              {{ 'RENT.COUPON.YOU_WILL_GET' | translate | uppercase }}
              <span class="value">{{ discount }}</span>
              {{ 'RENT.COUPON.OF_DISCOUNT' | translate | uppercase }}
          </label>
        </div>
        <div *ngIf="!error && !discount" class="keyboard">
            <app-keyboard [value]="input.value" [formControl]="input" [layout]="KeyboardLayout.Numeric" [disableTempCaps]="true"></app-keyboard>
        </div>
        <div class="d-flex justify-content-center">
            <button
                (click)="onContinue()"
                class="btn-rug confirm-button short continue"
                [ngClass]="{ 'disabled': !error && !discount && !this.input.valid }">
                {{ continueText | translate | uppercase }}</button>
        </div>
    </div>
    <img class="decoration-path" src="assets/img/path.svg" alt="path"/>
    <img class="decoration-background" src="assets/img/bays-background.jpg" alt="bays"/>
</div>
