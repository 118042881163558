import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductService } from '@shared/services/product.service';
import { ProductGroup, ProductType } from 'src/app/domain/product-type.model';
import { BayProductEntry } from 'src/app/domain/bay-product-entry.model';

@Component({
  selector: 'app-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss']
})
export class AttachmentListComponent implements OnInit {

  @Input() maximum: number;
  @Input() existing: BayProductEntry[];
  @Output() changed: EventEmitter<any[]>;
  public selectorIndex: number;
  public attachments: ProductType[];
  public options: any[];
  public selected: any[];

  public constructor(
    private productService: ProductService
  ) {
    this.selected = [];
    this.changed = new EventEmitter();
  }

  public get totalCount(): number {
    let total = 0;
    this.selected.forEach(entry => total += entry.quantity);
    return total;
  }

  public ngOnInit(): void {
    if (this.existing) {
      this.selected = this.existing.map(entry => {
        return {type: entry.type, quantity: entry.quantity, minimum: entry.quantityRented};
      });
      this.changed.emit(this.selected);
    } else {
      this.selected = [];
    }
    this.productService.getTypes(ProductGroup.Attachments).subscribe(attachments => {
      this.attachments = attachments;
      this.recalculateOptions();
    });
  }

  public isChangeable(index: number, delta: number): boolean {
    if (this.totalCount >= this.maximum && delta > 0) {
      return false;
    }
    return !(this.selected[index].quantity <= this.selected[index].minimum && delta < 0);
  }

  public onChangeQuantity(index: number, delta: number): void {
    if (!this.isChangeable(index, delta)) {
      return;
    }
    if (this.selected[index].quantity === 1 && delta < 0) {
      this.onRemove(index);
      return;
    }
    this.selected[index].quantity += delta;
    this.changed.emit(this.selected);
  }

  public onAddAttachment(): void {
    if (this.options.length === 0 || this.totalCount >= this.maximum) {
      return;
    }
    this.selected.push({type: this.options[0].value, quantity: 1, minimum: 0});
    this.changed.emit(this.selected);
    this.recalculateOptions();
  }

  public onRemove(index: number): void {
    this.selected.splice(index, 1);
    this.changed.emit(this.selected);
    this.recalculateOptions();
  }

  public onShowSelector(index: number): void {
    if (this.selected[index].minimum !== 0 || this.options.length === 0) {
      return;
    }
    this.selectorIndex = index;
  }

  public onCloseSelector(): void {
    this.selectorIndex = null;
  }

  public onSelected(value: ProductType): void {
    this.selected[this.selectorIndex].type = value;
    this.selectorIndex = null;
    this.recalculateOptions();
  }

  private recalculateOptions(): void {
    const attachmentOptions: ProductType[] = this.attachments.filter(option => {
      let isSelected = false;
      this.selected.forEach(entry => {
        if (entry.type.id === option.id) {
          isSelected = true;
        }
      });
      return !isSelected;
    });
    this.options = attachmentOptions.map(option => ({text: option.name, value: option}));
  }
}
