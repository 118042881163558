import { autoserialize } from 'cerialize';

export class Venue {
    @autoserialize id: number;
    @autoserialize name: string;
    @autoserialize address1: string;
    @autoserialize address2: string;
    @autoserialize city: string;
    @autoserialize state: string;
    @autoserialize zip: string;
    public constructor() { }
}
