import { autoserialize, autoserializeAs } from 'cerialize'
import { Venue } from './venue.model'
import { Bay } from './bay.model'

export class Kiosk {
  @autoserialize id: number
  @autoserializeAs('jde_number') jdeNumber: number
  @autoserializeAs('last_service_date') lastService: string
  @autoserializeAs(Venue, 'venue') venue: Venue
  @autoserializeAs(Bay, 'bays') bays: Bay[]
  @autoserializeAs('chemicals_sell') chemicals_sell: boolean;
  @autoserializeAs('reservation_support') reservation_support: boolean;
  @autoserializeAs('bay_numeric') bayNumeric: number;
  @autoserializeAs('uph_lock') uphLock: boolean;
  public constructor() { }
}
