import { Component, OnChanges, Input, forwardRef, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const ALT = 'Symbols';

export enum KeyboardLayout {
  Alpha = 'letters',
  Numeric = 'numeric',
  Email = 'email'
}

export enum SpecialChars {
  Shift = 'SHIFT',
  Space = 'SPACE',
  Backspace = 'BACKSPACE',
  Clear = 'CLEAR',
  Switch = 'SWITCH'
}

const DEFUALT_LAYOUTS = {
  [KeyboardLayout.Alpha]: [
    { chars: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'], padding: 0 },
    { chars: ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'], padding: 5 },
    { chars: [SpecialChars.Shift, 'Z', 'X', 'C', 'V', 'B', 'N', 'M', SpecialChars.Backspace], padding: 0 },
    { chars: [SpecialChars.Switch, SpecialChars.Space, SpecialChars.Clear], padding: 0 }
  ],
  [KeyboardLayout.Alpha + ALT]: [
    { chars: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], padding: 0 },
    { chars: ['$', '!', '~', '&', '=', '#'], padding: 5 },
    { chars: ['@', '.', '_', '-', '+', SpecialChars.Backspace], padding: 0 },
    { chars: [SpecialChars.Switch, SpecialChars.Space, SpecialChars.Clear], padding: 0 }
  ],
  [KeyboardLayout.Email]: [
    { chars: ['@gmail.com', '@outlook.com', '@yahoo.com', '@icloud.com'], padding: 0 },
    { chars: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'], padding: 0 },
    { chars: ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'], padding: 5 },
    { chars: ['.com', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', SpecialChars.Backspace], padding: 0 },
    { chars: [SpecialChars.Switch, '@', SpecialChars.Space, '.', SpecialChars.Clear], padding: 0 }
  ],
  [KeyboardLayout.Email + ALT]: [
    { chars: ['@gmail.com', '@outlook.com', '@yahoo.com', '@icloud.com'], padding: 0 },
    { chars: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], padding: 0 },
    { chars: ['$', '!', '~', '&', '=', '#'], padding: 5 },
    { chars: ['@', '.', '_', '-', '+', SpecialChars.Backspace], padding: 0 },
    { chars: [SpecialChars.Switch, SpecialChars.Space, SpecialChars.Clear], padding: 0 }
  ],
  [KeyboardLayout.Numeric]: [
    { chars: ['1', '2', '3'], padding: 15 },
    { chars: ['4', '5', '6'], padding: 15 },
    { chars: ['7', '8', '9'], padding: 15 },
    { chars: [SpecialChars.Clear, '0', SpecialChars.Backspace], padding: 15 },
  ]
};

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KeyboardComponent),
      multi: true
    }
  ]
})
export class KeyboardComponent implements OnChanges, OnInit, ControlValueAccessor {

  @Input() layout: string;
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() disableTempCaps: boolean;
  @Output() valueChanged: EventEmitter<string>;
  public currentLayoutKey: string;
  public capsLock: boolean;
  public tempCaps: boolean;
  public get activeLayout(): any { return DEFUALT_LAYOUTS[this.currentLayoutKey]; }
  public get switchLabel(): string {
    return (this.currentLayoutKey.indexOf(ALT) > -1) ? 'ABC' : '@123';
  }
  public constructor() {
    this.capsLock = true;
    this.tempCaps = true;
    this.currentLayoutKey = KeyboardLayout.Alpha;
    this.valueChanged = new EventEmitter();
  }
  public ngOnInit(): void {
    document.addEventListener("keydown", (event) => {
      this.onKeyboardPress(event.key)
    });
    if (!this.value) {
      this.value = '';
    }
    if (this.layout === KeyboardLayout.Email || this.disableTempCaps) {
      this.capsLock = false;
      this.tempCaps = false;
    }
    if (this.layout === KeyboardLayout.Email) {
      this.currentLayoutKey = KeyboardLayout.Email;
    }
  }
  public ngOnChanges(): void {
    if (this.currentLayoutKey === KeyboardLayout.Numeric.toString() && this.layout !== KeyboardLayout.Numeric.toString() ||
      this.currentLayoutKey !== KeyboardLayout.Numeric.toString() && this.layout === KeyboardLayout.Numeric.toString()) {
      this.currentLayoutKey = this.layout || KeyboardLayout.Alpha;
    }
    if (this.value.length === 0 && this.layout !== KeyboardLayout.Email && !this.disableTempCaps) {
      this.capsLock = true;
      this.tempCaps = true;
    }
  }
  public onPress(key: string): void {
    if (this.disabled) {
      return;
    }
    switch (key) {
      case 'SWITCH':
        this.switchLayout();
        break;
      case 'SHIFT':
        this.capsLock = !this.capsLock;
        this.tempCaps = false;
        break;
      case 'CLEAR':
        this.value = '';
        this.capsLock = this.currentLayoutKey !== KeyboardLayout.Email;
        this.tempCaps = true;
        break;
      case 'BACKSPACE':
        this.value = this.value.slice(0, -1);
        break;
      case 'SPACE':
        this.value += ' ';
        if (!this.capsLock && !this.disableTempCaps) {
          this.capsLock = true;
          this.tempCaps = true;
        }
        break;
      default:
        this.value += (this.capsLock) ? key : key.toLowerCase();
        if (this.tempCaps && this.capsLock) {
          this.capsLock = false;
          this.tempCaps = false;
        }
    }
    this.writeValue();
  }
  public onKeyboardPress(key: string): void {
    if (this.disabled) {
      return;
    }
    switch (key) {
      case 'SWITCH':
        this.switchLayout();
        break;
      case 'SHIFT':
        this.capsLock = !this.capsLock;
        this.tempCaps = false;
        break;
      case 'CLEAR':
        this.value = '';
        this.capsLock = this.currentLayoutKey !== KeyboardLayout.Email;
        this.tempCaps = true;
        break;
      case 'BACKSPACE':
        this.value = this.value.slice(0, -1);
        break;
      case 'Backspace':
        this.value = this.value.slice(0, -1);
        break;
      case 'SPACE':
        this.value += ' ';
        if (!this.capsLock && !this.disableTempCaps) {
          this.capsLock = true;
          this.tempCaps = true;
        }
        break;
      case 'Space':
        this.value += ' ';
        if (!this.capsLock && !this.disableTempCaps) {
          this.capsLock = true;
          this.tempCaps = true;
        }
        break;
      default:
        if(key.length === 1 || key.includes('@') || key.includes('com')){
          this.value += key
        }

    }
    this.writeValue();
  }
  public isSpecial(key: string): boolean {
    let special = false;
    Object.keys(SpecialChars).forEach(specialKey => {
      if (SpecialChars[specialKey] === key) {
        special = true;
      }
    });
    return special;
  }
  public switchLayout(): void {
    if (this.layout === KeyboardLayout.Email) {
      if (this.currentLayoutKey === KeyboardLayout.Email.toString()) {
        this.currentLayoutKey = KeyboardLayout.Email + ALT;
      } else {
        this.currentLayoutKey = KeyboardLayout.Email.toString();
      }
    } else {
      if (this.currentLayoutKey === KeyboardLayout.Alpha.toString()) {
        this.currentLayoutKey = KeyboardLayout.Alpha + ALT;
      } else {
        this.currentLayoutKey = KeyboardLayout.Alpha.toString();
      }
    }
  }
  // ControlValueAccessor Implementation
  public writeValue(): void {
    this.valueChanged.emit(this.value);
    this.onChange(this.value);
  }
  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  public onChange(value: string) { }
  public onTouched() { }
}
