import { RentalProduct } from './rental-product';
import { Reservation } from './reservation.model';

export enum BayTransactionType {
  Rent = 'rent',
  Return = 'return'
}

export class BayTransaction {

  public reservation: Reservation;
  public type: BayTransactionType;
  public get bayItems(): RentalProduct[] {
    return this.reservation.products.filter(rentalProduct => rentalProduct.isPlaced);
  }
  public get shelfItems(): RentalProduct[] {
    return this.reservation.products.filter(rentalProduct => !rentalProduct.isPlaced);
  }
  public get isShelfOnly(): boolean {
    return this.reservation.isShelfOnly;
  }

  constructor(
    reservation?: Reservation,
    type?: BayTransactionType
  ) {
    this.reservation = reservation;
    this.type = type;
  }
}
