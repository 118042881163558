import { autoserialize } from 'cerialize';
import { Authorization } from './authorization.model';

export class User {
  @autoserialize id: number;
  @autoserialize email: string;
  @autoserialize username: string;
  @autoserialize password: string;
  @autoserialize authorization: Authorization;
  public constructor() { }
}
