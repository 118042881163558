<app-header
  (back)="onBack()"
  [customBackText]="((this.currentStep == 'options') ? 'GLOBAL.EXIT' : null)"
  [canNavigateBack]="true"
  [customBackNavigation]="true"
  [showLanguage]="true"
  [redBorder]="true"></app-header>
<div class="return page d-flex flex-column" [ngClass]="{ 'confirm': currentStep === 'confirm' }">
    <div class="w-100 title" [ngClass]="{ 'centered': step.message || currentStep === 'confirm' }">
        <h1 [ngClass]="{ 'long': step && step.titleLong }">
            {{ step.title | translate | uppercase }}
        </h1>
    </div>
    <div *ngIf="currentStep === 'options'" class="w-100 d-flex flex-column justify-content-between options">
        <button id='cardBtn' class="btn-rug confirm-button" (click)="onStepChange('card')" *ngIf="!complete">{{ 'RETURN.OPTIONS.CARD' | translate | uppercase }}</button>
        <button id='nameBtn' class="btn-rug confirm-button" (click)="onStepChange('name')" *ngIf="!complete">{{ 'RETURN.OPTIONS.NAME' | translate | uppercase }}</button>
        <button id='serialBtn' class="btn-rug confirm-button" (click)="onStepChange('serial')" *ngIf="!complete">{{ 'RETURN.OPTIONS.SERIAL' | translate | uppercase }}</button>
    </div>
    <div *ngIf="currentStep == 'card'" class="w-100 credit-card">
        <app-card-read (readComplete)="onCardReadComplete($event)"></app-card-read>
    </div>
    <div *ngIf="step.input" class="w-100 d-flex flex-column input-label">
        <label class="d-flex align-items-center justify-content-center" [ngClass]="{ 'placeholder': input.value.length === 0 }">
            {{input.value || (step.placeholder | translate)}}
        </label>
        <span *ngIf="step.inputNote" class="message">* {{ step.inputNote | translate | uppercase }}</span>
    </div>
    <div *ngIf="step.input" class="keyboard">
        <app-keyboard [value]="input.value" [layout]="step.layout || 'letters'" [formControl]="input"></app-keyboard>
    </div>
    <div *ngIf="step.message" class="d-flex flex-column align-items-center justify-content-center centered-content">
        <div *ngIf="currentStep === 'error'" class="rent-details centered flex-column">
            <label class="d-flex flex-column text">
                {{ step.message | translate | uppercase }}
            </label>
        </div>
    </div>
    <div *ngIf="currentStep === 'confirm' && reservations" class="d-flex flex-column align-items-center justify-content-center centered-content">
        <div (click)="onSelectReservation(i)"
            class="rent-details centered flex-column"
            *ngFor="let reservation of reservations; let i = index"
            [ngClass]="{ 'multiple': reservations.length > 1, 'selected': reservationIndex === i }">
            <h1 *ngIf="reservation && reservation.renterName != ''">{{reservation.renterName}}</h1>
            <h1 *ngIf="reservation && reservation.transactionRefNum != ''">{{reservation.transactionRefNum}}</h1>
            <!-- <h1>DATE: {{formatDate(reservation)}}</h1>
            <h1>TIME: {{formatTime(reservation)}}</h1> -->

        </div>
    </div>
    <div *ngIf="step.next && step.message || currentStep === 'confirm'">
        <button id="confirmErrorBtn" class="btn-rug confirm-button" (click)="onStepChange(step.next)" *ngIf="!complete">{{ (step.nextText || 'GLOBAL.NEXT') | translate | uppercase }}</button>
    </div>
    <div
        *ngIf="currentStep != 'options' && currentStep != 'card' && step.next && !step.message && currentStep !== 'confirm'"
        class="buttons d-flex w-100 justify-content-end">
        <button
             id="confirmBtn"
            (click)="onStepChange(step.next)"
            [ngClass]="{ 'disabled': step.validation && !input.valid }"
            class="btn-rug button d-flex align-items-center justify-content-center">
            {{ (step.nextText || 'GLOBAL.NEXT') | translate | uppercase }}
            <img src="assets/img/arrow-right-white.svg" alt="right"/>
        </button>
    </div>
</div>
<img class="decoration-path" src="assets/img/path.svg" alt="path"/>
<img class="decoration-background" src="assets/img/bays-background.jpg" alt="bays"/>
