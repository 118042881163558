import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environment/environment';

import { User } from '@domain';
import { RoutingService } from '@services';
import { Deserialize } from 'cerialize';

const KIOSK_ID_KEY = 'kiosk_id';
const KIOSK_TOKEN_KEY = 'kiosk_token';
const KIOSK_TEST_TOKEN_KEY = 'kiosk_test_token';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    private http: HttpClient,
    private routingService: RoutingService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get kioskId(): number {

    const value: string = localStorage.getItem(KIOSK_ID_KEY);
    if (!value) {
      return null;
    }
    return parseInt(value, 10);
  }

  public get kioskToken(): string {
    return localStorage.getItem(KIOSK_TOKEN_KEY);
  }

  public get kioskTestToken(): string {
    return localStorage.getItem(KIOSK_TEST_TOKEN_KEY);
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get kioskAuthHeader(): HttpHeaders {
    return new HttpHeaders({
      Authorization: 'Bearer ' + ((this.kioskTestToken) ? this.kioskTestToken : this.kioskToken)
    });
  }

  public get adminAuthHeader(): HttpHeaders {
    return (this.currentUserValue?.authorization)
    && new HttpHeaders({
      Authorization: 'Bearer ' + this.currentUserValue.authorization.token
    });
  }

  public requestTestToken(): Observable<any> {
    return this.http.post(environment.baseUrl + 'kiosks/' + this.kioskId + '/maintenance_token', {}, { headers: this.adminAuthHeader })
      .pipe(map((data: any) => {
        localStorage.setItem(KIOSK_TEST_TOKEN_KEY, data.maintenance_token.token);
        return data;
      }));
  }

  public destroyTestToken(): void {
    localStorage.removeItem(KIOSK_TEST_TOKEN_KEY);
  }

  public registerKioskId(id: string): Observable<any> {
    return this.http.post(environment.baseUrl + 'kiosks/' + id + '/token', {}, { headers: this.adminAuthHeader })
      .pipe(map((data: any) => {
        localStorage.setItem(KIOSK_ID_KEY, id.toString());
        localStorage.setItem(KIOSK_TOKEN_KEY, data.authorization.token);
        return data;
      }));
  }

  public loginAdmin(user: User): Observable<User> {
    return this.http.post<User>(environment.baseUrl + 'users/authenticate', { ...user, provider: 'username' })
      .pipe(map((res: User) => {
        if (res && res.authorization) {
          localStorage.setItem('currentUser', JSON.stringify(Deserialize(res, User)));
          this.currentUserSubject.next(res);
        }
        return res;
      }));
  }

  public logout(): void {
    this.destroyTestToken();
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.routingService.navigateToSplashScreen();
  }
}
