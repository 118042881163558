import {autoserialize, autoserializeAs, Deserialize} from 'cerialize';
import {Bay} from './bay.model';
import {BayProductEntry} from './bay-product-entry.model';

export class BayStatus {
  @autoserialize id: number;
  @autoserializeAs(Bay, 'bay') bay: Bay;

  public get quantity(): number {
    if (!this.products || this.products.length === 0) {
      return 0;
    }
    return this.products.reduce((total, entry) => (total + entry.quantity), 0);
  }

  public get quantityLoaded(): number {
    if (!this.products || this.products.length === 0) {
      return 0;
    }
    return this.products.reduce((total, entry) => (total + entry.quantityLoaded), 0);
  }

  public get quantityRented(): number {
    if (!this.products || this.products.length === 0) {
      return 0;
    }
    return this.products.reduce((total, entry) => (total + entry.quantityRented), 0);
  }

  public get productCode(): string {
    if (!this.products || this.products.length === 0) {
      return 'N/A';
    }
    return this.products[0].type.productCode;
  }

  public products: BayProductEntry[];

  public get serial(): string {
    if (this.products.length === 0) {
      return '';
    }
    return this.products[0].product.serialNumber;
  }

  public constructor(
    bay?: Bay
  ) {
    this.products = [];
    this.bay = bay;
  }

  public static OnDeserialized(instance: BayStatus, json: any): void {
    if (json.product) {
      instance.products.push(Deserialize(json, BayProductEntry));
    }
  }
}
