import { autoserializeAs, Deserialize } from 'cerialize';
import { Rental } from './rental.model';
import { PaymentDetails } from './payment-details.model';

export class RentalDetails {
    public paymentDetails: PaymentDetails;
    @autoserializeAs('rental') rental: Rental;
    public constructor(
        rental?: Rental,
        paymentDetails?: PaymentDetails
    ) {
        this.rental = rental;
        this.paymentDetails = paymentDetails;
    }
    public static OnDeserialized(instance: RentalDetails, json: any): void {
        instance.paymentDetails = Deserialize(json, PaymentDetails);
    }
}
