import { ProductType, ProductGroup } from './product-type.model'
import { Bundle } from './bundle.model'
import { RentalProduct } from './rental-product'

export class PresentationItem<T> {

  public id: number
  public name: string
  public subtitle: string
  public group: ProductGroup
  public imageUrl: string
  public details: string
  public price: number
  public actual_quantity: number
  public quantity: number
  public description: string
  public description1: string
  public description2: string
  public description3: string
  public originalValue: T

  public constructor(original: T) {
    this.originalValue = original

    if (original instanceof RentalProduct) {
      this.setValues(original.type as ProductType)
    } else if (original instanceof ProductType) {
      this.setValues(original as ProductType)
    } else if (original instanceof Bundle) {
      const bundle: Bundle = original as Bundle

      this.name = bundle.name
      this.subtitle = bundle.subtitle
      this.details = bundle.details
      this.description1 = bundle.description1
      this.description2 = bundle.description2
      this.description3 = bundle.description3
      this.group = ProductGroup.Bundle
      this.price = bundle.bundlePrice
      this.actual_quantity = 1
      this.quantity = 1
      this.imageUrl = bundle.photo
    }
  }

  private setValues(originalData: ProductType) {
    this.id = originalData.id
    this.name = originalData.name
    this.subtitle = originalData.subtitle
    this.group = originalData.productGroup
    this.imageUrl = originalData.imageUrl
    this.details = originalData.details
    this.price = originalData.price
    this.actual_quantity = originalData.actual_quantity !== null ? originalData.actual_quantity : originalData.quantity
    this.quantity = originalData.actual_quantity !== null ? originalData.actual_quantity : originalData.quantity
    this.description1 = originalData.description1
    this.description2 = originalData.description2
    this.description3 = originalData.description3
  }
}
