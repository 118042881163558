import { Injectable } from '@angular/core'
import { environment } from '@environment/environment'
import { HttpClient } from '@angular/common/http'
import { Serialize, Deserialize } from 'cerialize'
import { map } from 'rxjs/operators'
import { Rental } from 'src/app/domain/rental.model'
import { Reservation } from 'src/app/domain/reservation.model'
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'
import { Observable, zip } from 'rxjs'
import { UtilService } from './util.service'
import { RentalDetails } from 'src/app/domain/rental-details.model'
import { Kiosk } from "src/app/domain/kiosk.model"
import { RentalProduct } from 'src/app/domain/rental-product'
import { Bay, BayState, BayType } from "src/app/domain/bay.model"
import { KioskServerService } from "@shared/services/kiosk-server.service"
import { KioskService } from '@shared/services/kiosk.service'
import { BeamStatus } from "src/app/domain/bay-beam-status.model"

const BAY_COUNT = 5
const BEAM_COUNT = 4
@Injectable({
  providedIn: 'root'
})
export class RentalService {

  private kiosk: Kiosk

  public constructor(
    private http: HttpClient,
    private util: UtilService,
    private auth: AuthenticationService,
    private kioskService: KioskService,
    private kioskServerervice: KioskServerService,
  ) { }

  public get(rentalId: string): Observable<Reservation> {
    return this.http.get(environment.baseUrl + 'rentals/' + rentalId, { headers: this.auth.kioskAuthHeader }).pipe(
      map((reservation: Reservation) => {
        return Deserialize(reservation, Reservation)
      }
      ))
  }

  public find(params): Observable<Reservation[]> {
    let query = this.util.encodeParams(params)
    query += '&kiosk_id=' + this.auth.kioskId
    return this.http.get(environment.baseUrl + 'rentals?' + query, { headers: this.auth.kioskAuthHeader }).pipe(
      map((reservation: Reservation) => {
        return Deserialize(reservation, Reservation)
      }
      ))
  }

  public create(rental: Rental): Observable<RentalDetails> {
    return this.http.post(environment.baseUrl + 'rentals', Serialize(rental), { headers: this.auth.kioskAuthHeader }).pipe(
      map((rentalDetails: RentalDetails) => {
        return Deserialize(rentalDetails, RentalDetails)
      }
      ))
  }

  public update(rental: Rental): Observable<RentalDetails> {
    rental.kioskId = this.auth.kioskId
    const rentalData = Serialize(rental)
    const updateData = {
      products: rentalData.products.map(productData => {
        return {
          quantity: productData.quantity,
          product_type_id: productData.product_type_id,
          bay_id: productData.bay?.id
        }
      }),
      coupon_codes: rentalData.coupon_codes
    }
    return this.http.put(environment.baseUrl + 'rentals/' + rental.id, updateData, { headers: this.auth.kioskAuthHeader }).pipe(
      map((rentalDetails: RentalDetails) => {
        return Deserialize(rentalDetails, RentalDetails)
      }
      ))
  }

  public publish(rental: Rental): Observable<Reservation> {
    rental.kioskId = this.auth.kioskId

    return this.http.put(
      environment.baseUrl + 'rentals/' + rental.id + '/publish',
      Serialize(rental),
      { headers: this.auth.kioskAuthHeader }
    ).pipe(
      map((reservation: Reservation) => {
        return Deserialize(reservation, Reservation)
      }
    ))
  }

  public changeReservation(rental: Rental): Observable<Reservation> {
    rental.kioskId = this.auth.kioskId
    return this.http.put(
      environment.baseUrl + 'rentals/' + rental.id + '/change_reservation',
      {},
      { headers: this.auth.kioskAuthHeader }
    ).pipe(
      map((reservation: Reservation) => {
        return Deserialize(reservation, Reservation)
      }
      ))
  }

  public pickUp(reservationId: number): Observable<any> {
    return this.http.put(
      environment.baseUrl + 'rentals/' + reservationId + '/pick_up_products',
      {},
      { headers: this.auth.kioskAuthHeader }
    )
  }

  public return(reservationId: number): Observable<any> {
    return this.http.put(environment.baseUrl + 'rentals/' + reservationId + '/return_products', {},
      { headers: this.auth.kioskAuthHeader })
  }

  public assignBays(rental: Rental) {
    const observables: Observable<any>[] = []
    observables.push(this.kioskService.getKioskInfo())
    observables.push(this.kioskServerervice.statusAll(BAY_COUNT, BEAM_COUNT))

    zip(...observables).subscribe((data: any[]) => {
      let statuses = data[1]
      this.kiosk = data[0]
      this.kiosk.bays.forEach((bay: Bay) => {
        if (bay.state == BayState.Available) {
          rental.products.every((product: RentalProduct) => {
            if (product.type.id == bay.products[0].type.id) {
              if (typeof statuses[`bay_${bay.number}`] != 'undefined') {
                let status = statuses[`bay_${bay.number}`]
                for (let beam in status.beam_status) {
                  if (status.beam_status[beam] == BeamStatus.Filled) {
                    product.bay = bay

                    return false
                  }
                }
              }
            }

            return true
          })
        }
      })

      this.update(rental)
    })
  }
}
