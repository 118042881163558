import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@shared/services/i18n.service';
import { RoutingService } from '@shared/services';
import { CartService } from '@shared/stores/cart.service';

const SUMMARY_STEP = 'summary';
const TEXT_ELEMENT_NAME = 'text';

@Component({
    selector: 'app-agreement',
    templateUrl: './agreement.component.html',
    styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent {

    @ViewChild(TEXT_ELEMENT_NAME) textElement: any;
    public hitTop: boolean;
    public hitBottom: boolean;
    public offset: number;

    public constructor(
        public translate: TranslateService,
        public i18n: InternationalizationService,
        private cartService: CartService,
        private routingService: RoutingService
    ) {
        translate.setDefaultLang(i18n.lang);
        this.hitTop = true;
        this.hitBottom = false;
    }

    public onTextDrag(event: any): void {
        if (event.buttons !== 0) {
            if (this.offset) {
                this.textElement.nativeElement.scrollTop += (this.offset - event.offsetY);
            }
            this.offset = event.offsetY;
            this.onUpdateScrollBounds();
        } else {
            this.offset = null;
        }
    }

    public onUpdateScrollBounds(): void {
        this.hitTop = this.textElement.nativeElement.scrollTop === 0;
        this.hitBottom = this.textElement.nativeElement.scrollTop ===
            this.textElement.nativeElement.scrollHeight - this.textElement.nativeElement.clientHeight;
    }

    public onAccept(): void {
        this.cartService.complete();
    }

    public onDecline(): void {
        this.routingService.navigateToRent(SUMMARY_STEP);
    }
}
