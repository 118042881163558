import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.scss']
})
export class ProgressTrackerComponent implements OnInit {

  @Input() progress: number;
  @Output() changed: EventEmitter<number>;
  public constructor() {
    this.changed = new EventEmitter();
  }
  public ngOnInit() {
    if (this.progress == null) {
      this.progress = 0;
    }
  }
  public navigateTo(step: number): void {
    if (step > this.progress) {
      return;
    }
    this.changed.emit(step);
  }
}
