import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public open: boolean;
  public modalContent = null;
  public modalType: string;

  public constructor() {
    this.open = false;
  }

  closeModal() {
    this.open = false;
    this.modalContent = null;
  }

  openModal() {
    this.open = true;
  }

}
