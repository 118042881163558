import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Kiosk } from 'src/app/domain/kiosk.model'
import { environment } from 'src/environments/environment'
import { Deserialize } from 'cerialize'
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'

const KIOSK_ID_KEY = 'mrent_kiosk_id'

@Injectable({
  providedIn: 'root'
})
export class KioskService {
  public kiosk: Kiosk
  public constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }
  public getKioskInfo(): Observable<Kiosk> {
    return this.http.get(environment.baseUrl + 'kiosks/' + this.auth.kioskId).pipe(
      map((kiosk: Kiosk) => {
        const kioskSelected = Deserialize(kiosk, Kiosk)
        this.kiosk = kioskSelected

        return kioskSelected
      }
      ))
  }
}
