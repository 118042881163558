import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@services';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@shared/services/i18n.service';

@Component({
  selector: 'app-how-to-rent',
  templateUrl: './how-to-rent.component.html',
  styleUrls: ['./how-to-rent.component.scss']
})
export class HowToRentComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public i18n: InternationalizationService,
    public routingService: RoutingService
  ) {
    translate.setDefaultLang(i18n.lang);
  }

  public ngOnInit() { }
}
