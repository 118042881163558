import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@shared/services';

@Component({
    selector: 'app-admin-link',
    templateUrl: './admin-link.component.html',
    styleUrls: ['./admin-link.component.scss']
})
export class AdminLinkComponent implements OnInit {
    public clickCounter: number;
    public constructor(
        public routingService: RoutingService
    ) { }
    public ngOnInit(): void {
        this.clickCounter = 0;
    }
    public onClick(): void {
        this.clickCounter++;
        if (this.clickCounter > 2) {
            this.routingService.navigateToLogin();
        }
    }
}
