import { autoserialize, autoserializeAs, Deserialize } from 'cerialize';
import { ProductType } from './product-type.model';
import { Coupon, CouponType } from './coupon.model';

export class Bundle {
    @autoserialize id: number;
    @autoserializeAs('name') name: string;
    @autoserializeAs('subtitle') subtitle: string;
    @autoserializeAs('bundle_price') price: number;
    @autoserializeAs(Coupon, 'coupon') coupon: Coupon;
    @autoserializeAs('details') details: string;
    @autoserializeAs('description_1') description1: string;
    @autoserializeAs('description_2') description2: string;
    @autoserializeAs('description_3') description3: string;
    public products: ProductType[];
    public photo: string;
    public static OnDeserialized(instance: Bundle, json: any): void {
        let i = 1;
        instance.coupon.name = instance.name;
        instance.coupon.type = CouponType.Bundle;
        instance.products = [];
        while (json['product_type_' + i]) {
            instance.products.push(Deserialize(json['product_type_' + i], ProductType));
            i++;
        }

        instance.photo = json.photo?.url;
    }

    public get bundlePrice(): number {
      return this.products.reduce((price, product) => (price + product.price), 0) - this.coupon.amount;
    }

    public constructor(
        id?: number,
        name?: string,
        bundlePrice?: number
    ) {
        this.id = id;
        this.name = name;
        this.price = bundlePrice;
    }
}
