<div class="circle-progress">
    <div class="main-circle"></div>
    <div [ngStyle]="{'transform':'rotate(' + circle1Rotation + 'deg)'}" class="rotating-circle-base">
        <div class="color-circle-1"></div>
    </div>
    <div class="gray-circle-1"></div>
    <div [ngStyle]="{'transform':'rotate(' + circle2Rotation + 'deg)'}" class="rotating-circle-base circle-2">
        <div *ngIf="progress >= halfSpan" class="color-circle-2"></div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center inner-circle">
        <span class="message">{{ message | translate | uppercase }}</span>
        <span class="percent">{{ time }}</span>
    </div>
</div>