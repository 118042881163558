import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';

export type TimerData = {
    time: number,
    stopped: boolean,
    complete: boolean
}

@Injectable({
    providedIn: 'root'
})
export class TimerService {

    private stopped: boolean;
    public constructor() { }

    public start(duration: number): Observable<TimerData> {
        let time = -1;
        this.stopped = false;
        return new Observable(observer => {
            timer(0, 1000).subscribe(() => {
                time++;
                observer.next({
                    time,
                    stopped: this.stopped,
                    complete: time === duration
                });
                if (time === duration || this.stopped) {
                    observer.complete();
                }
            });
        });
    }

    public stop(): void {
        this.stopped = true;
    }
}
