import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public constructor(
    private http: HttpClient
  ) { }

  public encodeParams(params): string {
    let query = '';
    Object.keys(params).forEach(key => {
      if (params[key]) {
        if (query.length > 0) {
          query += '&';
        }
        query += key + '=' + encodeURIComponent(params[key]);
      }
    });
    return query;
  }

  public formatName(firstName: string, lastName: string): string {
    let name = '';
    const firstNameParts: string[] = (firstName && firstName.length > 0) ? firstName.split(' ') : [];
    const lastNameParts: string[] = (lastName && lastName.length > 0) ? lastName.split(' ') : [];
    firstNameParts.forEach((part, i) => name += ((i > 0) ? ' ' : '') + this.capitalize(part));
    name += firstNameParts.length > 0 ? ' ' : '';
    lastNameParts.forEach((part, i) => name += ((i > 0) ? ' ' : '') + this.capitalize(part));
    return name;
  }

  public capitalize(value: string): string {
    return value.substring(0, 1).toUpperCase() + value.slice(1).toLowerCase();
  }

  public rotateScreen() {
    return this.http.get(environment.kioskUrl + 'system/flip');
  }

  public updateKiosk() {
    return this.http.get(environment.kioskUrl + 'system/update');
  }
}
