<div *ngIf="options" class="dropdown">
    <span
        (click)="toggleList()"
        class="input-rug value"
        [ngClass]="{ 'list-visible': listVisible }">
        <span>{{ valueText }}</span>
        <img src="/assets/img/drop.svg" alt="dropdown"/>
    </span>
    <div *ngIf="listVisible" class="list">
        <div #inner class="inner-list scrollable">
            <div
                (click)="onSelectValue(option.key)"
                *ngFor="let option of options; let i = index"
                [ngClass]="{ 'first': i === 0 }"
                class="entry">
                {{ option.text }}
            </div>
        </div>
    </div>
</div>