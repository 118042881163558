import { autoserialize, autoserializeAs } from 'cerialize'

export class Card {
  @autoserialize number: string
  @autoserialize month: number
  @autoserialize year: number
  @autoserializeAs('verification_value') verificationValue: string
  @autoserializeAs('first_name') firstName: string
  @autoserializeAs('last_name') lastName: string
  public get last4Digits(): string {
    return this.number.substr(this.number.length - 4)
  }
  public constructor(
    cardNumber?: string,
    month?: number,
    year?: number,
    verificationValue?: string,
    firstName?: string,
    lastName?: string
  ) {
    this.number = cardNumber
    this.month = month
    this.year = year
    this.verificationValue = verificationValue
    this.firstName = firstName
    this.lastName = lastName
  }
}
