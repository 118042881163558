<app-header [canNavigateBack]="true" [redBorder]="true"></app-header>
<div class="login page d-flex justify-content-between flex-column">
    <div class="w-100 title">
        <h1>TYPE YOUR USERNAME & PASSWORD</h1>
    </div>
    <div class="w-100 d-flex flex-column input-label">
        <label
            (click)="onSelectInput('username')"
            class="d-flex align-items-center justify-content-center username"
            [ngClass]="{ 'placeholder': user.username.length === 0, 'active': selectedInputKey == 'username' }">
            {{user.username || 'Username'}}
        </label>
        <label
            (click)="onSelectInput('password')"
            class="d-flex align-items-center justify-content-center"
            [ngClass]="{ 'placeholder': user.password.length === 0, 'active': selectedInputKey == 'password' }">
            {{hiddenPassword || 'Password'}}
        </label>
        <span *ngIf="message" class="message">* USERNAME & PASSWORD DO NOT MATCH</span>
    </div>
    <div class="keyboard">
        <app-keyboard [value]="value" [formControl]="input" [disableTempCaps]="true" (valueChanged)="onValueChange($event)"></app-keyboard>
    </div>
    <div class="buttons d-flex w-100 justify-content-end">
        <button
            (click)="onLogin()"
            [ngClass]="{ 'disabled': user.username.length === 0 || user.password.length === 0 }"
            class="btn-rug button d-flex align-items-center justify-content-center">
            CONFIRM
            <img src="assets/img/arrow-right-white.svg" alt="confirm"/>
        </button>
    </div>
</div>
<img class="decoration-path" src="assets/img/path.svg" alt="path"/>
<img class="decoration-background" src="assets/img/bays-background.jpg" alt="background"/>
