import { autoserializeAs, Deserialize } from 'cerialize';
import { ProductType } from './product-type.model';
import { Bay } from './bay.model';

export class RentalProduct {

    @autoserializeAs('quantity') quantity: number;
    @autoserializeAs('actual_quantity') actual_quantity: number = 0;
    @autoserializeAs(Bay, 'bay') bay: Bay;
    public reserved: boolean;
    public type: ProductType;
    public get isPlaced(): boolean { return this.bay != null; }
    public get price(): number { return this.quantity * this.type.price; }

    public constructor(
        type?: ProductType,
        quantity?: number,
        bay?: Bay
    ) {
        this.reserved = false;
        this.type = type;
        this.quantity = quantity || 1;
        this.bay = bay;
    }

    public static OnSerialized(instance: RentalProduct, json: any): void {
        json.product_type_id = instance.type.id;
    }

    public static OnDeserialized(instance: RentalProduct, json: any): void {
        instance.type = Deserialize(json.product.product_type, ProductType);
    }
}
