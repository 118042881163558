<div class="d-flex flex-column justify-content-start bay-configuration h-100">
    <div class="d-flex">
      <div class="w-100 title">
        <h1 id=bay_configuration_table_title class="light">Bay Configuration</h1>
      </div>
      <button class="btn-rug add-bay-btn" (click)="onNewBay()">+ Add new bay</button>
    </div>

    <table class="table table-centered" aria-describedby="bay_configuration_table_title">
        <tr>
            <th scope="col">BAY</th>
            <th scope="col">BAY TYPE</th>
            <th scope="col">QTY LOADED</th>
            <th scope="col">QTY RENTED</th>
            <th scope="col">QTY LEFT</th>
            <th scope="col">MCH TYPE</th>
            <th scope="col">SERIAL</th>
            <th scope="col">STATUS</th>

        </tr>
        <tr
            *ngFor="let productBay of productBays; let i = index; let even = even;"
            [ngClass]="{ 'even': even }">
            <td>{{ productBay.bay.number }}</td>
            <td>{{ productBay.bay.type | uppercase }}</td>
            <td>{{ productBay.quantityLoaded }}</td>
            <td>{{ productBay.quantityRented }}</td>
            <td>{{ productBay.quantity }}</td>
            <td>{{ productBay.productCode }}</td>
            <td>
                <span class="serial centered">
                    {{ productBay.serial }}
                </span>
            </td>
            <td class="smaller">{{ formatState(productBay.bay.state) }}</td>
        
        </tr>
    </table>
    <div class="spinner-position" *ngIf="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
</div>
