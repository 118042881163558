import { Component, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { User } from '@domain'
import { RoutingService } from '@shared/services'
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public selectedInputKey: string
  public user: User
  public input: FormControl
  public message: boolean
  public get value(): string { return this.user[this.selectedInputKey] }
  public get hiddenPassword(): string { return '*'.repeat(this.user.password.length) }
  public constructor(
    private authService: AuthenticationService,
    private routingService: RoutingService
  ) { }
  public ngOnInit(): void {
    this.message = false
    this.user = new User()
    this.user.username = ''
    this.user.password = ''
    this.selectedInputKey = 'username'
    this.input = new FormControl('', [Validators.required])
  }
  public onSelectInput(key: string): void {
    this.selectedInputKey = key
  }
  public onLogin(): void {
    if (this.user.username.length > 0 && this.user.password.length > 0) {
      this.authService.loginAdmin(this.user).subscribe((user: User) => {
        this.routingService.navigateToAdmin()
      }, error => {
        this.message = true
      })
    }
  }
  public onValueChange(value: string): void {
    this.user[this.selectedInputKey] = value
  }
}
