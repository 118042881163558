<div class="d-flex flex-column justify-content-between bays">
    <div class="d-flex justify-content-center align-items-center header pb-2">
        <img alt="RugDoctor" src="/assets/img/logo.svg">
    </div>

    <div *ngIf="action && !complete && action.type != 'pickFromShelf'" class="d-flex flex-column justify-content-around align-items-center middle">
        <app-circle-progress [message]="'BAYS.KIOSK_DOOR'" [progress]="time" [span]="duration"></app-circle-progress>

        <div class="d-flex flex-column justify-content-around align-items-center middle pt-2 pb-2">
            <div class="d-flex justify-content-center align-items-center">
                <span class="text">{{ title | translate | uppercase }}</span>
            </div>
        </div>

        <div *ngFor="let action of actions; let i = index">
            <div class="d-flex flex-column justify-content-around align-items-center middle pt-2">
                <div class="d-flex justify-content-center align-items-center">
                    <span class="text done">
                        <span class="door-number-label">{{ 'BAYS.DOOR_NUMBER' | translate | uppercase }}
                            {{ action?.number }}:
                        </span>
                        {{ bayText | translate | uppercase }}
                        <span class="name">{{ action?.displayText | uppercase }}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="action && !complete && shelfAction" class="d-flex flex-column justify-content-around align-items-center middle">
        <span class="text">
            {{ 'BAYS.SOLUTION_REMINDER' | translate | uppercase }}
        </span>
        <div class="solutions scrollable large">
            <div *ngFor="let item of action.items" class="d-flex flex-row solution">
                <img [src]="item.type.imageUrl" alt="product" class="stars"/>
                <div class="d-flex flex-column justify-content-center h-100">
                    <span class="ammount">{{ item.quantity }}
                        BOTTLE</span>
                    <span>{{ item.type.name | uppercase }}</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="complete && !cancelled" class="d-flex flex-column justify-content-around align-items-center middle">
        <div class="d-flex justify-content-center align-items-center">
            {{ 'BAYS.DONE_LABEL_2' | translate | uppercase }}
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <span *ngIf="!shelfOnly" class="thank-you">{{ 'BAYS.THANK_YOU' | translate | uppercase }}</span>
            <span *ngIf="shelfOnly" class="thank-you">{{ 'BAYS.PURCHASE_THANK_YOU' | translate | uppercase }}</span>
        </div>
        <div *ngIf="!shelfOnly && messageType" class="d-flex justify-content-center align-items-center">
            <span *ngIf="messageType === 'rent'" class="text message">{{ 'BAYS.RENT_MESSAGE' | translate | uppercase }}</span>
            <span *ngIf="messageType === 'return'" class="text message">{{ 'BAYS.RETURN_MESSAGE' | translate | uppercase }}</span>
        </div>
    </div>

    <div *ngIf="complete && cancelled" class="d-flex flex-column justify-content-around align-items-center middle">
        <div class="d-flex justify-content-center align-items-center">
            <span *ngIf="!shelfOnly" class="thank-you">{{ 'BAYS.TIMEOUT_HEADING' | translate | uppercase }}</span>
        </div>
        <div *ngIf="!shelfOnly && messageType" class="d-flex justify-content-center align-items-center">
            <span *ngIf="messageType === 'rent'" class="text message">{{ 'BAYS.RENT_TIMEOUT_MESSAGE' | translate | uppercase }}</span>
            <span *ngIf="messageType === 'return'" class="text message">{{ 'BAYS.RETURN_TIMEOUT_MESSAGE' | translate | uppercase }}</span>
        </div>
    </div>

    <!-- <div *ngIf="action && !complete && !shelfAction" class="d-flex justify-content-center align-items-center">
        <span class="text done">
            {{ bayText | translate | uppercase }}
            <span class="name">{{ action?.displayText | uppercase }}</span>
            {{ 'BAYS.DONE_LABEL_2' | translate | uppercase }}
        </span>
    </div> -->

    <div class="d-flex justify-content-center footer">
      <!-- <button class="btn-rug" id="doneBtn" (click)="onDone()" *ngIf="!complete">{{ 'BAYS.DONE' | translate | uppercase }}</button> -->
      <button class="btn-rug" id="completeBtn" (click)="onComplete()" *ngIf="complete" [ngClass]="{ 'disabled': !transactionConfirmed }">{{ 'BAYS.COMPLETE' | translate | uppercase }}</button>
    </div>
</div>
<img alt="path" class="decoration-path" src="assets/img/path.svg"/>
<img alt="background" class="decoration-background" src="assets/img/bays-background.jpg"/>
<!-- <div *ngIf="itemNotTaken" class="dialog">
    <div class="dialog-box d-flex flex-column justify-content-between">
        <div class="title">
            ARE YOU SURE YOU WANT TO CLOSE THE DOOR?
        </div>
        <div class="buttons d-flex w-100 justify-content-between">
            <button (click)="openBay()" class="btn-rug button hollow">NO</button>
            <button (click)="completeActionAndClose()" class="btn-rug button">YES</button>
        </div>
    </div>
</div> -->
