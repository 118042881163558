import { autoserialize, autoserializeAs } from 'cerialize';

const PREFIX = 'Coupon: ';

export enum CouponState {
    Active = 'active',
    Inactive = 'inactive'
}

export enum CouponType {
    Regular = 'regular',
    Bundle = 'bundle'
}

export enum CouponTransactionType {
    Machine = 'R',
    Solution = 'C',
    Both = 'B'
}

export enum CouponFactor {
    Amount = 'amount',
    Percentage = 'percentage'
}

export class Coupon {
    @autoserialize id: number;
    @autoserialize amount: number;
    @autoserialize validity: boolean;
    @autoserializeAs('coupon_code') code: string;
    @autoserializeAs('discount_percentage') percentage: number;
    @autoserializeAs(CouponState, 'state') state: CouponState;
    @autoserializeAs(CouponTransactionType, 'transaction_type') transactionType: CouponTransactionType;
    @autoserializeAs('service_types') serviceTypes: string;
    public type: CouponType;
    public name: string;
    public factor: CouponFactor;
    public constructor() { }
    public static OnDeserialized(instance: Coupon, json: any): void {
        instance.type = CouponType.Regular;
        instance.name = PREFIX + instance.code;
        instance.factor = (json.amount > 0) ? CouponFactor.Amount : CouponFactor.Percentage;
    }
    public get discount(): string {
        return (this.factor === CouponFactor.Amount)
            ? ('$' + this.amount.toFixed(2))
            : (this.percentage + '%');
    }
}
