import * as moment from 'moment';
import { autoserialize, autoserializeAs } from 'cerialize';
import { RentalProduct } from './rental-product';
import { Coupon } from './coupon.model';

export class Reservation {

  @autoserialize id: number;
  @autoserialize state: string;
  @autoserializeAs('renter_name') renterName: string
  @autoserializeAs('transaction_ref_num') transactionRefNum: string
  @autoserializeAs(Coupon, 'rental_coupons') coupons: Coupon[];
  @autoserializeAs(RentalProduct, 'rental_products') products: RentalProduct[];
  public startDate: Date;

  public get isShelfOnly(): boolean {
    return this.products.reduce((result, product) => (result && !product.isPlaced), true);
  }

  constructor() { }

  public static OnSerialized(instance: Reservation, json: any): void {
    json.start_date = moment(instance.startDate).format('YYYY-MM-DD HH:mm:ss Z');
  }

  public static OnDeserialized(instance: Reservation, json: any): void {
    instance.startDate = new Date(json.start_date);
  }
}
