import { Coupon, CouponType, CouponFactor } from './coupon.model';
export class CartCoupon {
    public coupon: Coupon;
    public requirements: number[];
    public get name(): string { return this.coupon.name; }
    public get code(): string { return this.coupon.code; }
    public get type(): CouponType { return this.coupon.type; }
    public get amount(): number { return this.coupon.amount; }
    public constructor(coupon: Coupon, requirements?: number[]) {
        this.coupon = coupon;
        this.requirements = requirements || [];
    }
    public get discount(): string { return this.coupon.discount; }
}
