import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Deserialize } from 'cerialize';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service';
import { Bundle } from 'src/app/domain/bundle.model';

@Injectable({
    providedIn: 'root'
})
export class BundleService {

    public constructor(
        private http: HttpClient,
        private auth: AuthenticationService
    ) { }

    public getAll(): Observable<Bundle[]> {

        return this.http.get(environment.baseUrl + 'bundles?kiosk_id=' + this.auth.kioskId, { headers: this.auth.kioskAuthHeader }).pipe(
            map((bundle: Bundle[]) => {
            
                return Deserialize(bundle, Bundle);
            }
        ));
    }
}
