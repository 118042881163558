import { Injectable } from '@angular/core';
import { Settings } from 'src/app/domain/settings.model';
import { Deserialize, Serialize } from 'cerialize';

const KIOSK_SETTINGS_KEY = 'kiosk_settings';
const defaultSettings: Settings = {
    pollBeams: true
};

@Injectable({ providedIn: 'root' })
export class SettingsService {
    public get settings(): Settings {
        const stored: any = localStorage.getItem(KIOSK_SETTINGS_KEY);
        if (stored) {
            return Deserialize(JSON.parse(stored), Settings);
        }
        else {
            return defaultSettings;
        }
    }
    public save(settings: Settings): void {
        localStorage.setItem(KIOSK_SETTINGS_KEY, JSON.stringify(Serialize(settings, Settings)));
    }
}
