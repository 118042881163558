<div class="d-flex flex-column justify-content-between h-100">
    <div class="w-100 title">
        <h1>UPDATE KIOSK ENV</h1>
    </div>
    <div class="w-100 d-flex flex-column input-label">
        <label class="d-flex align-items-center justify-content-center">
            <select [formControl]="select" class="custom-select custom-select-lg">
                <option *ngFor="let environment of environments" [ngValue]="environment">{{ environment }}</option>
            </select>
        </label>
    </div>
    <div class="buttons d-flex w-100 justify-content-end">
        <button (click)="onUpdateKioskEnvironment()" [ngClass]="{ 'disabled': !select.valid }" class="btn-rug button d-flex align-items-center justify-content-center">
            CONFIRM
            <img alt="confirm" src="assets/img/arrow-right-white.svg"/>
        </button>
    </div>
</div>
