<div *ngIf="visible" class="description">
  <div class="close" (click)="onHide()"></div>
  <div class="content">
    <div class="scrollable scrollable-content" (scroll)="onUpdateScrollBounds()" #text>
      <h1>{{presentationItem.name}}</h1>
      <img src="{{presentationItem.imageUrl}}" alt="{{presentationItem.name}}">
      <p class="price">${{presentationItem.price | number:'0.2-2' }}</p>
      <ul>
        <li *ngIf="presentationItem.description1" [innerHTML]="presentationItem.description1"></li>
        <li *ngIf="presentationItem.description2" [innerHTML]="presentationItem.description2"></li>
        <li *ngIf="presentationItem.description3" [innerHTML]="presentationItem.description3"></li>
      </ul>
      <div [innerHTML]="presentationItem.details"></div>
    </div>
    <div (pointermove)="onTextDrag($event)" class="drag-container"></div>
  </div>
</div>
