import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { Kiosk } from 'src/app/domain/kiosk.model'
import { AdminService } from '@shared/services/admin.service'
import { KioskServerService } from '@shared/services/kiosk-server.service'
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'
import { environment } from '@environment/environment'
import { SettingsService } from '@shared/stores/settings.service'
import { Settings } from 'src/app/domain/settings.model'

const KIOSK_ID_KEY = 'kiosk_id'
const KIOSK_TOKEN_KEY = 'kiosk_token'

@Component({
  selector: 'app-kiosk-configuration',
  templateUrl: './kiosk-configuration.component.html',
  styleUrls: ['./kiosk-configuration.component.scss']
})
export class KioskConfigurationComponent implements OnInit {

  @Output() updateKioskJde: EventEmitter<void>
  @Output() updateKioskEnvironment: EventEmitter<void>
  public shutDownDialog: boolean
  public refreshButtonText: string
  public togglePollBeamsButtonText: string
  public kioskInfo: Kiosk
  public settings: Settings
  public get environmentName(): string { return environment.name }
  public get pollBeamsStatus(): string { return (this.settings.pollBeams) ? 'Enabled' : 'Disabled' }
  public get appVersion(): string { return environment.version }

  public constructor(
    private adminService: AdminService,
    private authService: AuthenticationService,
    private kioskServerService: KioskServerService,
    private settingsService: SettingsService
  ) {
    this.refreshButtonText = 'REFRESH TOKEN'
    this.updateKioskJde = new EventEmitter()
    this.updateKioskEnvironment = new EventEmitter()
  }

  public ngOnInit(): void {
    this.settings = this.settingsService.settings
    this.togglePollBeamsButtonText = (this.settings.pollBeams) ? 'DISABLE' : 'ENABLE'
    this.onRefreshKioskInfo()
  }

  private onRefreshKioskInfo(): void {
    this.adminService.getKioskInfo().subscribe(kiosk => {
      this.kioskInfo = kiosk
    })
  }

  public onUpdateKioskJde(): void {
    this.updateKioskJde.emit()
  }

  public onUpdateKioskEnvironment(): void {
    this.updateKioskEnvironment.emit()
  }

  public onShutDown(): void {
    this.kioskServerService.shutDown().subscribe(() => { }, error => console.error(error))
  }

  public onRefreshToken(): void {
    this.refreshButtonText = 'REFRESHING...'

    this.authService.registerKioskId(this.kioskInfo.id.toString()).subscribe(() => {
      this.refreshButtonText = 'REFRESHED'
      const kioskJde = localStorage.getItem('kiosk_jde')
      const kioskId = localStorage.getItem(KIOSK_ID_KEY)
      const kioskToken = localStorage.getItem(KIOSK_TOKEN_KEY)

      this.kioskServerService.saveKioskConfig(kioskJde.toString(), kioskToken.toString(), parseInt(kioskId, 10), environment.version)
        .subscribe(next => {
          // reload to reestablish socket connection to backend
          window.location.reload()
        },
          error => {
            window.location.reload()
          })
    })
  }

  public onTogglePollBeams(): void {
    this.settings.pollBeams = !this.settings.pollBeams
    this.settingsService.save(this.settings)
    this.togglePollBeamsButtonText = (this.settings.pollBeams) ? 'DISABLE' : 'ENABLE'
  }
}
