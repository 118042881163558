import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Bundle } from 'src/app/domain/bundle.model';
import { RentalProduct } from 'src/app/domain/rental-product';
import { ProductGroup, ProductType } from 'src/app/domain/product-type.model';
import { PresentationItem } from 'src/app/domain/presentation-item.model';

@Component({
    selector: 'app-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent {

    @Input() plus: boolean;
    @Input() removable: boolean;
    @Input() item: PresentationItem<ProductType | RentalProduct | Bundle>;
    @Output() remove: EventEmitter<void>;
    public get slimmer(): boolean {
        return this.item.group === ProductGroup.Solutions || this.item.group === ProductGroup.Bundle;
    }

    public constructor() {
        this.remove = new EventEmitter<void>();
    }

    public onRemove(): void {
        this.remove.emit();
    }
}
