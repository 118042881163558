import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ProductGroup, ProductType } from '../../../domain/product-type.model';
import { Bay, BayState, BayType } from '../../../domain/bay.model';
import { AdminService } from '@shared/services/admin.service';
import { ProductService } from '@shared/services/product.service';
import { Product } from '../../../domain/product.model';
import { Observable, zip } from 'rxjs';

@Component({
  selector: 'app-new-bay',
  templateUrl: './new-bay.component.html',
  styleUrls: ['./new-bay.component.scss']
})
export class NewBayComponent implements OnInit  {

  @Output() return: EventEmitter<void>;
  public keyboardVisible: boolean;
  public keyboardNumbersVisible: boolean;
  public error: any;
  public serialNumberInput: FormControl;
  public bayNumberInput: FormControl;
  public existingMachine = true;
  public itemSelection: any[];
  public machines: ProductType[];
  public bay: Bay;
  public referenceMachineType: ProductType;
  public referenceProductId: number;
  private selectedAttachments: any[];
  public loading = false;
  public get bayStatusOptions(): any[] {
    return Object.keys(BayState).map(key => ({ key: BayState[key], text: key }));
  }
  public get noBeamsOptions(): any[] {
    return [1, 2, 3, 4].map(beamNumber => ({ key: beamNumber, text: beamNumber + ' Beams' }));
  }

  public constructor(
    private adminService: AdminService,
    public productService: ProductService
  ) {
    this.bay = new Bay();
    this.keyboardVisible = false;
    this.keyboardNumbersVisible = false;
    this.serialNumberInput = new FormControl('', [Validators.required]);
    this.bayNumberInput = new FormControl('', [Validators.required]);
    this.return = new EventEmitter();
  }

  public ngOnInit(): void {
    this.productService.getTypes(ProductGroup.Machines).subscribe(machines => {
      this.machines = machines;
    });
  }

  public onChangeType(type: string): void {
    this.bay.type = type as BayType;
    if (type === BayType.Machine) {
      this.bay.capacity = 1;
    }
  }

  public onChangeState(state: string): void {
    this.bay.state = state as BayState;
  }

  public onChangeCapacity(capacity: string): void {
    this.bay.capacity = parseInt(capacity, 10);
  }

  public onSelectProduct(): void {
    if (!this.existingMachine) {
      this.itemSelection = [
        {text: 'Empty', value: null},
        ...this.machines.map(machine => ({text: machine.name, value: machine}))
      ];
    }
  }

  public onCloseSelector(): void {
    this.itemSelection = null;
  }

  public onProductSelected(value: ProductType): void {
    this.itemSelection = null;
    if (this.bay.type === BayType.Machine) {
      this.keyboardVisible = false;
      this.referenceMachineType = value;
    }
  }

  public onToggleSerialKeyboard(value: boolean, event?: Event): void {
    this.keyboardVisible = value;
    if (event) {
      this.checkSerialNumber();
      event.stopPropagation();
    }
  }
  public checkSerialNumber() {
    this.loading = true;
    this.adminService.getProduct(this.serialNumberInput.value).subscribe(
      next => {
        if (next[0]) {
          this.existingMachine = true;
          this.referenceMachineType = (next[0] as Product).type;
          this.referenceProductId = next[0].id;
        } else {
          this.referenceMachineType = null;
          this.existingMachine = false;
          this.referenceProductId = null;
        }
        this.loading = false;
      },
      error => {
        console.log(error);
        this.loading = false;
        this.referenceProductId = null;
        this.existingMachine = false;
      }
    )
  }

  public onToggleNumberKeyboard(value: boolean, event?: Event): void {
    this.keyboardNumbersVisible = value;
    if (event) {
      event.stopPropagation();
    }
  }

  public isSaveEnabled(): boolean {
    if (this.bayNumberInput.value && (this.bay.type === BayType.Machine || this.bay.type === BayType.Attachment) && !this.loading) {
      if (this.serialNumberInput.value && this.referenceMachineType) {
        return true;
      } else if (!this.serialNumberInput.value && !this.referenceMachineType) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public onUpdateSelectedAttachments(selected: any): void {
    this.selectedAttachments = selected;
  }

  public goBack(): void {
    this.return.emit();
  }

  public onCreateMachine(): void {
  }

  private attachmentsQuantity(): number {
    let total = 0;
    this.selectedAttachments.forEach(selection => total += selection.quantity);
    return total;
  }

  public onSave(): void {
    if (this.isSaveEnabled()) {
      this.createBay();
    }
  }

  public createBay(){
    this.loading = true;
    this.bay.number = parseInt(this.bayNumberInput.value, 10);
    if (this.bay.type === 'mch' && !this.serialNumberInput.value) {
      this.bay.state = BayState.Empty;
    } else if (this.bay.type === 'mch' && this.serialNumberInput.value) {
      this.bay.state = BayState.Available;
    } else if (this.bay.type !== 'mch' && this.selectedAttachments.length === 0) {
      this.bay.state = BayState.Empty;
    } else {
      this.bay.state = BayState.Available;
    }
    this.adminService.createBay(this.bay).subscribe(
      (next: Bay) => {
        if (this.bay.type === 'mch') {
          if (this.serialNumberInput.value) {
            this.saveMachine(next);
          } else {
            this.loading = false;
          }
        } else {
          this.saveAttachments(next.id);
        }
      },
      error => {
        this.loading = false;
        this.error = { title: 'Unable to create bay', message: error };
      }
    )
  }

  public saveMachine(bay: Bay) {
    const bayId = bay.id;
    if (!this.existingMachine) {
      this.productService.createMachineInBay(
        this.serialNumberInput.value,
        this.referenceMachineType.id.toString(10),
        bayId.toString(10))
        .subscribe(
        next => {
          this.loading = false;
          this.goBack();
        },
        error => {
          this.loading = false;
          this.error = { title: 'Unable to add rentable to bay', message: error };
        }
      )
    } else {
      this.productService.loadToBay(this.referenceProductId, bayId, 1).subscribe(
        next => {
          this.loading = false;
          this.goBack();
        },
        error => {
          this.loading = false;
          this.error = { title: 'Unable to add rentable to bay', message: error };
        }
      );
    }
  }

  public saveAttachments(bayId: number) {
    const changes: Observable<any>[] = [];
    this.selectedAttachments.forEach(selection => {
      changes.push(this.productService.loadAttachmentsToBay(selection.type.id, bayId, selection.quantity));
    });
    zip(...changes).subscribe(() => {
        this.loading = false;
        this.goBack();
      },
      error => {
        this.loading = false;
        this.error = { title: 'Unable to add attachments', message: error };
      });
  }
}
