import { autoserializeAs } from 'cerialize';
import { Product } from './product.model';
import { ProductType } from './product-type.model';

export class BayProductEntry {
    @autoserializeAs('quantity') quantity: number;
    @autoserializeAs('quantity_loaded') quantityLoaded: number;
    @autoserializeAs('quantity_rented') quantityRented: number;
    @autoserializeAs(Product, 'product') product: Product;
    public get type(): ProductType { return this.product.type; }
    public constructor() { }
}
