import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@shared/services/i18n.service';

const DEFAULT_TIME = 60;

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss']
})
export class CircleProgressComponent implements OnInit {
  @Input() progress: number;
  @Input() span: number;
  @Input() message: string;
  public get halfSpan() { return Math.floor(this.span / 2); }
  public get circle1Rotation(): number {
    const rotation: number = (this.progress / this.halfSpan) * 180;
    return (rotation > 180) ? 180 : rotation;
  }
  public get circle2Rotation(): number {
    const rotation: number = ((this.progress - this.halfSpan) / this.halfSpan) * 180;
    return (rotation > 180) ? 180 : rotation;
  }
  public get time(): string {
    if (this.progress == null) {
      return '01:00';
    }
    const timeRemaining = this.span - this.progress;
    const minutes: number = Math.floor(timeRemaining / 60);
    const seconds: number = timeRemaining % 60;
    return (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
  }
  public constructor(
    public i18n: InternationalizationService,
    public translate: TranslateService,
  ) {
    this.translate.setDefaultLang(this.i18n.lang);
  }
  public ngOnInit() {
    if (!this.span) {
      this.span = DEFAULT_TIME;
    }
  }
}
