import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bundle } from 'src/app/domain/bundle.model';
import { ProductType } from 'src/app/domain/product-type.model';
import { PresentationItem } from 'src/app/domain/presentation-item.model';

@Component({
    selector: 'app-carousel-item',
    templateUrl: './carousel-item.component.html',
    styleUrls: ['./carousel-item.component.scss']
})
export class CarouselItemComponent implements OnInit{

    @Input() odd: boolean;
    @Input() item: PresentationItem<ProductType | Bundle>;
    @Output() showDetails: EventEmitter<void>;
    public details = '';
    public constructor() {
      this.showDetails = new EventEmitter();
    }

    ngOnInit(): void {
      this.details = this.item.details;
    }

    public openDescription() {
      this.showDetails.emit();
    }



}
