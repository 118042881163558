import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@shared/services';
import { CartService } from '@shared/stores/cart.service';
import { StockService } from '@shared/stores/stock.service';
import { Bundle } from 'src/app/domain/bundle.model';
import { RentalProduct } from 'src/app/domain/rental-product';
import { PresentationItem } from 'src/app/domain/presentation-item.model';
import { ProductGroup, ProductType } from 'src/app/domain/product-type.model';

const SUMMARY_STEP = 'summary';

@Component({
    selector: 'app-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {
    public items: PresentationItem <ProductType>[] = [];
    public kioskJde: string;
    public get progress(): number { return this.cartService.progress; }
    public get hasGroup(): boolean { return this.cartService.hasGroup(ProductGroup.Solutions); }
    public get cartEmpty(): boolean { return this.cartService.isEmpty; }
    public get cartItems(): PresentationItem <RentalProduct>[] {
        return (this.cartService.items) ? this.cartService.items.map(item => new PresentationItem(item)) : [];
    }
    // public get bundles(): PresentationItem <Bundle>[] {
    //     return (this.stockService.bundles) ? this.stockService.bundles.map(item => new PresentationItem(item)) : [];
    // }



    public constructor(
        private cartService: CartService,
        private stockService: StockService,
        private routingService: RoutingService
    ) {}

    public ngOnInit(): void {
      this.kioskJde = localStorage.getItem('kiosk_jde');

            this.stockService.restock().subscribe( next => {
              this.items = (this.stockService.solutions) ? this.stockService.solutions.map(item => new PresentationItem(item)) : [];
            });

    }

    public onRemove(index: number): void {
        this.cartService.removeAtIndex(index);
    }

    public onBack(): void {

        if (this.cartService.hasGroup(ProductGroup.Machines) && this.stockService.attachments.length > 0) {
            this.routingService.navigateToRent(ProductGroup.Attachments);
        } else if (!this.cartService.hasGroup(ProductGroup.Machines)) {
          this.routingService.navigateToHome()
        } else {
            this.routingService.navigateToRent(ProductGroup.Machines);
        }
    }

    public onNext(): void {
        this.routingService.navigateToRent(SUMMARY_STEP);
    }

    // public onSelectBundle(index: number): void {
    //     this.routingService.navigateToBundle(index);
    // }
}
