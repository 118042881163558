<div class="d-flex flex-column justify-content-between h-100">
    <div class="w-100 title">
        <h1>UPDATE KIOSK JDE</h1>
    </div>
    <div class="w-100 d-flex flex-column input-label">
        <label [ngClass]="{ 'placeholder': input.value.length === 0 }" class="d-flex align-items-center justify-content-center">
            {{input.value || 'Enter kiosk JDE'}}
        </label>
        <span *ngIf="error" class="message">{{ error }}</span>
    </div>
    <div class="keyboard">
        <app-keyboard [formControl]="input" [layout]="'numeric'" [value]="input.value"></app-keyboard>
    </div>
    <div class="buttons d-flex w-100 justify-content-end">
        <button (click)="onRegisterKiosk()" [ngClass]="{ 'disabled': !input.valid }" class="btn-rug button d-flex align-items-center justify-content-center">
            CONFIRM
            <img alt="confirm" src="assets/img/arrow-right-white.svg"/>
        </button>
    </div>
</div>
