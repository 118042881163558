import { Component, Output, EventEmitter } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { CartService } from '@shared/stores/cart.service'
import { CouponService } from '@shared/services/coupon.service'
import { Coupon } from 'src/app/domain/coupon.model'
import { RoutingService } from '@shared/services'
import { KeyboardLayout } from 'src/app/components/keyboard/keyboard.component'

const SUMMARY_STEP = 'summary'
@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent {
  public KeyboardLayout = KeyboardLayout;
  public error: string
  public input: FormControl
  private coupon: Coupon
  public get discount(): string { return this.coupon?.discount }
  public get continueText(): string { return (this.error) ? 'RENT.COUPON.TRY_ANOTHER' : 'GLOBAL.DONE' }

  public get couponErrorMsg(){
    return this.cartService.couponError
  }


  public constructor(
    private cartService: CartService,
    private couponService: CouponService,
    private routingService: RoutingService
  ) {
    this.input = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(7)])
  }

  public onContinue(): void {
    if (this.error) {
      this.completeError()
    } else if (this.coupon) {
      this.completeProcess()
    } else if (this.input.value && this.input.valid) {
      this.requestCouponInformation()
    }
  }

  private completeError(): void {
    this.error = null
    this.input.setValue('')
  }

  private completeProcess(): void {
    this.coupon = null
    this.input.setValue('')
    this.routingService.navigateToRent(SUMMARY_STEP)
  }

  private requestCouponInformation(): void {
    this.error = ""
    this.couponService.getCouponsByCode(this.input.value).subscribe(coupons => {
      if (coupons.length > 0) {
        if (coupons[0].validity) {
          if (this.handleCouponErrorMsg(coupons[0])) {
            //if (this.cartService.checkCouponAmountEligible(coupons[0])) {
            this.coupon = coupons[0]
            this.cartService.addCoupon(this.coupon)
            // } else {
            //   this.error = 'RENT.COUPON.WRONG_AMOUNT_TYPE'
            // }
          }
          //  else {
          //   this.error = 'RENT.COUPON.WRONG_TRANSACTION_TYPE'
          // }
        } else {
          this.error = 'RENT.COUPON.INACTIVE'
        }
      } else {
        this.error = 'RENT.COUPON.INVALID'
      }
    })
  }
  public handleCouponErrorMsg(coupon){
    const serviceTypes = coupon.serviceTypes || null
    const kioskTypeCoupon = /kiosk|rapid_rent/gi
    const transactionType = coupon.transactionType
    const findRental = this.cartService.items.find(item => item.type?.productGroup === 'machine')
    const findSolution = this.cartService.items.find(item => item.type?.productGroup === 'solution')
    const couponError = {
      not_valid: 'RENT.COUPON.INVALID',
      miss_solution:'RENT.COUPON.MISSING_SOLUTION',
      miss_machine :'RENT.COUPON.MISSING_MACHINE',
      remove_solution: 'RENT.COUPON.REMOVE_SOLUTION',
      remove_machine: 'RENT.COUPON.REMOVE_MACHINE',
      missing_items: 'RENT.COUPON.MISSING_ITEMS',
      invalid_location:  'RENT.COUPON.INVALID_LOCATION'
    }
    if(this.cartService.items.length === 0){
      this.error = couponError['missing_items']
         return false
    }
    else if(serviceTypes !== null && (typeof serviceTypes === 'string' && !kioskTypeCoupon.test(serviceTypes))){
      this.error = couponError['invalid_location']
         return false
    }
    else if(transactionType === 'R'){
      if(!findRental){
        this.error = couponError['miss_machine']
         return false
      }
      else if(findSolution){
        this.error = couponError['remove_solution']
        return false
      }
      else {
        return true
      }
    }
    else if(transactionType === 'B'){
      if(!findRental){
        this.error = couponError['miss_machine']
        return false
      }
      else if(!findSolution){
        this.error = couponError['miss_solution']
        return false
      }
      else {
        return true
      }
    }
    else if(transactionType === 'C'){
      if(findRental){
        this.error = couponError['remove_machine']
        return false
      }
      else if(findSolution){
        this.error = couponError['miss_solution']
        return false
      }
      else {
        return true
      }
    }
    else {
      return true
    }


  }
}
