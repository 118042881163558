import { ErrorHandler, NgModule } from '@angular/core'
import { UserIdleModule } from 'angular-user-idle'
import { SlickCarouselModule } from 'ngx-slick-carousel'
import { BrowserModule } from '@angular/platform-browser'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { AppRoutingModule } from './app-routing.module'
import { AuthenticationModule } from './modules/authentication-module/authentication.module'
import { AppComponent } from './app.component'
import { HomeComponent } from './screens/home/home.component'
import { SplashComponent } from './screens/splash/splash.component'
import { HeaderComponent } from './components/header/header.component'
import { HowToUseComponent } from './screens/tutorial/how-to-use/how-to-use.component'
import { HowToRentComponent } from './screens/tutorial/how-to-rent/how-to-rent.component'
import { ProgressTrackerComponent } from './components/progress-tracker/progress-tracker.component'
import { ListComponent } from './screens/rent/list/list.component'
import { ListItemComponent } from './screens/rent/list/list-item/list-item.component'
import { GalleryComponent } from './screens/rent/gallery/gallery.component'
import { CarouselComponent } from './screens/rent/carousel/carousel.component'
import { CarouselItemComponent } from './screens/rent/carousel/carousel-item/carousel-item.component'
import { MachinesComponent } from './screens/rent/machines/machines.component'
import { SolutionsComponent } from './screens/rent/solutions/solutions.component'
import { AttachmentsComponent } from './screens/rent/attachments/attachments.component'
import { BundlesComponent } from './screens/rent/bundles/bundles.component'
import { SummaryComponent } from './screens/rent/summary/summary.component'
import { SummaryPriceComponent } from './screens/rent/summary/summary-price/summary-price.component'
import { AgreementComponent } from './screens/rent/agreement/agreement.component'
import { TechnicalDetailsComponent } from './components/technical-details/technical-details.component'
import { PaymentComponent } from './screens/payment/payment.component'
import { KeyboardComponent } from './components/keyboard/keyboard.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BaysComponent } from './screens/bays/bays.component'
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component'
import { BulletsComponent } from './components/bullets/bullets.component'
import { ReturnComponent } from './screens/return/return.component'
import { AdminComponent } from './screens/admin/admin.component'
import { LoginComponent } from './screens/admin/login/login.component'
import { ScreenSaverComponent } from './screens/idle/screen-saver/screen-saver.component'
import { UserIdleComponent } from './screens/idle/user-idle/user-idle.component'
import { CouponComponent } from './screens/rent/coupon/coupon.component'
import { ConfigureBayComponent } from './screens/admin//configure-bay/configure-bay.component'
import { DropdownComponent } from './components/dropdown/dropdown.component'
import { ModalSelectorComponent } from './components/modal-selector/modal-selector.component'
import { BayConfigurationComponent } from './screens/admin/bay-configuration/bay-configuration.component'
import { KioskConfigurationComponent } from './screens/admin/kiosk-configuration/kiosk-configuration.component'
import { KioskDiagnosticComponent } from './screens/admin/kiosk-diagnostic/kiosk-diagnostic.component'
import { UpdateKioskJdeComponent } from './screens/admin/update-kiosk-jde/update-kiosk-jde.component'
import { UpdateKioskEnvironmentComponent } from './screens/admin/update-kiosk-environment/update-kiosk-environment.component'
import { AttachmentListComponent } from './screens/admin/configure-bay/attachment-list/attachment-list.component'
import { ReservationComponent } from './screens/reservation/reservation.component'
import { AdminLinkComponent } from './components/admin-link/admin-link.component'
import { CardReadComponent } from './components/card-read/card-read.component'
import { DisconnectedComponent } from './screens/disconnected/disconnected.component'
import { UpdatingComponent } from './screens/updating/updating.component'
import { PopupComponent } from './components/popup/popup.component'
import { NewBayComponent } from './screens/admin/new-bay/new-bay.component'
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
// import { CacheInterceptor } from './http-interceptors/cache-interceptor'

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient)
}
Bugsnag.start({ apiKey: '40a8e010e09a4077d286668c8af69e31' })

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SplashComponent,
    HeaderComponent,
    HowToRentComponent,
    HowToUseComponent,
    ProgressTrackerComponent,
    ListComponent,
    ListItemComponent,
    GalleryComponent,
    CarouselComponent,
    CarouselItemComponent,
    MachinesComponent,
    SolutionsComponent,
    AttachmentsComponent,
    BundlesComponent,
    SummaryComponent,
    SummaryPriceComponent,
    AgreementComponent,
    TechnicalDetailsComponent,
    PaymentComponent,
    KeyboardComponent,
    BaysComponent,
    CircleProgressComponent,
    BulletsComponent,
    ReturnComponent,
    AdminComponent,
    LoginComponent,
    ScreenSaverComponent,
    UserIdleComponent,
    CouponComponent,
    ConfigureBayComponent,
    DropdownComponent,
    ModalSelectorComponent,
    BayConfigurationComponent,
    KioskConfigurationComponent,
    KioskDiagnosticComponent,
    UpdateKioskJdeComponent,
    UpdateKioskEnvironmentComponent,
    AttachmentListComponent,
    ReservationComponent,
    AdminLinkComponent,
    CardReadComponent,
    DisconnectedComponent,
    UpdatingComponent,
    PopupComponent,
    NewBayComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthenticationModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    UserIdleModule.forRoot({ idle: 60, timeout: 30, ping: 5 }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SlickCarouselModule
  ],
  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    // { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }




