import { Component, Input, OnInit } from '@angular/core';
import { PaymentDetails } from 'src/app/domain/payment-details.model';
import { CartService } from 'src/app/shared/stores/cart.service';
@Component({
    selector: 'app-summary-price',
    templateUrl: './summary-price.component.html',
    styleUrls: ['./summary-price.component.scss']
})
export class SummaryPriceComponent implements OnInit {

    @Input() paymentDetails: PaymentDetails;
    public get subtotal(): number {
        return this.paymentDetails?.totalAmount;
    }
    public get tax(): number {
        return this.paymentDetails?.taxAmount;
    }
    public get loadingPrice(): boolean {
      return this.cartService.loadingPrice
  }
    public get coupon(): number {
        return this.paymentDetails?.totalCouponAmount;
    }
    public get total(): number {
        return (this.paymentDetails)
            ? this.paymentDetails.totalAmount + this.paymentDetails.taxAmount
            : null;
    }

    public constructor(private cartService: CartService) { }

    public ngOnInit(): void {}
}
