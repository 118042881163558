import { autoserializeAs } from 'cerialize'
import { Bay } from "./bay.model"

export enum BayDoorStatus {
  Open = 'open',
  Closed = 'closed'
}

export enum BeamStatus {
  Empty = 'empty',
  Filled = 'blocked'
}

export class BayBeamStatus {
  @autoserializeAs(BayDoorStatus, 'door_status') doorStatus: BayDoorStatus
  public bayId: number
  public beamStatus: BeamStatus[]
  public get isClosed(): boolean { return this.doorStatus === BayDoorStatus.Closed }
  public get isEmpty(): boolean {
    return this.beamStatus.reduce((result, beam: BeamStatus) => (result && beam === BeamStatus.Empty), true)
  }
  // Current number of occupied beams
  // used with BayTransactionAction's expectedBeamDiff to determine (pick up / return) validity
  public get beamStatusCount(): number {
    return this.beamStatus.reduce((result, beam: BeamStatus) => (result += (beam === BeamStatus.Empty) ? 0 : 1), 0)
  }
  public constructor() { }
  public static OnDeserialized(instance: BayBeamStatus, json: any): void {
    let i = 1
    instance.beamStatus = []
    while (json.beam_status['beam_' + i]) {
      instance.beamStatus.push(json.beam_status['beam_' + i] as BeamStatus)
      i++
    }
  }
  public compareBeams(other: BayBeamStatus): boolean {
    if (this.beamStatus.length !== other.beamStatus.length) {
      return false
    }
    for (let i = 0; i < this.beamStatus.length; i++) {
      if (this.beamStatus[i] !== other.beamStatus[i]) {
        return false
      }
    }
    return true
  }
}
