import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@shared/services/i18n.service';
import { RoutingService } from '@services';
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() redBorder: boolean;
  @Input() showLanguage: boolean;
  @Input() canNavigateBack: boolean;
  @Input() customBackNavigation: boolean;
  @Input() customBackText: string;
  @Output() back: EventEmitter<void>;
  public get testMode(): boolean { return this.authService.kioskTestToken != null; }

  public constructor(
    public i18n: InternationalizationService,
    public translate: TranslateService,
    public routingService: RoutingService,
    public authService: AuthenticationService
  ) {
    this.back = new EventEmitter();
    this.translate.setDefaultLang(this.i18n.lang);
  }

  public onSwitchLanguage(): void {
    if(this.showLanguage) {
      this.i18n.switch();
      this.translate.setDefaultLang(this.i18n.lang);
    }
  }

  public onExitTestMode(): void {
    this.authService.destroyTestToken();
    this.routingService.navigateToAdmin();
  }

  public onBack(): void {
    if (this.customBackNavigation) {
      this.back.emit();
    } else {
      this.routingService.navigateBack();
    }
  }
}
