import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { RoutingService } from '@services'
import { Language, InternationalizationService } from '@shared/services/i18n.service'
import { IdleService } from '@shared/services/idle.service'
import { ProductService } from '@shared/services/product.service'
import { ProductType } from 'src/app/domain/product-type.model'
import { KioskServerService } from '@shared/services/kiosk-server.service'

const KIOSK_ID_KEY = 'kiosk_id'
const KIOSK_TOKEN_KEY = 'kiosk_token'

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  public featured: ProductType
  public kioskJde: string
  public get price(): number { return (this.featured) ? this.featured.price : null }

  constructor(
    public idleService: IdleService,
    public translate: TranslateService,
    public products: ProductService,
    public routingService: RoutingService,
    public i18n: InternationalizationService,
    private kioskServerService: KioskServerService
  ) {
    translate.addLangs(['en'])
    translate.setDefaultLang(i18n.lang)
  }

  public ngOnInit(): void {
    if (
      !('kiosk_jde' in localStorage) ||
      !(KIOSK_ID_KEY in localStorage) ||
      !(KIOSK_TOKEN_KEY in localStorage)
    ) {
      this.kioskServerService.getKioskConfig().subscribe(next => {
        this.kioskJde = next.jde

        localStorage.setItem('kiosk_jde', this.kioskJde.toString())
        localStorage.setItem(KIOSK_ID_KEY, next.kioskId.toString())
        localStorage.setItem(KIOSK_TOKEN_KEY, next.token)

        window.location.reload()
      },
        error => {
          console.log(error)
        })
    } else {
      this.kioskJde = localStorage.getItem('kiosk_jde')
    }

    this.products.getFeaturedProductType().subscribe(featured => this.featured = featured)
  }
  public selectLang(lang: string): void {
    this.idleService.restart()
    this.i18n.lang = lang as Language
    this.routingService.navigateToHome()
  }
}
