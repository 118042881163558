import { Component, ChangeDetectorRef, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SlickCarouselComponent } from 'ngx-slick-carousel'
import { CartService } from '@shared/stores/cart.service'
import { ModalService } from '@shared/services/modal.service'
import { InternationalizationService } from '@shared/services/i18n.service'
import { Bundle } from 'src/app/domain/bundle.model'
import { RentalProduct } from 'src/app/domain/rental-product'
import { PresentationItem } from 'src/app/domain/presentation-item.model'
import { ProductGroup, ProductType } from 'src/app/domain/product-type.model'
import { StockService } from "@shared/stores/stock.service"

const CAROUSEL_NAME = 'slickCarousel'

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @Input() defaultSlide: number
  @Input() skipText: string
  @Input() hasGroup: boolean
  @Input() items: PresentationItem<ProductType | Bundle>[]
  @Input() setCurrentSlideItem: (args: any) => void;
  @Output() next: EventEmitter<void>
  @Output() swiped: EventEmitter<number>
  @ViewChild(CAROUSEL_NAME) slick: any
  public currentSlide: number
  public get length(): number { return (this.items) ? this.items.length : 0 }
  public get available(): boolean { return this.stockService.available(this.items[this.currentSlide].id) }
  public config: object
  public showDetails = false;
  public details = '';
  public emptyMessage() {
    let str = window.location.pathname
    if(typeof str === 'string' && str.includes('machine')){
      return 'RENT.EMPTY_MACHINE'
    } else {
      return 'RENT.DEFAULT'
    }
  }

  public constructor(
    public translate: TranslateService,
    public i18n: InternationalizationService,
    public modalService: ModalService,
    private cartService: CartService,
    private stockService: StockService,
    private cdr: ChangeDetectorRef
  ) {
    this.currentSlide = 0
    this.next = new EventEmitter()
    this.swiped = new EventEmitter()
    this.config = {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      touchThreshold: 25,
      arrow: false
    }
    translate.setDefaultLang(i18n.lang)
  }
  public ngOnInit(): void { }
  public onSlickInit(event: any) {
    const slick = event.slick
    if (slick && this.defaultSlide) {
      this.currentSlide = this.defaultSlide
      slick.slickGoTo(this.defaultSlide)
      this.cdr.detectChanges()

    }
  }

  public onBeforeChange(event: any): void {
    if(typeof this.setCurrentSlideItem === 'function'){
      this.setCurrentSlideItem(event.nextSlide)
  }
    this.currentSlide = event.nextSlide
    this.swiped.emit(this.currentSlide)

  }

  public onAdd() {
    const item: PresentationItem<ProductType | Bundle> = this.items[this.currentSlide]

    if (!this.stockService.available(item.id)) {
      return
    }

    if (item.group === ProductGroup.Bundle) {
      this.cartService.addBundle(item.originalValue as Bundle)
    } else {
      this.cartService.add(new RentalProduct(item.originalValue as ProductType))
    }
  }

  public onNext(): void {
    this.next.emit()
  }

  public toNextSlide(): void {
    if (this.slick && this.slick.currentIndex < this.slick.slides.length - 1) {
      this.slick.slickGoTo(this.slick.currentIndex + 1)
      if(typeof this.setCurrentSlideItem === 'function'){
          this.setCurrentSlideItem(this.slick.currentIndex)
      }

    }
  }

  public toPreviousSlide(): void {
    if (this.slick && this.slick.currentIndex > 0) {
      this.slick.slickGoTo(this.slick.currentIndex - 1)
      if(typeof this.setCurrentSlideItem === 'function'){
        this.setCurrentSlideItem(this.slick.currentIndex)
    }

    }
  }

  public slidesTrackBy(index: number): number {
    return index
  }

  public onHideDetails() {
    this.showDetails = false
  }
  public openDetails() {
    this.showDetails = true
    this.details = this.items[this.currentSlide].details
  }

}
