import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  public listVisible: boolean;
  @Input() value: string;
  @Input() placeholder: string;
  @Input() options: any[];
  @Output() selected: EventEmitter<string>;
  @ViewChild('inner') innerList;
  public get valueText(): string {
    if (this.value == null) {
      return this.placeholder;
    }
    const valueArray: any[] = this.options.filter(option => option.key === this.value);
    if (valueArray.length === 0) {
      return this.placeholder;
    }
    return valueArray[0].text;
  }
  public constructor() {
    this.listVisible = false;
    this.selected = new EventEmitter();
  }
  public ngOnInit(): void {
    if (!this.placeholder) {
      this.placeholder = 'Select value';
    }
  }
  public onSelectValue(value: string): void {
    this.selected.emit(value);
    this.listVisible = false;
  }
  public toggleList(): void {
    this.listVisible = !this.listVisible;
  }
}
