<ngx-slick-carousel class="carousel" #slickCarousel="slick-carousel" [config]="config" (init)="onSlickInit($event)"
    (beforeChange)="onBeforeChange($event)">
    <div *ngIf="!items || items.length === 0" class="empty">
        <h1 class="text-center">
            {{ emptyMessage() | translate | uppercase}}</h1>
    </div>
    <app-carousel-item
        *ngFor="let item of items; let i = index; let odd = odd; trackBy: slidesTrackBy"
        ngxSlickItem
        [item]="item"
        [odd]="odd"
        (showDetails)="openDetails()"
    >
    </app-carousel-item>
</ngx-slick-carousel>
<div class="d-flex w-100 flex-column or-choose">
    <div class="bullets">
        <app-bullets [index]="currentSlide" [length]="length"></app-bullets>
    </div>
    <div *ngIf="items.length > 0" class="d-flex w-100 justify-content-between">
        <div class="d-flex justify-content-center arrow-box">
            <div (click)="toPreviousSlide()" *ngIf="currentSlide !== 0" class="btn-rug d-flex justify-content-center align-items-center arrow previous-slide">
                <img alt="left" src="assets/img/arrow-left-white-48.svg"/>
            </div>
        </div>
        <button (click)="onAdd()" [ngClass]="{ 'disabled': !available }" class="btn-rug rent-button left add">
            {{ 'RENT.ADD_TO_CART' | translate | uppercase }}
            <img alt="cart" src="assets/img/shopping-bag.svg"/>
        </button>
        <div class="d-flex justify-content-center arrow-box">
            <div (click)="toNextSlide()" *ngIf="currentSlide !== length - 1" class="btn-rug d-flex justify-content-center align-items-center arrow next-slide">
                <img alt="right" src="assets/img/arrow-right-white-48.svg"/>
            </div>
        </div>
    </div>
    <div [ngClass]="{ 'has-group': hasGroup }" class="d-flex buttons">
        <span (click)="onNext()" *ngIf="!hasGroup && items.length > 0" class="btn-rug hollow skip-step d-flex justify-content-center align-items-center">
            {{ (skipText || 'RENT.SKIP_STEP') | translate | uppercase }}
        </span>
        <span (click)="onNext()" *ngIf="hasGroup || (!hasGroup && items.length === 0)" class="btn-rug next-step d-flex justify-content-center align-items-center">
            {{ 'RENT.NEXT_STEP' | translate | uppercase }}
            >
        </span>
    </div>
</div>
<img alt="background" class="static-background" src="assets/img/rent-static-background.jpg"/>
<app-popup (hide)="onHideDetails()" [presentationItem]="this.items[this.currentSlide]" [visible]="showDetails"></app-popup>
