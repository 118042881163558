import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core'
import { interval } from 'rxjs/internal/observable/interval'
import { Subscription, Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { Card } from 'src/app/domain/card.model'
import { KioskServerService } from '@shared/services/kiosk-server.service'
import { CardTracks } from '../../domain/card-tracks.model'
import { environment } from '@environment/environment'
import { getDummyCardTracksData } from '../../../testing/dummy-data'

const READ_INTERVAL = 1000

@Component({
  selector: 'app-card-read',
  templateUrl: './card-read.component.html',
  styleUrls: ['./card-read.component.scss']
})
export class CardReadComponent implements OnInit, OnDestroy {
  private subscription: Subscription
  public cardReadError = false;
  private track1regex = /%B([0-9]{16})\^([\s\S]*)\/([\s\S]*)\^([0-9]{2})([0-9]{2})([0-9]{3})/;
  private track2regex = /;([0-9]{16})=([0-9]{2})([0-9]{2})([0-9]{3})/;
  @Output() readComplete: EventEmitter<CardTracks>
  public constructor(
    public kioskServerService: KioskServerService
  ) {
    this.readComplete = new EventEmitter<CardTracks>()
  }
  public ngOnInit(): void {
    if (environment.kiosk) {
      this.subscription = interval(READ_INTERVAL)
        .pipe(
          switchMap(() => this.doReadTracks())
        )
        .subscribe(result => {
          if (result instanceof CardTracks && (
            (result.tracks[0] && result.tracks[0].match(this.track1regex))
            || (result.tracks[1] && result.tracks[1].match(this.track2regex))
          )) {
            this.subscription.unsubscribe()
            this.readComplete.emit(result as CardTracks)

            this.cardReadError = false
          } else if (result instanceof CardTracks) {

            this.cardReadError = true
          }
        })
    } else {
      this.readComplete.emit(getDummyCardTracksData().deserializedObject as CardTracks)
    }
  }
  public ngOnDestroy(): void {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe()
    }
  }
  private doReadCard(): Observable<Card | string> {
    return new Observable(observer => {
      this.kioskServerService.readCard()
        .subscribe(card => {
          observer.next(card)
          observer.complete()
        }, error => {
          observer.next(error)
          observer.complete()
        })
    })
  }

  private doReadTracks(): Observable<CardTracks | string> {
    return new Observable(observer => {
      this.kioskServerService.readTracks()
        .subscribe(card => {
          observer.next(card)
          observer.complete()
        }, error => {
          observer.next(error)
          observer.complete()
        })
    })
  }
}
