<div class="progress">
    <div class="progress-box d-flex align-items-center">
        <div class="line"></div>
    </div>
    <div class="progress-box position-absolute d-flex align-items-center justify-content-start">
        <div class="line red" [ngClass]="'progress-'+progress"></div>
    </div>
    <div class="progress-box position-absolute d-flex justify-content-between">
        <div class="circle active" [ngClass]="{ 'current': progress == 0 }">
            <div class="middle"></div>
        </div>
        <div class="circle" [ngClass]="{ 'active': progress > 0, 'current': progress == 1 }">
            <div class="middle"></div>
        </div>
        <div class="circle" [ngClass]="{ 'active': progress > 1, 'current': progress == 2 }">
            <div class="middle"></div>
        </div>
        <div class="circle" [ngClass]="{ 'active': progress > 2, 'current': progress == 3 }">
            <div class="middle"></div>
        </div>
    </div>
</div>
