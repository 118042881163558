import { Component, OnInit, OnDestroy } from '@angular/core'
import { interval } from 'rxjs/internal/observable/interval'
import { timer } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { InternationalizationService } from '@shared/services/i18n.service'
import { TimerService } from '@shared/services/timer.service'
import { BaysService } from '@shared/stores/bays.service'
import { KioskServerService } from '@shared/services/kiosk-server.service'
import { BayBeamStatus, BeamStatus } from 'src/app/domain/bay-beam-status.model'
import { RoutingService } from '@shared/services'
import { RentalService } from '@shared/services/rental.service'
import { BayTransactionType } from 'src/app/domain/bay-transaction.model'
import { BayTransactionAction, BayTransactionActionType } from 'src/app/domain/bay-transaction-action.model'
import { Subscription } from 'rxjs'
import { SettingsService } from '@shared/stores/settings.service'
import { BayType } from "src/app/domain/bay.model"
import { CartService } from "@shared/stores/cart.service"
import { IdleService } from '@shared/services/idle.service'

const DURATION = 30
const POLL_INTERVAL = 1000
const CANCEL_DELAY = 10000

@Component({
  selector: 'app-bays',
  templateUrl: './bays.component.html',
  styleUrls: ['./bays.component.scss']
})
export class BaysComponent implements OnInit, OnDestroy {
  public time: number
  public cancelled: boolean
  public transactionConfirmed: boolean
  private initialBayStatus: BayBeamStatus[]
  public pollBeamSubscription: Subscription
  public timerTickSubscription: Subscription

  // Return tracking
  public hasReturnMachine: boolean
  public isMachineReturned: boolean

  // in case customer takes item and returns it before closing the door
  public options: any[]

  public get complete(): boolean { return this.baysService.isComplete }
  public get action(): BayTransactionAction { return this.baysService.action }
  public get actions(): BayTransactionAction[] { return this.baysService.actions }
  public get shelfOnly(): boolean { return (!this.baysService.transaction) ? false : this.baysService.transaction.isShelfOnly }
  public get shelfAction(): boolean { return (!this.action) ? null : this.action.type === BayTransactionActionType.PickUpShelf }
  public get doneVisible(): boolean { return this.kioskServerService.kioskDevMode || !this.settingsService.settings.pollBeams }
  public get title(): string {
    return (this.action.type === BayTransactionActionType.PickFromBay) ? 'BAYS.TITLE' : 'BAYS.RETURN_TITLE'
  }
  public get bayText(): string {
    return (this.action.type === BayTransactionActionType.PickFromBay) ? 'BAYS.DONE_LABEL_1' : 'BAYS.DONE_LABEL_1a'
  }
  public get messageType(): string {
    return this.baysService.transaction?.type
  }
  public get duration(): number { return DURATION }

  public constructor(
    private baysService: BaysService,
    private rentalService: RentalService,
    private routingService: RoutingService,
    private settingsService: SettingsService,
    private cartService: CartService,
    public timer: TimerService,
    public delay: TimerService,
    public translate: TranslateService,
    public i18n: InternationalizationService,
    public kioskServerService: KioskServerService,
    private idleService: IdleService,
  ) {
    this.cancelled = false
    this.transactionConfirmed = false
    this.hasReturnMachine = false
    this.isMachineReturned = false
    this.initialBayStatus = []

    translate.setDefaultLang(i18n.lang)
  }

  public ngOnInit() {
    if (this.actions.length > 0) {
      this.startAction()
    } else {
      this.transactionConfirmed = true
    }
  }

  public ngOnDestroy() {
    if (this.pollBeamSubscription && !this.pollBeamSubscription.closed) {
      this.pollBeamSubscription.unsubscribe()
    }
    if (this.timerTickSubscription && !this.timerTickSubscription.closed) {
      this.timerTickSubscription.unsubscribe()
    }
  }

  public startAction(): void {
    if (this.baysService.transaction.type === BayTransactionType.Rent) {
      this.rentalService.assignBays(this.cartService.rental)
    }

    if (
      this.action.type === BayTransactionActionType.PickFromBay ||
      this.action.type === BayTransactionActionType.ReturnToBay
    ) {
      this.prepareBays(true)
    }
  }

  public prepareBays(runAttempt): void {
    this.actions.forEach((action: BayTransactionAction, currentAction: number) => {
      this.kioskServerService.initialBayStatus(
        action.number,
        action.capacity
      )
        .subscribe(
          (result: BayBeamStatus) => {
            this.initialBayStatus[currentAction] = result
            this.openBay(action.number)

            if (this.baysService.transaction.type === BayTransactionType.Return && action.bay_type === BayType.Machine) {
              this.hasReturnMachine = true
              this.pollStatus(currentAction)
            }
          },
          error => {
            if(runAttempt){
              this.handleInitialBayStatusErrors(error)
            }
          }
        )
    })

    this.startTimer()
  }


  public handleInitialBayStatusErrors(error): void {
    this.prepareBays(false)
  }

  public beamStatusNumberMatch(count: number, bayId: number): boolean {
    let expectedBeamDiff =  this.actions[bayId].expectedBeamDiff
    let beamStatusCount = this.initialBayStatus[bayId].beamStatusCount
    if (!this.settingsService.settings.pollBeams || this.kioskServerService.kioskDevMode) {
      return true
    }

    // return count === this.actions[bayId].expectedBeamDiff + this.initialBayStatus[bayId].beamStatusCount
    return count === expectedBeamDiff
  }

  public openBay(bayId: number): void {
    this.kioskServerService.openBay(bayId)
      .subscribe((data: any) => {
        //
      })
  }

  public pollStatus(currentAction: number): void {
    this.pollBeamSubscription = interval(POLL_INTERVAL)
      .pipe(
        switchMap(() => this.kioskServerService.bayStatus(
          this.actions[currentAction].number,
          this.actions[currentAction].capacity
        ))
      )
      .subscribe(result => this.handlePollStatus(result, currentAction))
  }

  public handlePollStatus(status: BayBeamStatus, currentAction: number): void {
    if (this.baysService.transaction.type === BayTransactionType.Return &&this.actions[currentAction] && this.actions[currentAction].bay_type === BayType.Machine) {
      if (this.beamStatusNumberMatch(status.beamStatusCount, currentAction)) {
        this.isMachineReturned = true
      } else {
        this.isMachineReturned = false
      }
    }
  }

  public closeBay(bayId: number): void {
    this.kioskServerService.closeBay(bayId)
      .subscribe((data: any) => {
        //
      })
  }

  public startTimer(): void {
    if (this.timerTickSubscription && !this.timerTickSubscription.closed) {
      this.timerTickSubscription.unsubscribe()
    }

    this.time = 0
    this.timerTickSubscription = this.timer.start(DURATION)
      .subscribe(data => {
        this.time = data.time

        if (data.time >= DURATION) {
          this.actions.forEach((action: BayTransactionAction, currentAction: number) => {
            this.closeBay(action.number)
          })

          if (this.baysService.transaction.type === BayTransactionType.Return && (!this.hasReturnMachine || (this.hasReturnMachine && this.isMachineReturned))) {
            this.completeReturn()
          } else if (this.baysService.transaction.type === BayTransactionType.Return && (this.hasReturnMachine && !this.isMachineReturned)) {
            this.cancelActions()
          } else if (this.baysService.transaction.type === BayTransactionType.Rent) {
            this.completePickup()
          } 
          timer(CANCEL_DELAY)
            .subscribe(this.routingService.navigateToSplashScreen)
        }
      })
  }

  // public updateAction(currentAction: number): void {
  //   if (this.action.type === BayTransactionActionType.PickUpShelf) {
  //     return
  //   }
  //   this.kioskServerService.bayStatus(
  //     this.baysService.action.number,
  //     this.baysService.action.capacity
  //   ).subscribe((result: BayBeamStatus) => {
  //     const beamStatusOk = this.kioskServerService.kioskDevMode || this.beamStatusNumberMatch(result.beamStatusCount, currentAction)
  //     if (beamStatusOk && result.isClosed) {
  //       this.completeAction(currentAction)
  //     } else {
  //       this.openBay(this.actions[currentAction].number)
  //     }
  //   })
  // }

  public cancelActions() {
    // Cancel Transaction
    this.actions.forEach((action: BayTransactionAction, currentAction: number) => {
      this.closeBay(action.number)
    })

    this.baysService.completeActions()
    this.cancelled = true
    this.transactionConfirmed = true
  }

  // public completeActionAndClose(currentAction: number) {
  //   let number = this.actions[currentAction].number
  //   this.closeBay(number)

  //   // Complete Transaction
  //   this.completeAction(currentAction)
  // }

  // public completeAction(currentAction: number): void {
  //   this.baysService.completeAction(currentAction)
  //   if (!this.baysService.isComplete) {
  //     this.startAction()
  //   } else {
  //     if (this.baysService.transaction.type === BayTransactionType.Rent) {
  //       this.completePickup()
  //     } else if (this.baysService.transaction.type === BayTransactionType.Return) {
  //       this.completeReturn()
  //     }
  //   }
  // }

  public completePickup(): void {
    this.rentalService.pickUp(this.baysService.transaction.reservation.id).subscribe(
      next => {
        this.baysService.completeActions()
        this.transactionConfirmed = true
        this.idleService.start()
      },
      error => {
        console.log(error)
      })
  }

  public completeReturn(): void {
    this.rentalService.return(this.baysService.transaction.reservation.id).subscribe(() => {
      this.baysService.completeActions()
      this.transactionConfirmed = true
      this.idleService.start()
    },
      error => {
        console.log(error)
      })
  }

  public onDone(): void {
    this.timer.stop()
    this.routingService.navigateToSplashScreen()
  }

  public onComplete(): void {
    if (!this.transactionConfirmed) {
      return
    }
    this.timer.stop()
    this.routingService.navigateToSplashScreen()
  }
}
