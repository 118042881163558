import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bullets',
  templateUrl: './bullets.component.html',
  styleUrls: ['./bullets.component.scss']
})
export class BulletsComponent {

  @Input() length: number;
  @Input() index: number;
  public get bullets(): number[] { return Array(this.length); }
  public constructor() { }
}
