<div class="d-flex flex-column key-row keyboard">
    <div *ngFor="let row of activeLayout"
        class="d-flex flex-row justify-content-between key-row"
        [ngStyle]="{ 'padding':'0 ' + row.padding + '%' }"
    >
        <div *ngFor="let key of row.chars"
            (click)="onPress(key)"
            class="d-flex justify-content-center align-items-center key"
            [ngClass]="{ 'special':isSpecial(key), 'space':key == 'SPACE' }"
        >
            <span *ngIf="!isSpecial(key)" [ngClass]="{'longer':key.length > 1}">{{ (capsLock) ? (key | uppercase) : (key | lowercase) }}</span>
            <span *ngIf="key == 'SPACE'">space</span>
            <span *ngIf="key == 'CLEAR'">{{ (layout !== 'numeric') ? 'clear' : 'C' }}</span>
            <img class="icon" *ngIf="key == 'SHIFT'" src="assets/img/keyboard/shift.png" alt="shift"/>
            <img [ngClass]="{ 'icon': layout !== 'numeric'}" *ngIf="key == 'BACKSPACE'" src="assets/img/keyboard/backspace.png" alt="backspace"/>
            <span *ngIf="key == 'SWITCH'">{{switchLabel}}</span>
        </div>
    </div>
</div>