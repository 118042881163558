import { Component, Output, EventEmitter } from '@angular/core'
import { RoutingService } from '@services'
import { KioskServerService } from '@shared/services/kiosk-server.service'
import { FormControl, Validators } from '@angular/forms'

const ERROR_MESSAGE = 'Cannot find kiosk with this JDE.'

const KIOSK_JDE_KEY = 'kiosk_jde'
const KIOSK_ID_KEY = 'kiosk_id'
const KIOSK_TOKEN_KEY = 'kiosk_token'

const ENVIRONMENTS = [
  "TESTING",
  "DEVELOPMENT",
  "STAGING",
  "UAT",
  "PRODUCTION",
]

@Component({
  selector: 'app-update-kiosk-environment',
  templateUrl: './update-kiosk-environment.component.html',
  styleUrls: ['./update-kiosk-environment.component.scss']
})
export class UpdateKioskEnvironmentComponent {
  @Output() completed: EventEmitter<void>

  public error: string
  public select: FormControl
  public environments: Array<Object>

  public constructor(
    public routingService: RoutingService,
    private kioskServerService: KioskServerService
  ) {
    this.completed = new EventEmitter()
    this.environments = ENVIRONMENTS
    this.select = new FormControl('', [Validators.required])

    this.kioskServerService.getKioskConfig()
      .subscribe(next => {
        this.select.setValue(next.kioskEnvironment || ENVIRONMENTS[0])
      },
        error => {
          this.select.setValue(ENVIRONMENTS[0])
        })
  }

  public update(e) {
    this.select = e.target.value
  }

  public onUpdateKioskEnvironment(): void {
    if (!this.select.valid) {
      return
    }

    const kioskJde = localStorage.getItem(KIOSK_JDE_KEY)
    const kioskId = localStorage.getItem(KIOSK_ID_KEY)
    const kioskToken = localStorage.getItem(KIOSK_TOKEN_KEY)

    this.kioskServerService.saveKioskConfig(kioskJde.toString(), kioskToken.toString(), parseInt(kioskId), this.select.value)
      .subscribe(next => {
        this.routingService.navigateToUpdating()
      },
        error => {
          this.completed.emit()
          window.location.reload()
        })
  }
}
