import { Component, Input, Output, ViewChild, EventEmitter, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@shared/services/i18n.service';
import { PresentationItem } from 'src/app/domain/presentation-item.model';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ProductType } from 'src/app/domain/product-type.model';
import { RentalProduct } from 'src/app/domain/rental-product';
import { Bundle } from 'src/app/domain/bundle.model';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnChanges {

    @Input() autoScroll: boolean;
    @Input() pluses: boolean;
    @Input() selectable: boolean;
    @Input() removable: boolean;
    @Input() title: string;
    @Input() items: PresentationItem<ProductType | RentalProduct | Bundle>[];
    @Output() selected: EventEmitter<number>;
    @Output() remove: EventEmitter<number>;
    @ViewChild('slickList') public slick: SlickCarouselComponent;
    public count: number;
    public config: any;

    constructor(
        public translate: TranslateService,
        public i18n: InternationalizationService,
    ) {
        this.selected = new EventEmitter<number>();
        this.remove = new EventEmitter();
        this.config = {
            slidesToShow: 2.2,
            slidesToScroll: 2,
            infinite: false
        };
        translate.setDefaultLang(i18n.lang);
    }
    private mousePosition = {
      x: 0,
      y: 0
    };
    mouseDown($event) {
      this.mousePosition.x = $event.screenX;
      this.mousePosition.y = $event.screenY;
    }

    public ngOnChanges(): void {
        if (this.autoScroll && this.count && this.items.length > this.count && this.items.length > 2) {
            this.slick.slickGoTo(this.items.length);
        }
        this.count = this.items.length;
    }

    public onSelect($event, index: number): void {
        if (this.selectable) {
          if (this.mousePosition.x === $event.screenX && this.mousePosition.y === $event.screenY) {
            this.selected.emit(index);
          }

        }
    }

    public slidesTrackBy(index: number): number {
        return index;
    }
}
