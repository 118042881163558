<div class="screen-wrapper d-flex flex-column justify-content-between">
  <div class="logo">
    <img src="/assets/img/logo.svg" alt="RugDoctor">
  </div>
  <div class="content">
    <div class="heading">
      <h3>It’s not rocket science</h3>
      <h1>Quality carpet cleaning</h1>
    </div>
    <div class="row description w-100">
      <div class="col-6 hero"><p>Rent a professional cleaning machine today for just</p></div>
      <div class="col-6 price"><h1 *ngIf="price">${{ price }}</h1></div>
    </div>
    <div class="select-language d-flex justify-content-between">
      <button class="btn-rug btn-rug-xl" (click)="selectLang('en')">Touch to start</button>
      <!-- <button class="btn-rug btn-rug-xl" (click)="selectLang('es')">presione para comenzar</button> -->
    </div>
    <div class="contact d-flex justify-content-between">
<!--     TODO (877) 343-0588 is correct but admin should be able to change it-->
<!--     TODO 8774704111 = mrent is correct but admin should be able to change it-->
      <p>For assistance call: (877) 343-0588</p>
      <p>Kiosk JDE: {{kioskJde ? kioskJde : '/'}}</p>
    </div>
  </div>
</div>
<app-admin-link></app-admin-link>
