<app-header [canNavigateBack]="true" [redBorder]="true"></app-header>
<div class="reservation page d-flex justify-content-between flex-column">
    <div class="w-100 title">
        <h1>{{ 'RESERVATIONS.TITLE' | translate | uppercase }}</h1>
    </div>
    <div class="w-100 d-flex flex-column input-label">
        <label
            class="d-flex align-items-center justify-content-center"
            [ngClass]="{ 'placeholder': input.value.length === 0 }">
            {{input.value || 'Reservation Code'}}
        </label>
        <span *ngIf="message" class="message">{{ message | translate | uppercase }}</span>
    </div>
    <div class="keyboard">
        <app-keyboard [value]="input.value" [formControl]="input" [disableTempCaps]="true"></app-keyboard>
    </div>
    <div class="buttons d-flex w-100 justify-content-end">
        <button
            (click)="onFindReservation()"
            [ngClass]="{ 'disabled': !input.valid }"
            class="btn-rug button d-flex align-items-center justify-content-center">
            CONFIRM
            <img src="assets/img/arrow-right-white.svg" alt="confirm"/>
        </button>
    </div>
    <div class="contact d-flex justify-content-between">
      <!--     TODO (877) 343-0588 is correct but admin should be able to change it-->
      <!--     TODO 8774704111 = mrent is correct but admin should be able to change it-->
            <p>For assistance call: (877) 343-0588</p>
            <p>Kiosk JDE: {{kioskJde ? kioskJde : '/'}}</p>
          </div>
</div>
<img class="decoration-path" src="assets/img/path.svg" alt="path"/>
<img class="decoration-background" src="assets/img/bays-background.jpg" alt="background"/>
