<div class="configure-bay d-flex flex-column justify-content-between">
    <div>
        <div class="header d-flex justify-content-between align-items-center">
            <h1>Configure Bay</h1>
            <span class="bay-number d-flex align-items-center justify-content-center">{{ bay.number }}</span>
        </div>
        <div class="form d-flex flex-column">
            <label>BAY TYPE</label>
            <div class="d-flex justify-content-between input-box checkboxes">
                <span (click)="onChangeType('mch')" class="input-rug">
                    <span>Machine</span>
                    <img *ngIf="bay.type == 'mch'" src="/assets/img/checked.svg" alt="checked"/>
                    <img *ngIf="bay.type != 'mch'" src="/assets/img/unchecked.svg" alt="unchecked"/>
                </span>
                <span (click)="onChangeType('uph')" class="input-rug">
                    <span>Attachment</span>
                    <img *ngIf="bay.type == 'uph'" src="/assets/img/checked.svg" alt="checked"/>
                    <img *ngIf="bay.type != 'uph'" src="/assets/img/unchecked.svg" alt="unchecked" />
                </span>
            </div>
            <div *ngIf="bay.type == 'mch'">
                <label>BAY STATUS</label>
                <app-dropdown (selected)="onChangeState($event)" [options]="bayStatusOptions" [value]="bay.state" ></app-dropdown>
            </div>
            <div *ngIf="bay.type == 'uph'">
                <label>NUMBER OF BEAMS</label>
                <app-dropdown (selected)="onChangeCapacity($event)" [options]="noBeamsOptions" [value]="capacity" ></app-dropdown>
            </div>
        </div>
        <div *ngIf="bay.type == 'mch'" class="form dimmed d-flex flex-column">
            <label>ITEM NAME</label>
            <div (click)="onSelectProduct()" class="d-flex justify-content-between input-box">
                <span class="input-rug"> {{ (referenceMachineType) ? referenceMachineType.name : 'Empty' }} </span>
            </div>
            <label>SERIAL NUMBER</label>
            <div class="d-flex justify-content-between input-box serial-input" (click)="onToggleKeyboard(true)">
                <span class="input-rug"> {{ input.value }} </span>
                <button *ngIf="keyboardVisible" class="btn-rug" (click)="onToggleKeyboard(false, $event)">DONE</button>
            </div>
        </div>
        <app-attachment-list
            *ngIf="bay.type == 'uph'"
            [maximum]="bay.capacity"
            [existing]="bayStatus.products"
            (changed)="onUpdateSelectedAttachments($event)">
        </app-attachment-list>
    </div>
    <div *ngIf="keyboardVisible" class="keyboard">
        <app-keyboard [value]="input.value" [formControl]="input" [disableTempCaps]="true"></app-keyboard>
    </div>
    <div *ngIf="!keyboardVisible" class="buttons d-flex w-100 justify-content-between">
        <button (click)="goBack()" class="btn-rug button hollow">CANCEL</button>
        <button (click)="onUpdate()" class="btn-rug button" [ngClass]="{ 'disabled': !isUpdateEnabled() }">UPDATE</button>
    </div>
    <app-modal-selector *ngIf="selectorItems" [options]="selectorItems" (selected)="onProductSelected($event)" (closed)="onCloseSelector()"></app-modal-selector>
</div>
<div *ngIf="error" class="dialog">
    <div class="dialog-box d-flex flex-column justify-content-between">
        <div class="title">
            {{ error.title | uppercase }}
        </div>
        <div class="message">
            {{ error.message | uppercase }}
        </div>
        <div class="buttons d-flex w-100 justify-content-center">
            <button (click)="error = null;" class="btn-rug button">OK</button>
        </div>
    </div>
</div>
<div *ngIf="createMachineDialog" class="dialog">
    <div class="dialog-box d-flex flex-column justify-content-between">
        <div class="title">
            NO MACHINE FOUND
        </div>
        <div class="message">
            UNABLE TO FIND MACHINE WITH SERIAL {{input.value}}.
            DO YOU WANT TO CREATE A NEW ONE?
        </div>
        <div class="buttons d-flex w-100 justify-content-between">
            <button (click)="createMachineDialog = false;" class="btn-rug button hollow">NO</button>
            <button (click)="onCreateMachine()" class="btn-rug button">YES</button>
        </div>
    </div>
</div>