import { Component, OnInit, HostListener, OnDestroy } from '@angular/core'
import { environment } from '@environment/environment'
import { IdleService } from '@shared/services/idle.service'
import { ActionCableService, Channel } from 'angular2-actioncable'
import { Subscription } from 'rxjs'
import { AuthenticationService } from './modules/authentication-module/authentication.service'
import { KioskServerService } from '@shared/services/kiosk-server.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ActionCableService]
})
export class AppComponent implements OnInit, OnDestroy {

  public offset: number
  public transform: string
  public userIdle: boolean
  public title: string
  private subscription: Subscription

  public constructor(
    private idleService: IdleService,
    private cableService: ActionCableService,
    private authService: AuthenticationService,
    private kioskServerService: KioskServerService
  ) {
    this.offset = 0
    this.userIdle = false
    this.title = 'rug-doctor-client-angular'
  }

  public ngOnInit() {
    this.scale()
    this.idleService.idle.subscribe(isIdle => {
      this.userIdle = isIdle
    })
    this.idleService.start()
    this.setupSocketConnection()
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  @HostListener('window:resize')
  public onResize() {
    this.scale()
  }

  private scale() {
    const height: number = window.innerHeight
    const width: number = window.innerWidth
    const scaleFactor: number = height / 1920
    this.transform = 'scale(' + scaleFactor + ')'
    this.offset = (width - 1080 * scaleFactor) / 2
  }

  private setupSocketConnection(): void {
    if (this.authService.kioskToken) {
      const channel: Channel = this.cableService
        .cable(environment.webSocketBaseUrl + this.authService.kioskToken)
        .channel('KioskCommandChannel')
      this.subscription = channel.received()
        .subscribe(data => {
          if (data.command === 'unlock') {
            this.kioskServerService.openBay(data.bay_id).subscribe()
          } else if (data.command === 'lock') {
            this.kioskServerService.closeBay(data.bay_id).subscribe()
          } else if (data.command === 'update') {
            this.kioskServerService.setPendingUpdate()
          }
        }, error => {
          console.error(error)
        }
        )
    }
  }
}
