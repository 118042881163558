<div [ngClass]=" { 'row-reverse': odd } " class="carousel-item">
    <div class="line title-heading">
        <h1>{{ item.name | uppercase }}</h1>
    </div>
    <div class="line subtitle">
        <h3>{{ item.subtitle | uppercase }}</h3>
    </div>
    <div class="line price-details">
        <span class="price">${{ item.price | number:'0.2-2' }}</span>
    </div>
    <div class="line">
        <div class="description">
            <ul *ngIf="item.description1 || item.description2 || item.description3">
                <li *ngIf="item.description1">{{item.description1}}</li>
                <li *ngIf="item.description2" class="description2">{{item.description2}}</li>
                <li *ngIf="item.description3">{{item.description3}}</li>
            </ul>
            <div *ngIf="item.details">
                <button (click)="openDescription()" class="btn-rug learn-more">Learn More</button>
            </div>
        </div>
    </div>

    <img [src]="item.imageUrl" alt="product" class="image"/>
</div>
