import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Deserialize, Serialize } from 'cerialize';
import { Bay } from 'src/app/domain/bay.model';
import { Kiosk } from 'src/app/domain/kiosk.model';
import { BayStatus } from 'src/app/domain/bay-status.model';
import { CartService } from '@shared/stores/cart.service';
import { KioskServerService } from './kiosk-server.service';
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service';
import { Product } from '../../domain/product.model';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    public constructor(
        private http: HttpClient,
        private cartService: CartService,
        private auth: AuthenticationService
    ) { }

    public getBays(): Observable<Bay[]> {
        const authHeader: HttpHeaders = this.auth.adminAuthHeader || this.auth.kioskAuthHeader;
        return this.http.get(environment.baseUrl + 'kiosks/' + this.auth.kioskId + '/bays/', { headers: authHeader }).pipe(
            map((bays: Bay[]) => {
                return Deserialize(bays, Bay);
            })
        );
    }

    public getProductsPerBays(): Observable<BayStatus[]> {
        const params: string = (this.auth.kioskId != null) ? '?kiosk_id=' + this.auth.kioskId : '';
        return this.http.get(environment.baseUrl + 'product_bays/' + params, { headers: this.auth.adminAuthHeader }).pipe(
            map((statuses: BayStatus[]) => {
                return Deserialize(statuses, BayStatus);
            })
        );
    }

    public createBay(bay: Bay) {
      const url = environment.baseUrl + 'kiosks/' + this.auth.kioskId + '/bays/';
      return this.http.post(url, Serialize(bay, Bay), {headers: this.auth.adminAuthHeader});
    }

  public getProduct(serialNumber: number) {
    const url = environment.baseUrl + 'products';
    let params = new HttpParams();
      params = params.append('serial_number', serialNumber.toString());
    return this.http.get<Product[]>(url,{ params, headers: this.auth.adminAuthHeader }).pipe(
      map((product: Product[]) => {
          return Deserialize(product, Product);
        }
      ));
  }

    public updateBay(bayId: number, bayData: Bay): Observable<Bay> {
        return this.http.put(
                environment.baseUrl + 'kiosks/' + this.auth.kioskId + '/bays/' + bayId,
                Serialize(bayData),
                { headers: this.auth.adminAuthHeader }
        ).pipe(
            map((bay: Bay) => {
                return Deserialize(bay, Bay);
            }
        ));
    }

    public getKioskInfo(): Observable<Kiosk> {
        return this.http.get(environment.baseUrl + 'kiosks/' + this.auth.kioskId, { headers: this.auth.adminAuthHeader }).pipe(
            map((kiosk: Kiosk) => {
                return Deserialize(kiosk, Kiosk);
            }
        ));
    }

    public abandonRentalProcess(): void {
        this.cartService.restart();
    }

    public findKioskByJDE(jdeNumber: number): Observable<Kiosk[]> {
        return this.http.get(environment.baseUrl + 'kiosks/?jde_number=' + jdeNumber).pipe(
            map((kiosk: Kiosk[]) => {
                return Deserialize(kiosk, Kiosk);
            }
        ));
    }
}
