import { Component } from '@angular/core';
import { IdleService } from '@shared/services/idle.service';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@shared/services/i18n.service';

@Component({
  selector: 'app-user-idle',
  templateUrl: './user-idle.component.html',
  styleUrls: ['./user-idle.component.scss']
})
export class UserIdleComponent {

  public get countdown(): number {
    return this.idleService.countdown;
  }

  public constructor(
    public translate: TranslateService,
    public i18n: InternationalizationService,
    private idleService: IdleService
  ) {
  }

  public restartIdle(): void {
    this.idleService.restart();
  }
}
