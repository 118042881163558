<div class="d-flex flex-column justify-content-between kiosk-diagnostic h-100">
    <div>
        <div class="w-100 title">
            <h1 class="light">Bay Diagnostic</h1>
        </div>
        <div class="details striped">
            <div class="row">
                <span class="label">Bay #</span>
                <span *ngIf="bays" class="d-flex">
                    <div (click)="onChangeBayIndex(-1)" class="btn-rug selector minus centered" [ngClass]="{ 'disabled': bayIndex < 1 }" > < </div>
                    {{ bays[bayIndex].number }}
                    <div (click)="onChangeBayIndex(1)" class="btn-rug selector plus centered" [ngClass]="{ 'disabled': bayIndex >= bays.length - 1 }" > > </div>
                </span>
                <span *ngIf="!bays"></span>
                <span class="right">
                    <button class="btn-rug" (click)="onLockBay(bays[bayIndex].number)">LOCK</button>
                </span>
            </div>
            <div class="row">
                <span class="label">Lock Status:</span>
                <span>
                    {{ (bayStatus) ? ( (bayStatus.isClosed) ? 'Closed' : 'Open' ) : 'Unknown' }}
                </span>
                <span class="right">
                    <button class="btn-rug" (click)="onUnlockBay(bays[bayIndex].number)">UNLOCK</button>
                </span>
            </div>
            <div class="row">
                <span class="label">Beam 1 Status:</span>
                <span>{{ beamStatus(0) }}</span>
                <span class="right">
                    <button class="btn-rug" (click)="onRefreshBayStatus()">REFRESH</button>
                </span>
            </div>
            <div class="row">
                <span class="label">Beam 2 Status:</span>
                <span>{{ beamStatus(1) }}</span>
                <span></span>
            </div>
            <div class="row">
                <span class="label">Beam 3 Status:</span>
                <span>{{ beamStatus(2) }}</span>
                <span></span>
            </div>
            <div class="row">
                <span class="label">Beam 4 Status:</span>
                <span>{{ beamStatus(3) }}</span>
                <span></span>
            </div>
        </div>
    </div>
    <div>
        <div class="w-100 title">
            <h1 class="light">Card Reader Diagnostics</h1>
        </div>
        <div class="details">
            <div class="row">
                <span class="label">Track 1</span>
                <span class="long">
                    <label class="value">{{ cardTracks?.tracks[0] }}</label>
                </span>
            </div>
            <div class="row">
                <span class="label">Track 2</span>
                <span class="long">
                    <label class="value">{{ cardTracks?.tracks[1] }}</label>
                </span>
            </div>
            <div class="row">
                <span class="label">Track 3</span>
                <span class="long">
                    <label class="value">{{ cardTracks?.tracks[2] }}</label>
                </span>
            </div>
            <div class="row">
                <span class="right w-100">
                    <button class="btn-rug" (click)="onRefreshCardStatus()">REFRESH</button>
                </span>
            </div>
        </div>
    </div>
    <div class="buttons d-flex w-100 justify-content-between">
        <button (click)="onUnlockAllBays()" class="btn-rug button hollow">UNLOCK DOORS</button>
        <button (click)="onLockAllBays()" class="btn-rug button">LOCK DOORS</button>
    </div>
</div>
