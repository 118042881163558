import { autoserialize, autoserializeAs } from 'cerialize'
import { Product } from "./product.model"

export enum BayType {
  Machine = 'mch',
  Attachment = 'uph'
}

export enum BayState {
  Available = 'available',
  Quarantine = 'quarantine',
  Empty = 'empty',
  Rented = 'rented',
  Replace = 'replace',
  Hold = 'hold'
}

export class Bay {
  @autoserialize id: number
  @autoserializeAs('occupancy') occupancy: number
  @autoserializeAs('product_capacity') capacity: number
  @autoserializeAs(BayType, 'bay_type') type: BayType
  @autoserializeAs(BayState, 'state') state: BayState
  @autoserializeAs('number') number: number
  @autoserializeAs(Product, 'products_in_bay') products: Product[]
  constructor() { }
  public static OnDeserialized(instance: Bay, json: any): void {
    instance.number = parseInt(json.number, 10)
  }
}
