import { map } from 'rxjs/operators'
import { Observable, zip } from 'rxjs'
import { Injectable } from '@angular/core'
import { BundleService } from '@shared/services/bundle.service'
import { ProductService } from '@shared/services/product.service'
import { KioskService } from '@shared/services/kiosk.service'
import { Bundle } from 'src/app/domain/bundle.model'
import { RentalProduct } from 'src/app/domain/rental-product'
import { Reservation } from 'src/app/domain/reservation.model'
import { ProductGroup, ProductType } from 'src/app/domain/product-type.model'
import { Kiosk } from "src/app/domain/kiosk.model"
import { Bay, BayState, BayType } from "src/app/domain/bay.model"
import { Product } from "src/app/domain/product.model"
import { KioskServerService } from "@shared/services/kiosk-server.service"
import { BeamStatus } from "src/app/domain/bay-beam-status.model"

const BAY_COUNT = 5
const BEAM_COUNT = 4
@Injectable({ providedIn: 'root' })
export class StockService {
  public machines: ProductType[]
  public attachments: ProductType[]
  public solutions: ProductType[]
  public bundles: Bundle[]
  private productTypes: ProductType[]
  private kiosk: Kiosk

  public constructor(
    private kioskService: KioskService,
    private kioskServerervice: KioskServerService,
    private bundleService: BundleService,
    private productService: ProductService,
  ) {
    // this.asyncRestock()
  }

  public restock(): Observable<void> {
    return this.asyncRestock()
  }

  public asyncRestock(): Observable<void> {
    const observables: Observable<any>[] = []
    observables.push(this.productService.getTypes())
    // observables.push(this.bundleService.getAll())
    observables.push(this.kioskService.getKioskInfo())
    return zip(...observables).pipe(map((data: any[]) => {
      let machines = []
      let solutions = []
      this.productTypes = data[0]
      this.kiosk = data[1]
      this.attachments = []
      // this.machines = this.productTypes.filter(entry => entry.productGroup === ProductGroup.Machines)
      // this.attachments = []
      // this.solutions = this.productTypes.filter(entry => entry.productGroup === ProductGroup.Solutions)
      // this.bundles = data[1].filter((bundle: Bundle) => bundle.name !== null)
      if(data &&  Array.isArray(data[0])){
        data[0].forEach((item) => {
          if (item.productGroup === "machine" && item.quantity_available >= 1){
              machines.push(item)
          }
          else if (item.productGroup === "solution" && this.kiosk !== undefined && this.kiosk.chemicals_sell){
              solutions.push(item)
          }
        })
      }
      machines.forEach((machine: ProductType) => {
        this.productService.getRelatedProducts(machine.id).subscribe(productType => {
          machine.compatibleWith = productType

          if (machine.compatibleWith && machine.compatibleWith.length > 0) {
            this.attachments = [...this.attachments, ...machine.compatibleWith]
          }
        })
      })

      this.machines = [...machines]
      this.solutions = [...solutions]

      if (this.kioskServerervice.kioskDevMode) {
        return
      }

      const bayObservables: Observable<any>[] = []
      bayObservables.push(this.kioskServerervice.statusAll(BAY_COUNT, BEAM_COUNT))

      zip(...bayObservables).subscribe((data: any[]) => {
        let statuses = data[0]
        this.kiosk.bays.forEach((bay: Bay) => {
          if (bay.state == BayState.Available) {
            bay.products.forEach((product: Product) => {
              this.productTypes.forEach((machine: ProductType) => {
                if (product.type?.id == machine.id) {
                  if (typeof statuses[`bay_${bay.number}`] != 'undefined') {
                    let status = statuses[`bay_${bay.number}`]
                    for (let beam in status.beam_status) {
                      if (status.beam_status[beam] == BeamStatus.Filled) {
                        machine.actual_quantity++
                      }
                    }
                  }
                }
              })
            })
          }
        })
      })
    }))
  }

  public assessCompatibility(machines: ProductType[]): void {
    this.attachments = []
    machines.forEach((machine: ProductType) => {
      if (machine.compatibleWith && machine.compatibleWith.length > 0) {
        this.attachments = [...this.attachments, ...machine.compatibleWith]
      }
    })
  }

  public find(productTypeId: number): ProductType {
    const allProductTypes = this.productTypes
    const filtered: ProductType[] = allProductTypes?.filter(entry => entry.id === productTypeId)
    return (filtered && filtered.length > 0) ? filtered[0] : null
  }

  public quantity(productTypeId: number): number {
    return this.find(productTypeId)?.actual_quantity || 0
  }

  public available(productTypeId: number): boolean {
    return this.quantity(productTypeId) > 0 || this.find(productTypeId)?.productGroup === ProductGroup.Solutions
  }

  public changeQuantity(productTypeId: number, quantityDelta: number): void {
    const productType = this.find(productTypeId)
    if (productType && productType.productGroup === ProductGroup.Solutions) {
      return
    }
    if (productType) {
      productType.actual_quantity += quantityDelta
      if (productType.actual_quantity < 0) {
        productType.actual_quantity = 0
      }
    }
  }

  // Used to set exsiting productType instance for type, as it has price and quantity data
  public prepareReservation(reservation: Reservation): void {
    reservation.products.forEach((entry: RentalProduct) => {
      entry.type = this.find(entry.type.id)
    })
  }
}
