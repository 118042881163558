import { Component, OnInit } from '@angular/core'
import { CardTracks } from 'src/app/domain/card-tracks.model'
import { Bay } from 'src/app/domain/bay.model'
import { BayBeamStatus, BeamStatus } from 'src/app/domain/bay-beam-status.model'
import { AdminService } from '@shared/services/admin.service'
import { KioskServerService } from '@shared/services/kiosk-server.service'

@Component({
  selector: 'app-kiosk-diagnostic',
  templateUrl: './kiosk-diagnostic.component.html'
})
export class KioskDiagnosticComponent implements OnInit {

  public bayIndex: number
  public bayStatus: BayBeamStatus
  public cardTracks: CardTracks
  public bays: Bay[]

  public constructor(
    private adminService: AdminService,
    private kioskServerService: KioskServerService
  ) {
    this.bayIndex = 0
  }

  public ngOnInit(): void {
    this.updateDiagnosticData()
  }

  private updateDiagnosticData(): void {
    this.adminService.getBays().subscribe(bays => {
      bays.sort((a, b) => a.number - b.number)
      this.bays = bays
      this.onRefreshBayStatus()
    })
    this.onRefreshCardStatus()
  }

  public onRefreshBayStatus(): void {
    this.kioskServerService.initialBayStatus(this.bays[this.bayIndex].number, this.bays[this.bayIndex].capacity).subscribe(status => {
      this.bayStatus = status
    })
  }

  public onRefreshCardStatus(): void {
    this.kioskServerService.readTracks().subscribe(tracks => {
      this.cardTracks = tracks
    })
  }

  public onLockBay(bayNumber: number): void {
    this.kioskServerService.closeBay(bayNumber).subscribe(() => {
      this.onRefreshBayStatus()
    }, error => console.error(error))
  }

  public onLockAllBays(): void {
    this.kioskServerService.closeAll(this.bays.length).subscribe(() => {
      this.onRefreshBayStatus()
    }, error => console.error(error))
  }

  public onUnlockBay(bayNumber: number): void {
    this.kioskServerService.openBay(bayNumber).subscribe(() => {
      this.onRefreshBayStatus()
    }, error => console.error(error))
  }

  public onUnlockAllBays(): void {
    this.kioskServerService.openAll(this.bays.length).subscribe(() => {
      this.onRefreshBayStatus()
    }, error => console.error(error))
  }

  public onChangeBayIndex(delta: number): void {
    if (delta === -1 && this.bayIndex === 0 ||
      delta === 1 && this.bayIndex >= this.bays.length - 1) {
      return
    }
    this.bayIndex += delta
    this.onRefreshBayStatus()
  }

  public beamStatus(index: number): string {
    if (!this.bayStatus) {
      return 'Unknown'
    }
    if (!this.bayStatus.beamStatus[index]) {
      return '/'
    }
    return (this.bayStatus.beamStatus[index] === BeamStatus.Empty) ? 'Empty' : 'Blocked'
  }
}
