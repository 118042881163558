import { Component, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { RoutingService } from '@services'
import { KioskServerService } from '@shared/services/kiosk-server.service'
import { CartService } from '@shared/stores/cart.service'
import { Card } from 'src/app/domain/card.model'
import { RentalService } from '@shared/services/rental.service'
import { BaysService } from '@shared/stores/bays.service'
import { BayTransactionType } from 'src/app/domain/bay-transaction.model'
import { KeyboardLayout } from 'src/app/components/keyboard/keyboard.component'
import { InputStep } from 'src/app/domain/steps/input-step.model'
import { UtilService } from '@shared/services/util.service'
import { Reservation } from 'src/app/domain/reservation.model'
import { CardTracks } from '../../domain/card-tracks.model'


const HOME = 'home'
const FINISH = 'finish'
const CARD_VALUE_KEY = 'creditCard'
const NAME_VALUE_KEY = 'renterName'
const VERIFICATION_VALUE_KEY = 'verification'
const ERROR = 'error'
const STEPS: { [id: string]: InputStep } = {
  [CARD_VALUE_KEY]: {
    title: 'PAYMENT.TITLES.CARD',
    next: 'renterZip',
    input: false,
    back: HOME
  },
  renterZip: {
    title: 'PAYMENT.TITLES.ZIP',
    next: NAME_VALUE_KEY,
    back: CARD_VALUE_KEY,
    input: true,
    placeholder: 'PAYMENT.PLACEHOLDERS.ZIP',
    validation: [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern(/^\d+$/)],
    layout: KeyboardLayout.Numeric
  },
  [NAME_VALUE_KEY]: {
    title: 'PAYMENT.TITLES.NAME',
    next: 'renterPhone',
    back: 'renterZip',
    input: true,
    placeholder: 'PAYMENT.PLACEHOLDERS.NAME',
    validation: [Validators.required]
  },
  renterPhone: {
    title: 'PAYMENT.TITLES.PHONE',
    inputNote: 'PAYMENT.MESSAGES.PHONE',
    next: 'renterEmail',
    back: NAME_VALUE_KEY,
    input: true,
    placeholder: 'PAYMENT.PLACEHOLDERS.PHONE',
    validation: [Validators.required, Validators.pattern(/^\d+$/), Validators.minLength(7), Validators.maxLength(10)],
    layout: KeyboardLayout.Numeric
  },
  [VERIFICATION_VALUE_KEY]: {
    title: 'PAYMENT.TITLES.VERIFICATION',
    titleLong: true,
    next: 'renterEmail',
    back: 'renterPhone',
    input: true,
    placeholder: 'PAYMENT.PLACEHOLDERS.VERIFICATION',
    validation: [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern(/^[a-z0-9]+$/i)]
  },
  renterEmail: {
    title: 'PAYMENT.TITLES.EMAIL',
    inputNote: 'PAYMENT.MESSAGES.EMAIL',
    next: FINISH,
    back: 'renterPhone',
    input: true,
    placeholder: 'PAYMENT.PLACEHOLDERS.EMAIL',
    validation: [Validators.required, Validators.email],
    layout: KeyboardLayout.Email
  },
  error: {
    title: 'PAYMENT.TITLES.ERROR',
    message: true,
    input: false,
    next: 'renterEmail',
    nextText: 'GLOBAL.CONTINUE',
    back: 'renterEmail'
  }
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  public submited: boolean
  public exitDialog: boolean
  public currentStep: string
  public errorMessage: string
  public loading: boolean
  public input: FormControl
  public get step(): any { return STEPS[this.currentStep] }
  public constructor(
    private route: ActivatedRoute,
    private utilService: UtilService,
    private cartService: CartService,
    private kioskServerService: KioskServerService,
    public routingService: RoutingService
  ) {
    this.submited = false
    this.errorMessage = 'Something went wrong'
    this.currentStep = CARD_VALUE_KEY
    this.input = new FormControl('', [Validators.required])
  }

  public ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.currentStep = params.step
      this.resetInput()
    })
  }

  public onStepChange(step?: string): void {
    console.log(step)
    if (!step) {
      step = this.step.next
    }
    if (step === HOME) {
      this.routingService.navigateToHome()
      return
    }
    if (this.submited) {
      return
    }
    if (!this.step.validation || this.input.valid) {
      // Save value or check verification
      if (VERIFICATION_VALUE_KEY === this.currentStep) {
        // Verification
      } else if (this.step.input) {
        this.cartService.rental[this.currentStep] = this.input.value
      }
      if (step === FINISH) {
        this.onComplete()
      } else if (this.currentStep === CARD_VALUE_KEY) {
        this.onCardRead()
      } else {
        this.routingService.navigateToPayment(step)
        this.resetInput()
      }
    }
  }

  private resetInput(): void {
    if (!this.step.input) {
      return
    }
    this.input = new FormControl(
      (this.currentStep === NAME_VALUE_KEY) ? (this.cartService.rental.renterName || '') : '',
      this.step.validation
    )
  }

  private onComplete(): void {
    this.submited = true
    this.cartService.rental.id = this.cartService.rentalId
    this.cartService.rental.startDate = new Date()
    this.cartService.complete()
    this.cartService.paymentFailed.subscribe(next => {
      this.errorMessage = next
      this.submited = false
      this.routingService.navigateToPayment(ERROR)
    },
      error => {
        console.log(error)
      })
  }

  private onCardRead(): void {
    this.loading = true
    this.kioskServerService.readTracks().subscribe(result => {
      this.onCardReadComplete(result)
    }, error => {
      console.log('readTracks errored', error)
      this.loading = false
      this.errorMessage = error
      this.routingService.navigateToPayment(ERROR)
    })
  }

  public onCardReadComplete(cardTracks: CardTracks): void {
    // using read card tracks instead of read card
    // this.cartService.rental.creditCard = card
    // this.cartService.rental.renterName = this.utilService.formatName(card.firstName, card.lastName)
    this.cartService.rental.track1 = cardTracks.tracks[0]
    this.cartService.rental.track2 = cardTracks.tracks[1]
    this.cartService.rental.track3 = cardTracks.tracks[2]
    this.parseFirstAndLastName(cardTracks.tracks[0])
    this.loading = false
    this.routingService.navigateToPayment(this.step.next)
  }

  public parseFirstAndLastName(cardTrack1: string): void {
    if (cardTrack1) {
      const namesSeparated = cardTrack1.split('^')[1]
      if (namesSeparated) {
        const names = namesSeparated.split('/')
        if (names[0] && names[1]) {
          const lName = names[0].split(' ')
          const fName = names[1].split(' ')
          let lastName = ''
          if (fName.length > 0 && lName.length > 0) {
            lName.forEach(item => {
              if (item !== '') {
                lastName += item
                lastName += ' '
              }
            })
            lastName = lastName.slice(0, -1)


            let firstName = ''
            fName.forEach(item => {
              if (item !== '') {
                firstName += item
                firstName += ' '
              }
            })
            firstName = firstName.slice(0, -1)
            this.cartService.rental.renterName = this.utilService.formatName(firstName, lastName)
          }
        }
      }
    }
  }

  public onBack(): void {
    if (this.step.back === HOME) {
      this.exitDialog = true
    } else {
      this.routingService.navigateToPayment(this.step.back)
      this.resetInput()
    }
  }

  public onToggleExitDialog(visible: boolean): void {
    this.exitDialog = visible
  }
}
