import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@shared/services';
import { CartService } from '@shared/stores/cart.service';
import { RentalService } from '@shared/services/rental.service';
import { Reservation } from 'src/app/domain/reservation.model';
import { StockService } from '@shared/stores/stock.service';

const SUMMARY_STEP = 'summary';

@Component({
    selector: 'app-reservation',
    templateUrl: './reservation.component.html',
    styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {

    public input: FormControl;
    public message: string;
    public kioskJde: string;

    public constructor(
        private cartService: CartService,
        private stockService: StockService,
        private rentalService: RentalService,
        private routingService: RoutingService
    ) { }

    public ngOnInit(): void {
      this.kioskJde = localStorage.getItem('kiosk_jde');
        this.input = new FormControl('', [Validators.required]);
    }

    public onFindReservation(): void {
      this.rentalService.find({ is_reservation: true, name: this.input.value.toUpperCase(), state: 'pending' })
        .subscribe((reservations: Reservation[]) => {
            if (reservations.length > 0) {
                this.stockService.asyncRestock().subscribe(() => {
                    this.cartService.applyExternalReservation(reservations[0]);
                    this.routingService.navigateToRent(SUMMARY_STEP);
                });
            } else {
                this.message = 'RESERVATIONS.MESSAGE';
            }
        });
    }
}
