<div class="new-bay d-flex flex-column justify-content-between">
  <div>
    <div class="header d-flex justify-content-between align-items-center">
      <h1>New Bay</h1>
    </div>
    <div class="form d-flex flex-column">
      <label>BAY NUMBER</label>
      <div class="d-flex justify-content-between input-box serial-input" (click)="onToggleNumberKeyboard(true)">
        <span class="input-rug"> {{ bayNumberInput.value }} </span>
        <button *ngIf="keyboardNumbersVisible" class="btn-rug" (click)="onToggleNumberKeyboard(false, $event)">DONE</button>
      </div>
      <label>BAY TYPE</label>
      <div class="d-flex justify-content-between input-box checkboxes">
                <span (click)="onChangeType('mch')" class="input-rug">
                    <span>Rentable</span>
                    <img *ngIf="bay.type == 'mch'" src="/assets/img/checked.svg" alt="checked"/>
                    <img *ngIf="bay.type != 'mch'" src="/assets/img/unchecked.svg" alt="unchecked"/>
                </span>
        <span (click)="onChangeType('uph')" class="input-rug">
                    <span>Attachment</span>
                    <img *ngIf="bay.type == 'uph'" src="/assets/img/checked.svg" alt="checked"/>
                    <img *ngIf="bay.type != 'uph'" src="/assets/img/unchecked.svg" alt="unchecked" />
                </span>
      </div>
      <div *ngIf="bay.type == 'uph'">
        <label>NUMBER OF BEAMS</label>
        <app-dropdown (selected)="onChangeCapacity($event)" [options]="noBeamsOptions" [value]="bay.capacity" ></app-dropdown>
      </div>
    </div>
    <div *ngIf="bay.type == 'mch'" class="form dimmed d-flex flex-column">
      <label>SERIAL NUMBER</label>
      <div class="d-flex justify-content-between input-box serial-input" (click)="onToggleSerialKeyboard(true)">
        <span class="input-rug"> {{ serialNumberInput.value }} </span>
        <button *ngIf="keyboardVisible" class="btn-rug" (click)="onToggleSerialKeyboard(false, $event)">DONE</button>
      </div>
      <label>ITEM NAME</label>
      <div (click)="onSelectProduct()" class="d-flex justify-content-between input-box">
        <span class="input-rug"> {{ (referenceMachineType) ? referenceMachineType.name : 'Empty' }} </span>
      </div>
      <p class="create-new-machine" *ngIf="!existingMachine">Rentable with serial number: {{serialNumberInput.value}} does not exist, select Item Name to create a new rentable</p>
    </div>
    <app-attachment-list
      *ngIf="bay.type == 'uph'"
      [maximum]="bay.capacity"
      [existing]=""
      (changed)="onUpdateSelectedAttachments($event)">
    </app-attachment-list>
    <div *ngIf="keyboardVisible" class="keyboard">
      <app-keyboard [value]="serialNumberInput.value" [formControl]="serialNumberInput" [disableTempCaps]="true"></app-keyboard>
    </div>
    <div *ngIf="keyboardNumbersVisible" class="keyboard">
      <app-keyboard [value]="bayNumberInput.value" [formControl]="bayNumberInput" [disableTempCaps]="true" [layout]="'numeric'"></app-keyboard>
    </div>
  </div>
  <div class="spinner-position" *ngIf="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="!keyboardVisible && !keyboardNumbersVisible" class="buttons d-flex w-100 justify-content-between">
    <button (click)="goBack()" class="btn-rug button hollow">CANCEL</button>
    <button (click)="onSave()" class="btn-rug button" [ngClass]="{ 'disabled': !isSaveEnabled() }">ADD BAY</button>
  </div>
  <app-modal-selector *ngIf="itemSelection" [options]="itemSelection" (selected)="onProductSelected($event)" (closed)="onCloseSelector()"></app-modal-selector>
</div>
<div *ngIf="error" class="dialog">
  <div class="dialog-box d-flex flex-column justify-content-between">
    <div class="title">
      {{ error.title | uppercase }}
    </div>
    <div class="message">
      {{ error.message | uppercase }}
    </div>
    <div class="buttons d-flex w-100 justify-content-center">
      <button (click)="error = null;" class="btn-rug button">OK</button>
    </div>
  </div>
</div>
<!--<div *ngIf="createMachineDialog" class="dialog">-->
<!--  <div class="dialog-box d-flex flex-column justify-content-between">-->
<!--    <div class="title">-->
<!--      NO MACHINE FOUND-->
<!--    </div>-->
<!--    <div class="message">-->
<!--      UNABLE TO FIND MACHINE WITH SERIAL {{serialNumberInput.value}}.-->
<!--      DO YOU WANT TO CREATE A NEW ONE?-->
<!--    </div>-->
<!--    <div class="buttons d-flex w-100 justify-content-between">-->
<!--      <button (click)="createMachineDialog = false;" class="btn-rug button hollow">NO</button>-->
<!--      <button (click)="onCreateMachine()" class="btn-rug button">YES</button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
