<app-header (back)="onBack()" [canNavigateBack]="true" [customBackNavigation]="true" [redBorder]="true" [showLanguage]="true"></app-header>
<div class="page d-flex flex-column justify-content-between">
    <div class="summary">
        <h1>{{ 'RENT.SUMMARY.TITLE' | translate | uppercase }}</h1>
        <div class="summary-content">
            <div *ngIf="cart" class="cart scrollable large">
                <div *ngFor="let product of cart; let i = index" class="cart-item d-flex justify-content-between">
                    <img *ngIf="product !== undefined && product !== null && product.type !== undefined && product.type !== null && product.type.imageUrl !== undefined && product.type.imageUrl !== null" [src]="product.type.imageUrl" alt="product" class="image"/>
                    <div class="info d-flex flex-column justify-content-between">
                        <span class="type">{{ (product.quantity > 1) ? product.quantity + ' x' : '' }}
                            {{ product.type.productGroup | uppercase }}</span>
                        <label class="name">{{ product.type.name | uppercase }}
                        </label>
                        <div class="d-flex flex-column">
                            <label class="price">${{ product.price }}</label>
                        </div>
                    </div>
                    <span [ngClass]="{ 'hidden': product.reserved }" class="remove">
                        <img (click)="onRemove(i)" *ngIf="!product.reserved" alt="close" class="clickable remove-item" src="assets/img/close.svg"/>
                    </span>
                </div>
                <div *ngFor="let coupon of coupons; let i = index" class="cart-coupon d-flex justify-content-between align-items-center">
                    <span class="label strong name">{{ coupon.name | uppercase }}</span>
                    <span class="amount">-
                        {{ coupon.discount }}</span>
                </div>
            </div>
            <label (click)="onAddMore()" class="label red offset w-100" id="addMore">{{ 'RENT.SUMMARY.ADD_MORE' | translate | uppercase }}</label>
            <hr/>
            <div (click)="onAddCoupon()" class="coupon-add w-100 d-flex align-items-center justify-content-between" id="addCoupon">
                <label *ngIf="!activeCoupon" class="label red">{{ 'RENT.SUMMARY.ADD_COUPON' | translate | uppercase }}</label>
                <label *ngIf="!activeCoupon" class="amount hidden">* * * * *</label>

                <label *ngIf="activeCoupon" class="label red">{{ 'RENT.SUMMARY.CHANGE_COUPON' | translate | uppercase }}</label>
                <label *ngIf="activeCoupon" class="amount">
                    {{ activeCoupon.code }}</label>
            </div>
            <hr/>
            <app-summary-price [paymentDetails]="paymentDetails" class="d-flex flex-column align-items-center"></app-summary-price>

            <div class="d-flex justify-content-end">
                <button (click)="onNext()" [ngClass]="{ 'disabled': cartEmpty }" class="btn-rug payment" id="complete">
                    {{ 'RENT.SUMMARY.PAY_NOW' | translate | uppercase }}
                </button>
            </div>
        </div>

    </div>
    <img alt="path" class="decoration-path" src="assets/img/path.svg"/>
    <img alt="bays" class="decoration-background" src="assets/img/bays-background.jpg"/>

    <div class="contact d-flex justify-content-between">
        <!--     TODO (877) 343-0588 is correct but admin should be able to change it-->
                                                              <!--     TODO 8774704111 = mrent is correct but admin should be able to change it-->
        <p>For assistance call: (877) 343-0588</p>
        <p>Kiosk JDE:
            {{kioskJde ? kioskJde : '/'}}</p>
    </div>
</div>
