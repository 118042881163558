import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PresentationItem } from '../../domain/presentation-item.model';
import { ProductType } from '../../domain/product-type.model';
import { Bundle } from '../../domain/bundle.model';

const TEXT_ELEMENT_NAME = 'text';
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  @Input() presentationItem: PresentationItem<ProductType | Bundle>;
  @Input() visible: boolean;
  @Output() hide: EventEmitter<void>;
  @ViewChild(TEXT_ELEMENT_NAME) textElement: any;
  public hitTop: boolean;
  public hitBottom: boolean;
  public offset: number;
  public constructor() {
    this.hide = new EventEmitter<void>();
    this.hitTop = true;
    this.hitBottom = false;
  }

  ngOnInit(): void {
  }

  public onHide(): void {
    if (this.visible) {
      this.hide.emit();
    }
  }

  public onTextDrag(event: any): void {
    if (event.buttons !== 0) {
      if (this.offset) {
        this.textElement.nativeElement.scrollTop += (this.offset - event.offsetY);
      }
      this.offset = event.offsetY;
      this.onUpdateScrollBounds();
    } else {
      this.offset = null;
    }
  }

  public onUpdateScrollBounds(): void {
    this.hitTop = this.textElement.nativeElement.scrollTop === 0;
    this.hitBottom = this.textElement.nativeElement.scrollTop ===
      this.textElement.nativeElement.scrollHeight - this.textElement.nativeElement.clientHeight;
  }

}
