<div class="d-flex flex-column justify-content-between kiosk-configuration h-100">
    <div *ngIf="kioskInfo">
        <div class="w-100 title">
            <h1 class="light">Kiosk Configuration</h1>
        </div>
        <div class="details striped">
            <div class="row">
                <span class="label">Last Service:</span>
                <span>{{ kioskInfo.lastService }}</span>
                <span class="right">
                    <button (click)="onRefreshToken()" class="btn-rug">{{ refreshButtonText }}</button>
                </span>
            </div>
            <div class="row">
                <span class="label">JDE Cust #</span>
                <span>{{ kioskInfo.jdeNumber }}</span>
                <span class="right">
                    <button (click)="onUpdateKioskJde()" class="btn-rug">UPDATE JDE#</button>
                </span>
            </div>
            <div class="row">
                <span class="label">Store Name:</span>
                <span class="long">{{ kioskInfo.venue.name }}</span>
            </div>
            <div class="row">
                <span class="label">Address 1:</span>
                <span class="long">{{ kioskInfo.venue.address1 }}</span>
            </div>
            <div class="row">
                <span class="label">Address 2:</span>
                <span class="long">{{ kioskInfo.venue.address2 }}</span>
            </div>
            <div class="row">
                <span class="label">City, State, ZIP:</span>
                <span class="long">{{ kioskInfo.venue.city }}, {{ kioskInfo.venue.state }}, {{ kioskInfo.venue.zip }}</span>
            </div>
            <div class="row">
                <span class="label">Environment:</span>
                <span>{{ environmentName }}</span>
                <span class="right">
                    <button (click)="onUpdateKioskEnvironment()" class="btn-rug">UPDATE ENV</button>
                </span>
            </div>
            <div class="row">
                <span class="label">App Version:</span>
                <span class="long">{{ appVersion }}</span>
            </div>
        </div>
        <div *ngIf="settings" class="w-100 title settings">
            <h1 class="light">Local Settings</h1>
        </div>
        <div *ngIf="settings" class="details striped">
            <div class="row">
                <span class="label">Beam Polling:</span>
                <span>{{ pollBeamsStatus }}</span>
                <span class="right">
                    <button (click)="onTogglePollBeams()" class="btn-rug">{{ togglePollBeamsButtonText }}</button>
                </span>
            </div>
        </div>
    </div>
    <button *ngIf="kioskInfo" class="btn-rug confirm-button short" (click)="shutDownDialog = true;">SHUT DOWN</button>
</div>
<div *ngIf="shutDownDialog" class="dialog">
    <div class="dialog-box d-flex flex-column justify-content-between">
        <div class="title">
            ARE YOU SURE YOU WANT TO SHUTDOWN THE APPLICATION?
        </div>
        <div class="buttons d-flex w-100 justify-content-between">
            <button (click)="shutDownDialog = false;" class="btn-rug button hollow">NO</button>
            <button (click)="onShutDown()" class="btn-rug button">YES</button>
        </div>
    </div>
</div>
