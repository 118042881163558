import { Component, OnInit } from '@angular/core'
import { UtilService } from '@shared/services/util.service'

@Component({
  selector: 'app-updating',
  templateUrl: './updating.component.html',
  styleUrls: ['./updating.component.scss']
})
export class UpdatingComponent implements OnInit {
  constructor(
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.utilService.updateKiosk().subscribe(
      next => {
        // Do Nothing
      },
      error => {
        console.log(error)
      }
    )
  }

}
