import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { InfoService, RoutingService } from '@services';
import { AuthenticationService } from './authentication.service';
import { AdminService } from '@shared/services/admin.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private infoService: InfoService,
        private adminService: AdminService,
        private routingService: RoutingService,
        private authenticationService: AuthenticationService
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 502) {
                this.routingService.navigateToDisconnected();
            }
            else if (err.status >= 500) {
                this.adminService.abandonRentalProcess();
                this.infoService.errorListener.next(true);
            }
            if (err.status === 401 || err.status === 403) {
                this.adminService.abandonRentalProcess();
                if (this.authenticationService.kioskTestToken) {
                    this.authenticationService.logout();
                    this.routingService.navigateToSplashScreen();
                } else {
                    this.authenticationService.logout();
                    this.routingService.navigateToLogin();
                }
            }
            const error = err.error ? err.error.message : err.statusText;
            return throwError(error);
        }));
    }
}
