import { Injectable } from '@angular/core'
import { BayTransaction, BayTransactionType } from 'src/app/domain/bay-transaction.model'
import { Reservation } from 'src/app/domain/reservation.model'
import { BayTransactionAction, BayTransactionActionType } from 'src/app/domain/bay-transaction-action.model'
import { RentalProduct } from 'src/app/domain/rental-product'

@Injectable({ providedIn: 'root' })
export class BaysService {

  private currentAction: number
  public actions: BayTransactionAction[]
  private bayTransaction: BayTransaction
  public get isComplete(): boolean { return this.actions.length == 0 }
  public get noPickables(): boolean { return (this.bayTransaction) ? this.bayTransaction.bayItems.length === 0 : false }
  public get transaction() { return this.bayTransaction }
  public get action(): BayTransactionAction {
    if (!this.actions || this.currentAction == null) {
      return null
    }
    return this.actions[this.currentAction]
  }
  public get expectedDiff(): number {
    return this.action.expectedBeamDiff
  }

  constructor() { }

  public createTransaction(type: BayTransactionType, reservation: Reservation): void {
    this.actions = []
    this.currentAction = 0
    this.bayTransaction = new BayTransaction(reservation, type)
    this.bayTransaction.bayItems.forEach((pickable: RentalProduct) => {
      this.actions.push(
        new BayTransactionAction(
          (this.bayTransaction.type === BayTransactionType.Rent)
            ? BayTransactionActionType.PickFromBay
            : BayTransactionActionType.ReturnToBay,
          [pickable])
      )
    })
    if (this.bayTransaction.type === BayTransactionType.Rent &&
      this.bayTransaction.shelfItems.length > 0) {
      this.actions.push(
        new BayTransactionAction(
          BayTransactionActionType.PickUpShelf,
          this.bayTransaction.shelfItems)
      )
    }
    if (this.actions.length === 0) {
      this.currentAction = null
    }
  }

  public completeAction(currentAction: number): void {
    this.actions[currentAction] = null
  }

  public completeActions(): void {
    this.actions = []
  }
}
