import { autoserializeAs } from 'cerialize'
import { Coupon } from './coupon.model'

export class PaymentDetails {

  @autoserializeAs('total_amount') totalAmount: number
  @autoserializeAs('total_tax_amount') taxAmount: number
  @autoserializeAs(Coupon, 'coupon_amounts') couponAmounts: Coupon[]
  public get totalCouponAmount(): number {
    return (this.couponAmounts ? this.couponAmounts.reduce((total: number, coupon: Coupon) => total + coupon.amount, 0) : 0)
  }

  public constructor(
    totalAmount?: number,
    taxAmount?: number
  ) {
    this.totalAmount = totalAmount
    this.taxAmount = taxAmount
  }
}
