<div class="list-item d-flex flex-column justify-content-between">
    <span class="type">{{ (item.quantity > 1) ? item.quantity + ' x' : '' }} {{ item.group | uppercase }}</span>
    <label class="name" [ngClass]="{ 'slimmer': slimmer }">
        {{ item.name | uppercase }}
        <br />
        {{ item.subtitle | uppercase }}
    </label>
    <div class="d-flex flex-column">
        <label *ngIf="item.price" class="price">${{ item.price | number:'0.2-2' }}</label>
        <img class="image" [src]="item.imageUrl" alt="item"/>
    </div>
    <img *ngIf="removable" (click)="onRemove()" class="remove clickable" src="assets/img/close.svg" alt="close"/>
    <div *ngIf="plus" class="plus">+</div>
</div>
