import * as faker from 'faker'
import { Bay, BayState, BayType } from '../app/domain/bay.model'
import { BayStatus } from '../app/domain/bay-status.model'
import { Kiosk } from '../app/domain/kiosk.model'
import { Venue } from '../app/domain/venue.model'
import { Bundle } from '../app/domain/bundle.model'
import { Coupon, CouponState, CouponType, CouponFactor } from '../app/domain/coupon.model'
import { ProductGroup, ProductType, ProductEnvironment } from '../app/domain/product-type.model'
import { Price } from '../app/domain/price.model'
import { Product } from '../app/domain/product.model'
import { BayProductEntry } from '../app/domain/bay-product-entry.model'
import { BayBeamStatus, BayDoorStatus, BeamStatus } from '../app/domain/bay-beam-status.model'
import { CardTracks } from '../app/domain/card-tracks.model'
import { RentalProduct } from '../app/domain/rental-product'
import { Reservation } from '../app/domain/reservation.model'
import { Card } from '../app/domain/card.model'
import { CartCoupon } from '../app/domain/cart-coupon.model'
import { BayTransactionAction, BayTransactionActionType } from '../app/domain/bay-transaction-action.model'
import { BayTransaction, BayTransactionType } from '../app/domain/bay-transaction.model'
import { Rental } from '../app/domain/rental.model'
import { PaymentDetails } from 'src/app/domain/payment-details.model'

// Bays
export const getDummyBayData = () => {
  const deserializedObject = new Bay()
  const plainObject = {
    id: faker.random.number(),
    product_capacity: faker.random.number(),
    bay_type: BayType.Attachment,
    state: BayState.Available,
    number: faker.random.number()
  }
  deserializedObject.id = plainObject.id
  deserializedObject.capacity = plainObject.product_capacity
  deserializedObject.type = plainObject.bay_type
  deserializedObject.state = plainObject.state
  deserializedObject.number = plainObject.number
  return { plainObject, deserializedObject }
}

export const getDummyBayArrayData = (count = faker.random.number({ min: 1, max: 20 })) => {
  const plainObject = []
  const deserializedObject = []
  for (let i = 0; i < count; i++) {
    const bay = getDummyBayData()

    plainObject.push(bay.plainObject)
    deserializedObject.push(bay.deserializedObject)
  }
  return { plainObject, deserializedObject }
}

// Venues
export const getDummyVenueData = () => {
  const deserializedObject = new Venue()
  const plainObject = {
    id: faker.random.number(),
    name: faker.random.word(),
    address1: faker.random.word(),
    address2: faker.random.word(),
    city: faker.random.word(),
    state: faker.random.word(),
    zip: faker.random.word(),
  }
  deserializedObject.id = plainObject.id
  deserializedObject.name = plainObject.name
  deserializedObject.address1 = plainObject.address1
  deserializedObject.address2 = plainObject.address2
  deserializedObject.city = plainObject.city
  deserializedObject.state = plainObject.state
  deserializedObject.zip = plainObject.zip

  return { plainObject, deserializedObject }
}

// Kiosk
export const getDummyKioskData = () => {
  const deserializedObject = new Kiosk()
  const venue = getDummyVenueData()
  const plainObject = {
    id: faker.random.number(),
    jde_number: faker.random.number(),
    last_service_date: faker.random.word(),
    venue: venue.plainObject
  }


  deserializedObject.id = plainObject.id
  deserializedObject.jdeNumber = plainObject.jde_number
  deserializedObject.lastService = plainObject.last_service_date
  deserializedObject.venue = venue.deserializedObject

  return { plainObject, deserializedObject }
}

// BayStatus

export const getDummyBayStatusData = () => {
  const deserializedObject = new BayStatus()
  const bay = getDummyBayData()
  const plainObject = {
    id: faker.random.number(),
    bay: bay.plainObject
  }

  deserializedObject.id = plainObject.id
  deserializedObject.bay = bay.deserializedObject

  return { plainObject, deserializedObject }
}

export const getDummyBayStatusDataWithProductEntry = () => {
  const deserializedObject = new BayStatus()
  const bay = getDummyBayData()
  const productEntry = getDummyBayProductEntryData()
  const plainObject = {
    id: faker.random.number(),
    bay: bay.plainObject,
    product: productEntry.plainObject
  }

  deserializedObject.id = plainObject.id
  deserializedObject.bay = bay.deserializedObject
  deserializedObject.products = [productEntry.deserializedObject]

  return { plainObject, deserializedObject }
}

export const getDummyBayStatusArrayData = (count = faker.random.number({ min: 1, max: 20 })) => {
  const plainObject = []
  const deserializedObject = []
  for (let i = 0; i < count; i++) {
    const bayStatus = getDummyBayStatusData()
    plainObject.push(bayStatus.plainObject)
    deserializedObject.push(bayStatus.deserializedObject)
  }

  return { plainObject, deserializedObject }
}

// BayProductEntry

export const getDummyBayProductEntryData = () => {
  const deserializedObject = new BayProductEntry()
  const product = getDummyProductData()
  const quantity = faker.random.number({ min: 1 })
  const quantityRented = faker.random.number()
  const plainObject = {
    quantity,
    quantity_loaded: quantity + quantityRented,
    quantity_rented: quantityRented,
    product: product.plainObject
  }

  deserializedObject.quantity = plainObject.quantity
  deserializedObject.quantityLoaded = plainObject.quantity_loaded
  deserializedObject.quantityRented = plainObject.quantity_rented
  deserializedObject.product = product.deserializedObject

  return { plainObject, deserializedObject }
}

export const getDummyBayProductEntryArrayData = (count = faker.random.number({ min: 1, max: 20 })) => {
  const plainObject = []
  const deserializedObject = []
  for (let i = 0; i < count; i++) {
    const bayProductEntry = getDummyBayProductEntryData()
    plainObject.push(bayProductEntry.plainObject)
    deserializedObject.push(bayProductEntry.deserializedObject)
  }

  return { plainObject, deserializedObject }
}

export const getFakeProductTypeArrayData = (count = faker.random.number({ min: 1, max: 20 })) => {
  const plainObject = [
    {
      id: 1,
      name: 'product-type-1',
      product_number: 'no1',
      product_group: ProductGroup.Attachments,
    },
    {
      id: 2,
      name: 'product-type-2',
      product_number: 'no2',
      product_group: ProductGroup.Attachments,
    }
  ]

  const productType1 = new ProductType()
  productType1.id = plainObject[0].id
  productType1.name = plainObject[0].name
  productType1.productNumber = plainObject[0].product_number
  productType1.productGroup = plainObject[0].product_group
  const productType2 = new ProductType()
  productType2.id = plainObject[1].id
  productType2.name = plainObject[1].name
  productType2.productNumber = plainObject[1].product_number
  productType2.productGroup = plainObject[1].product_group
  const deserializedObject = [productType1, productType2]
  return { plainObject, deserializedObject }
}

// Product
export const getDummyProductData = () => {
  const deserializedObject = new Product()
  const productType = getDummyProductTypeData()
  const plainObject = {
    id: faker.random.number(),
    name: faker.random.word(),
    serial_number: faker.random.uuid(),
    product_type: productType.plainObject
  }
  deserializedObject.id = plainObject.id
  deserializedObject.name = plainObject.name
  deserializedObject.serialNumber = plainObject.serial_number
  deserializedObject.type = productType.deserializedObject

  return { plainObject, deserializedObject }
}

export const getDummyProductArrayData = (count = faker.random.number({ min: 1, max: 20 })) => {
  const plainObject = []
  const deserializedObject = []
  for (let i = 0; i < count; i++) {
    const product = getDummyProductData()
    plainObject.push(product.plainObject)
    deserializedObject.push(product.deserializedObject)
  }

  return { plainObject, deserializedObject }
}

// Coupons
export const getDummyCouponData = () => {
  const deserializedObject = new Coupon()
  const plainObject = {
    id: faker.random.number(),
    amount: faker.random.number({ min: 1 }),
    discount_percentage: 0,
    validity: faker.random.boolean(),
    coupon_code: faker.random.word(),
    state: CouponState.Active
  }
  deserializedObject.id = plainObject.id
  deserializedObject.name = 'Coupon: ' + plainObject.coupon_code
  deserializedObject.amount = plainObject.amount
  deserializedObject.percentage = plainObject.discount_percentage
  deserializedObject.validity = plainObject.validity
  deserializedObject.code = plainObject.coupon_code
  deserializedObject.state = plainObject.state
  deserializedObject.factor = CouponFactor.Amount
  deserializedObject.type = CouponType.Regular

  return { plainObject, deserializedObject }
}

export const getDummyCouponArrayData = (count = faker.random.number({ min: 1, max: 20 })) => {
  const plainObject = []
  const deserializedObject = []
  for (let i = 0; i < count; i++) {
    const bayStatus = getDummyCouponData()
    plainObject.push(bayStatus.plainObject)
    deserializedObject.push(bayStatus.deserializedObject)
  }

  return { plainObject, deserializedObject }
}

// Bundles
export const getDummyBundleData = () => {
  const deserializedObject = new Bundle()
  const coupon = getDummyCouponData()
  const productTypes = getDummyProductTypeArrayData(2)
  const plainObject = {
    id: faker.random.number(),
    name: faker.random.word(),
    bundle_price: faker.random.number(),
    coupon: coupon.plainObject,
    product_type_1: productTypes.plainObject[0],
    product_type_2: productTypes.plainObject[1]
  }
  deserializedObject.id = plainObject.id
  deserializedObject.name = plainObject.name
  deserializedObject.price = plainObject.bundle_price
  deserializedObject.coupon = coupon.deserializedObject
  deserializedObject.products = productTypes.deserializedObject
  deserializedObject.coupon.type = CouponType.Bundle
  deserializedObject.coupon.name = plainObject.name

  return { plainObject, deserializedObject }
}

export const getDummyBundleArrayData = (count = faker.random.number({ min: 1, max: 20 })) => {
  const plainObject = []
  const deserializedObject = []
  for (let i = 0; i < count; i++) {
    const bundle = getDummyBundleData()

    plainObject.push(bundle.plainObject)
    deserializedObject.push(bundle.deserializedObject)
  }
  return { plainObject, deserializedObject }
}

export const getFakeAttachmentRelationData = () => {
  const productTypes = getDummyProductTypeArrayData(2)
  productTypes.plainObject[1].product_group = ProductGroup.Attachments
  productTypes.deserializedObject[1].productGroup = ProductGroup.Attachments
  const plainObject = [{
    id: 1,
    name: 'Bundle 1',
    bundle_price: 123,
    coupon: {},
    product_type_1: productTypes.plainObject[0],
    product_type_2: productTypes.plainObject[1]
  }]
  return { plainObject, attachmentArray: [productTypes.deserializedObject[1]] }
}

// Prices
export const getDummyPriceData = () => {
  const deserializedObject = new Price()
  const plainObject = {
    id: faker.random.number(),
    retail_price: faker.random.number(),
    wholesale_price: faker.random.number(),
  }
  deserializedObject.id = plainObject.id
  deserializedObject.retailPrice = plainObject.retail_price
  deserializedObject.wholesalePrice = plainObject.wholesale_price

  return { plainObject, deserializedObject }
}

// ProductTypes
export const getDummyProductTypeData = (productGroup?: ProductGroup) => {
  const deserializedObject = new ProductType()
  const price = getDummyPriceData()
  const plainObject = {
    id: faker.random.number(),
    name: faker.random.word(),
    subtitle: faker.random.word(),
    product_number: faker.random.word(),
    product_group: productGroup || ProductGroup.Machines,
    upc_number: faker.random.word(),
    sku_number: faker.random.word(),
    image_url: faker.random.word(),
    details: faker.random.word(),
    indoor: faker.random.boolean(),
    quantity_available: faker.random.number({ min: 1 }),
    description_1: faker.random.word(),
    description_2: faker.random.word(),
    description_3: faker.random.word(),
    price: price.plainObject,
    photo: {
      url: faker.random.word()
    }
  }
  deserializedObject.id = plainObject.id
  deserializedObject.name = plainObject.name
  deserializedObject.subtitle = plainObject.subtitle
  deserializedObject.productNumber = plainObject.product_number
  deserializedObject.productGroup = plainObject.product_group
  deserializedObject.upcNumber = plainObject.upc_number
  deserializedObject.skuNumber = plainObject.sku_number
  deserializedObject.imageName = plainObject.image_url
  deserializedObject.imageUrl = plainObject.photo.url
  deserializedObject.details = plainObject.details
  deserializedObject.quantity = plainObject.quantity_available
  deserializedObject.description1 = plainObject.description_1
  deserializedObject.description2 = plainObject.description_2
  deserializedObject.description3 = plainObject.description_3
  deserializedObject.environment = (plainObject.indoor) ? ProductEnvironment.Indoor : ProductEnvironment.Outdoor
  deserializedObject.priceDetails = price.deserializedObject

  return { plainObject, deserializedObject }
}

export const getDummyProductTypeArrayData = (count = faker.random.number({ min: 1, max: 20 }), productGroup?: ProductGroup) => {
  const plainObject = []
  const deserializedObject = []
  for (let i = 0; i < count; i++) {
    const productType = getDummyProductTypeData(productGroup)

    plainObject.push(productType.plainObject)
    deserializedObject.push(productType.deserializedObject)
  }
  return { plainObject, deserializedObject }
}

export const getExampleProductTypeAvailableData = (count = faker.random.number({ min: 1, max: 20 })) => {
  const productTypeArray = getDummyProductTypeArrayData(count * 3)
  for (let i = 0; i < productTypeArray.plainObject.length; i++) {
    if (i % 3 === 0) {
      productTypeArray.plainObject[i].product_group = ProductGroup.Machines
      productTypeArray.deserializedObject[i].productGroup = ProductGroup.Machines
    } else if (i % 3 === 1) {
      productTypeArray.plainObject[i].product_group = ProductGroup.Attachments
      productTypeArray.deserializedObject[i].productGroup = ProductGroup.Attachments
    } else if (i % 3 === 2) {
      productTypeArray.plainObject[i].product_group = ProductGroup.Solutions
      productTypeArray.deserializedObject[i].productGroup = ProductGroup.Solutions
    }
  }
  return productTypeArray
}

// RentalProduct
export const getDummyRentalProductData = () => {
  const deserializedObject = new RentalProduct()
  const bay = getDummyBayData()
  const product = getDummyProductData()
  const plainObject = {
    product: {
      product_type: product.plainObject.product_type,
    },
    quantity: 1,
    bay: bay.plainObject
  }
  deserializedObject.type = product.deserializedObject.type
  deserializedObject.quantity = plainObject.quantity
  deserializedObject.bay = bay.deserializedObject
  return { plainObject, deserializedObject }
}

export const getDummyRentalProductArrayData = (count = faker.random.number({ min: 1, max: 5 })) => {
  const plainObject = []
  const deserializedObject = []
  for (let i = 0; i < count; i++) {
    const rentalProduct = getDummyRentalProductData()

    plainObject.push(rentalProduct.plainObject)
    deserializedObject.push(rentalProduct.deserializedObject)
  }
  return { plainObject, deserializedObject }
}

// Reservation
export const getDummyReservationData = (rentalProductCount?: number) => {
  const deserializedObject = new Reservation()
  const rentalProducts = getDummyRentalProductArrayData(rentalProductCount)
  const startDate = faker.date.past(1)
  const coupon = getDummyCouponData()
  const plainObject = {
    id: faker.random.number(),
    start_date: startDate,
    rental_products: rentalProducts.plainObject,
    coupons: [coupon.plainObject]
  }
  deserializedObject.id = plainObject.id
  deserializedObject.products = rentalProducts.deserializedObject
  deserializedObject.startDate = startDate
  deserializedObject.coupons = [coupon.deserializedObject]
  return { plainObject, deserializedObject }
}

export const getDummyReservationArrayData = (count = faker.random.number({ min: 1, max: 5 })) => {
  const plainObject = []
  const deserializedObject = []
  for (let i = 0; i < count; i++) {
    const reservation = getDummyReservationData()

    plainObject.push(reservation.plainObject)
    deserializedObject.push(reservation.deserializedObject)
  }
  return { plainObject, deserializedObject }
}

// Card
export const getTestingCardData = () => {
  return new Card('4000100011112224', 9, 2021, '123', 'John', 'Doe')
}

export const getDummyCardData = () => {
  const deserializedObject = new Card()
  const plainObject = {
    number: faker.random.word(),
    month: faker.random.number(),
    year: faker.random.number(),
    verification_value: faker.random.word(),
    first_name: faker.name.firstName(),
    last_name: faker.name.lastName(),
  }
  deserializedObject.number = plainObject.number
  deserializedObject.month = plainObject.month
  deserializedObject.year = plainObject.year
  deserializedObject.verificationValue = plainObject.verification_value
  deserializedObject.firstName = plainObject.first_name
  deserializedObject.lastName = plainObject.last_name
  return { plainObject, deserializedObject }
}

// BayBeamStatus

export const getDummyBayBeamStatusData = () => {
  const deserializedObject = new BayBeamStatus()
  const plainObject = {
    door_status: BayDoorStatus.Closed,
    beam_status: {
      beam_1: BeamStatus.Empty,
      beam_2: BeamStatus.Filled
    }
  }

  deserializedObject.doorStatus = plainObject.door_status
  deserializedObject.beamStatus = [BeamStatus.Empty, BeamStatus.Filled]

  return { plainObject, deserializedObject }
}

// CardTracks

export const getDummyCardTracksData = () => {
  const deserializedObject = new CardTracks()
  const plainObject = {
    track_1: faker.random.word(),
    track_2: faker.random.word(),
    track_3: faker.random.word()
  }
  deserializedObject.tracks = [plainObject.track_1, plainObject.track_2, plainObject.track_3]
  return { plainObject, deserializedObject }
}

// CartCoupon

export const getDummyCartCouponData = () => {
  const coupon = getDummyCouponData()
  const plainObject = {
    coupon: coupon.plainObject,
    requirements: [1, 2]
  }
  const deserializedObject = new CartCoupon(coupon.deserializedObject, plainObject.requirements)
  return { plainObject, deserializedObject }
}

// Rental

export const getDummyRentalData = () => {
  const deserializedObject = new Rental()
  const products = getDummyRentalProductData()
  const plainObject = {
    id: faker.random.number(),
    kiosk_id: faker.random.number(),
    renter_name: faker.name.firstName(),
    renter_email: faker.internet.email(),
    renter_phone: faker.phone.phoneNumber(),
    renter_zip: faker.address.zipCode(),
    coupon_codes: [faker.random.word()],
    products: products.plainObject,
    startDate: faker.date.past(1)
  }
  deserializedObject.id = plainObject.id.toString()
  deserializedObject.kioskId = plainObject.kiosk_id
  deserializedObject.renterName = plainObject.renter_name
  deserializedObject.renterEmail = plainObject.renter_email
  deserializedObject.renterPhone = plainObject.renter_phone
  deserializedObject.renterZip = plainObject.renter_zip
  deserializedObject.couponCodes = plainObject.coupon_codes
  deserializedObject.startDate = plainObject.startDate
  return { plainObject, deserializedObject }
}

export const getDummyPaymentDetails = () => {
  const plainObject = {
    total_amount: faker.random.number(),
    total_tax_amount: faker.random.number()
  }
  const deserializedObject: PaymentDetails =
    new PaymentDetails(plainObject.total_amount, plainObject.total_tax_amount)
  return { plainObject, deserializedObject }
}

export const getExamplePrepareRentalData = () => {
  return {
    ...(getDummyPaymentDetails().plainObject),
    rental: {
      id: faker.random.number()
    }
  }
}

// BayTransactionAction

export const getDummyBayTransactionAction = () => {
  const deserializedObject = new BayTransactionAction()
  const items = getDummyProductTypeArrayData()
  const plainObject = {
    type: BayTransactionActionType.PickFromBay,
    items: items.plainObject
  }
  deserializedObject.type = plainObject.type
  deserializedObject.items = items.deserializedObject
  return { plainObject, deserializedObject }
}

// BayTransaction

export const getDummyBayTransaction = () => {
  const deserializedObject = new BayTransaction()
  const reservation = getDummyReservationData()
  const plainObject = {
    type: BayTransactionType.Rent,
    reservation: reservation.plainObject
  }
  deserializedObject.type = plainObject.type
  deserializedObject.reservation = reservation.deserializedObject
  return { plainObject, deserializedObject }
}
