import { Component, OnInit } from '@angular/core'
import { RoutingService } from '@shared/services'
import { CartService } from '@shared/stores/cart.service'
import { StockService } from '@shared/stores/stock.service'
import { RentalProduct } from 'src/app/domain/rental-product'
import { CartCoupon } from 'src/app/domain/cart-coupon.model'
import { ProductGroup } from 'src/app/domain/product-type.model'
import { PaymentDetails } from 'src/app/domain/payment-details.model'

const COUPON_STEP = 'coupon'
const AGREEMENT_STEP = 'agreement'

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  public kioskJde: string
  public get cart(): RentalProduct[] { return this.cartService.items }
  public get cartEmpty(): boolean { return this.cartService.isEmpty }
  public get coupons(): CartCoupon[] { return this.cartService.coupons }
  public get activeCoupon(): CartCoupon { return this.cartService.activeCoupon }
  public get paymentDetails(): PaymentDetails { return this.cartService.paymentDetails }
  public get subtotal(): number {
    return this.paymentDetails?.totalAmount
  }
  public get tax(): string {
    return this.paymentDetails?.taxAmount.toFixed(2)
  }
  public get total(): string {
    return (this.paymentDetails)
      ? (this.paymentDetails.totalAmount + parseFloat(this.paymentDetails.taxAmount.toFixed(2)) - parseFloat(this.cartService.activeCoupon ? this.cartService.activeCoupon.discount : '0.0')).toFixed(2)
      : null
  }

  public get isReservation(): boolean { return this.cartService.isReservation }

  public constructor(
    private cartService: CartService,
    private stockService: StockService,
    private routingService: RoutingService,
  ) { }

  public ngOnInit(): void {
    this.kioskJde = localStorage.getItem('kiosk_jde')
    // if (this.cartService.isReservation) {
    //   this.stockService.restock().subscribe()
    // }
  }

  public onRemove(index: number): void {
    this.cartService.removeAtIndex(index)
  }

  public onNext(): void {
    if (this.cartEmpty) {
      return
    }
    this.routingService.navigateToRent(AGREEMENT_STEP)
  }

  public onAddMore(): void {
    this.routingService.navigateToRent()
  }

  public onAddCoupon(): void {
    this.routingService.navigateToRent(COUPON_STEP)
  }

  public onBack(): void {
    if(this.isReservation){
      this.routingService.navigateToHome();
    }
    else {
      this.routingService.navigateToRent(ProductGroup.Solutions)
    }
  }
}
