import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Bay, BayType, BayState } from 'src/app/domain/bay.model';
import { BayStatus } from 'src/app/domain/bay-status.model';
import { ProductService } from '@shared/services/product.service';
import { ProductType, ProductGroup } from 'src/app/domain/product-type.model';
import { AdminService } from '@shared/services/admin.service';
import { FormControl, Validators } from '@angular/forms';
import { Observable, zip } from 'rxjs';

@Component({
    selector: 'app-configure-bay',
    templateUrl: './configure-bay.component.html',
    styleUrls: ['./configure-bay.component.scss']
})
export class ConfigureBayComponent implements OnInit, OnChanges {

    @Input() bayStatus: BayStatus;
    @Output() return: EventEmitter<void>;
    public keyboardVisible: boolean;
    public createMachineDialog: boolean;
    public error: any;
    public input: FormControl;
    public selectorItems: any[];
    public machines: ProductType[];
    public referenceMachineType: ProductType;
    private selectedAttachments: any[];
    public get bay(): Bay { return this.bayStatus.bay; }
    public get status(): string { return this.bay.state.charAt(0).toUpperCase() + this.bay.state.substr(1); }
    public get serial(): string { return this.bayStatus.serial; }
    public get capacity(): number { return this.bay.capacity; }
    public get bayStatusOptions(): any[] {
        return Object.keys(BayState).map(key => ({ key: BayState[key], text: key }));
    }
    public get noBeamsOptions(): any[] {
        return [1, 2, 3, 4].map(beamNumber => ({ key: beamNumber, text: beamNumber + ' Beams' }));
    }

    public constructor(
        private adminService: AdminService,
        public productService: ProductService
    ) {
        this.keyboardVisible = false;
        this.createMachineDialog = false;
        this.input = new FormControl('', [Validators.required]);
        this.return = new EventEmitter();
    }

    public ngOnInit(): void {
        this.productService.getTypes(ProductGroup.Machines).subscribe(machines => {
            this.machines = machines;
        });
    }

    public ngOnChanges(): void {
        if (this.bayStatus) {
            this.input.setValue(this.bayStatus.serial);
            this.checkReferenceValues();
        }
    }

    public onChangeType(type: string): void {
        this.bay.type = type as BayType;
        if (type === BayType.Machine) {
            this.referenceMachineType = null;
            this.bay.capacity = 1;
        }
        if (type === BayType.Attachment) {
            this.keyboardVisible = false;
        }
    }

    private checkReferenceValues(): void {
        if (this.bay.type === BayType.Machine) {
            if (this.bayStatus.products.length > 0) {
                this.referenceMachineType = this.bayStatus.products[0].type;
            }
        }
    }

    public onChangeState(state: string): void {
        this.bay.state = state as BayState;
    }

    public onChangeCapacity(capacity: number): void {
        this.bay.capacity = capacity;
    }

    public onSelectProduct(): void {
        this.selectorItems = [
            { text: 'Empty', value: null },
            ...this.machines.map(machine => ({ text: machine.name, value: machine }))
        ];
    }

    public onCloseSelector(): void {
        this.selectorItems = null;
    }

    public onProductSelected(value: ProductType): void {
        this.selectorItems = null;
        if (this.bay.type === BayType.Machine) {
            this.referenceMachineType = value;
            this.keyboardVisible = false;
            if (value == null) {
                this.input.setValue('');
            }
        }
    }

    public onUpdate(): void {
        this.adminService.updateBay(this.bay.id, this.bay).subscribe(() => {
            if (this.bay.type === BayType.Machine) {
                this.updateMachines();
            } else if (this.bay.type === BayType.Attachment) {
                this.updateAttachments();
            }
        });
    }

    public onToggleKeyboard(value: boolean, event?: Event): void {
        if (!this.referenceMachineType) {
            this.keyboardVisible = false;
            return;
        }
        this.keyboardVisible = value;
        if (event) {
            event.stopPropagation();
        }
    }

    public isUpdateEnabled(): boolean {
        if (this.bay.type === BayType.Machine) {
            return !this.referenceMachineType || this.input.valid;
        } else {
            return this.selectedAttachments && this.attachmentsQuantity() <= this.bay.capacity;
        }
    }

    public onUpdateSelectedAttachments(selected: any): void {
        this.selectedAttachments = selected;
    }

    public goBack(): void {
        this.return.emit();
    }

    private updateMachines(): void {
        this.productService.getBySerial(this.input.value).subscribe(products => {
            if (products.length > 0) {
                const product = products[0];
                if (this.referenceMachineType == null) {
                    this.productService.unloadFromBay(this.bayStatus.products[0].product.id, this.bay.id).subscribe(() => {
                        this.goBack();
                    },
                        error => {
                            this.error = { title: 'Unable to remove machine', message: error };
                        });
                } else if (product.type.id === this.referenceMachineType.id) {
                    if (this.bayStatus.products.length > 0) {
                        this.productService.unloadFromBay(this.bayStatus.products[0].product.id, this.bay.id).subscribe(() => {
                            this.productService.loadMachineToBay(product.id, this.bay.id).subscribe(() => {
                                this.goBack();
                            },
                                error => {
                                    this.error = {
                                        title: 'Unable to replace machine',
                                        message: error
                                    };
                                });
                        },
                            error => {
                                this.error = {
                                    title: 'Unable to replace machine',
                                    message: error
                                };
                            });
                    } else {
                        this.productService.loadMachineToBay(product.id, this.bay.id).subscribe(() => {
                            this.goBack();
                        },
                            error => {
                                this.error = {
                                    title: 'Unable to replace machine',
                                    message: error
                                };
                            });
                    }
                } else {
                    this.error = {
                        title: 'Unable to replace machine',
                        message: 'Machine type ' + this.referenceMachineType.name + ' does not match it\'s serial number'
                    };
                }
            } else {
                this.createMachineDialog = true;
            }
        });
    }

    public onCreateMachine(): void {
        const serialNumber = this.input.value;
        const productTypeId = this.referenceMachineType.id;
        const bayId = this.bay.id;
        this.productService.createMachineInBay(
            serialNumber,
            productTypeId.toString(),
            bayId.toString()
        ).subscribe(() => {
            this.createMachineDialog = false;
        }, error => {
            this.createMachineDialog = false;
            this.error = {
                title: 'Unable to create machine',
                message: error
            };
        });
    }

    private attachmentsQuantity(): number {
        let total = 0;
        this.selectedAttachments.forEach(selection => total += selection.quantity);
        return total;
    }

    private updateAttachments(): void {
        if (!this.selectedAttachments || this.attachmentsQuantity() > this.bay.capacity) {
            this.error = { title: 'Unable to update attachments', message: 'Number of attachments exceeds bay\'s capacity' };
            return;
        }
        const changes: Observable<any>[] = [];
        const markForRemoval: any = {};
        this.bayStatus.products.forEach(product => {
            markForRemoval[product.type.id] = true;
        });
        this.selectedAttachments.forEach(selection => {
            markForRemoval[selection.type.id] = false;
            changes.push(this.productService.loadAttachmentsToBay(selection.type.id, this.bay.id, selection.quantity));
        });
        Object.keys(markForRemoval).forEach(key => {
            if (markForRemoval[key]) {
                changes.push(this.productService.unloadAttachmentFromBay(+key, this.bay.id));
            }
        });
        zip(...changes).subscribe(() => {
            this.goBack();
        },
            error => {
                this.error = { title: 'Unable to update attachments', message: error };
            });
    }
}
