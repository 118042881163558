import { autoserialize, autoserializeAs } from 'cerialize';
import { ProductType } from './product-type.model';

export class Product {
  @autoserialize id: number;
  @autoserializeAs('name') name: string;
  @autoserializeAs('serial_number') serialNumber: string;
  @autoserializeAs(ProductType, 'product_type') type: ProductType;

  public constructor(
    id?: number,
    name?: string,
    serialNumber?: string,
    type?: ProductType
  ) {
    this.id = id;
    this.name = name;
    this.serialNumber = serialNumber;
    this.type = type;
  }
}
