import * as moment from 'moment'
import { autoserializeAs } from 'cerialize'
import { Card } from './card.model'
import { RentalProduct } from './rental-product'

export class Rental {
  @autoserializeAs('rental_id') id: string
  @autoserializeAs('kiosk_id') kioskId: number
  @autoserializeAs('renter_name') renterName: string
  @autoserializeAs('renter_email') renterEmail: string
  @autoserializeAs('renter_phone') renterPhone: string
  @autoserializeAs('renter_zip') renterZip: string
  @autoserializeAs('coupon_codes') couponCodes: string[]
  @autoserializeAs(Card, 'credit_card') creditCard: Card
  @autoserializeAs('track1') track1: string
  @autoserializeAs('track2') track2: string
  @autoserializeAs('track3') track3: string
  @autoserializeAs(RentalProduct, 'products') products: RentalProduct[]
  public startDate: Date
  public get isEmpty(): boolean { return this.products.length === 0 }
  constructor(data?: Partial<Rental>) {
    this.products = []
    if (data) {
      Object.assign(this, data)
    }
  }
  public static OnSerialized(instance: Rental, json: any): void {
    json.start_date = moment(instance.startDate).format('YYYY-MM-DD HH:mm:ss Z')
  }
}
