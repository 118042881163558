import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@shared/services';
import { CartService } from '@shared/stores/cart.service';
import { StockService } from '@shared/stores/stock.service';
import { Bundle } from 'src/app/domain/bundle.model';
import { ProductGroup, ProductType } from 'src/app/domain/product-type.model';
import { PresentationItem } from 'src/app/domain/presentation-item.model';

@Component({
    selector: 'app-bundles',
    templateUrl: './bundles.component.html',
    styleUrls: ['./bundles.component.scss']
})
export class BundlesComponent implements OnInit {

    public currentBundle: number;
    public get progress(): number { return this.cartService.progress; }
    public get hasGroup(): boolean { return this.cartService.hasGroup(ProductGroup.Bundle); }
    // public get bundles(): PresentationItem <Bundle>[] {
    //   // console.log((this.stockService.bundles)
    //   // ? this.stockService.bundles.map(item => new PresentationItem(item))
    //   // : [])
    //     return (this.stockService.bundles)
    //         ? this.stockService.bundles.map(item => new PresentationItem(item))
    //         : [];
    // }

    public get currentBundleProducts(): PresentationItem<ProductType>[] {
        if (!this.stockService.bundles || this.stockService.bundles.length === 0) {
            return [];
        }

        return this.stockService.bundles[this.currentBundle]
        .products.map(productType => new PresentationItem(productType));
    }

    public constructor(
        private route: ActivatedRoute,
        private cartService: CartService,
        private stockService: StockService,
        private routingService: RoutingService
    ) {
        this.currentBundle = 0;
    }

    public ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.currentBundle = params.index;
        });
        if (this.cartService.isEmpty && !this.cartService.rental.id) {
            this.stockService.restock().subscribe();
        }
        console.log(this.currentBundle)
    }

    public onNext(): void {
        this.routingService.navigateBack();
    }

    public onChangeIndex(index: number): void {
        this.currentBundle = index;
    }
}
