<app-header (back)="onBack()" [canNavigateBack]="true" [customBackNavigation]="true" [customBackText]="((this.currentStep == 'creditCard') ? 'GLOBAL.EXIT' : null)" [redBorder]="true" [showLanguage]="true"></app-header>
<div class="payment page d-flex flex-column">
    <div class="w-100 title">
        <h1 [ngClass]="{ 'long': step && step.longTitle }">{{ step.title | translate | uppercase }}</h1>
    </div>
    <div *ngIf="step.input" class="w-100 d-flex flex-column input-label">
        <label [ngClass]="{ 'placeholder': !input.value || input.value.length === 0 }" class="d-flex align-items-center justify-content-center">
            {{input.value || (step?.placeholder | translate)}}
        </label>
        <span *ngIf="step.inputNote" class="message">*
            {{ step.inputNote | translate | uppercase }}</span>
    </div>
    <div *ngIf="currentStep === 'creditCard'" class="w-100 credit-card">
        <app-card-read (readComplete)="onCardReadComplete($event)"></app-card-read>
    </div>
    <div *ngIf="currentStep === 'error'" class="w-100 error centered">
        <label class="d-flex flex-column text error">
            {{ errorMessage | uppercase }}
        </label>
    </div>
    <div *ngIf="step.input" [ngClass]="{ 'email':step.layout == 'email' }" class="keyboard">
        <app-keyboard [formControl]="input" [layout]="step.layout || 'letters'" [value]="input.value"></app-keyboard>
    </div>
    <div *ngIf="step.next && step.message">
        <button (click)="onStepChange(step.next)" class="btn-rug confirm-button">{{ (step.nextText || 'GLOBAL.NEXT') | translate | uppercase }}</button>
    </div>
    <div *ngIf="currentStep !== 'creditCard' && step.next && !step.message" class="buttons d-flex w-100 justify-content-end">
        <button (click)="onStepChange(step.next)" [ngClass]="{ 'disabled': (step.validation && !input.valid) || submited }" class="btn-rug button d-flex align-items-center justify-content-center">
            {{ 'GLOBAL.NEXT' | translate | uppercase }}
            <img *ngElse alt="next" src="assets/img/arrow-right-white.svg"/>
        </button>
    </div>
    <div *ngIf="exitDialog" class="dialog">
        <div class="dialog-box d-flex flex-column justify-content-between">
            <div class="title">
                {{ 'PAYMENT.DIALOG.TITLE' | translate | uppercase }}
            </div>
            <div class="message">
                {{ 'PAYMENT.DIALOG.MESSAGE' | translate | uppercase }}
            </div>
            <div class="buttons d-flex w-100 justify-content-between">
                <button (click)="onToggleExitDialog(false)" class="btn-rug button hollow" id="cancelDialog">
                    {{ 'GLOBAL.CANCEL' | translate | uppercase }}
                </button>
                <button (click)="onStepChange('home')" class="btn-rug button">
                    {{ 'GLOBAL.EXIT' | translate | uppercase }}
                </button>
            </div>
        </div>
    </div>
</div>
<img alt="path" class="decoration-path" src="assets/img/path.svg"/>
<img alt="background" class="decoration-background" src="assets/img/bays-background.jpg"/>
