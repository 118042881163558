<div class="form dimmed scrollable large d-flex flex-column">
    <div *ngFor="let entry of selected; let i = index" class="d-flex justify-content-between attachment-entry">
        <div class="d-flex flex-column name">
            <label>ITEM NAME</label>
            <div (click)="onShowSelector(i)" class="d-flex justify-content-between input-box">
                <span class="input-rug"> {{entry.type.name}} </span>
            </div>
        </div>
        <div class="d-flex flex-column quantity">
            <label>QUANTITY</label>
            <div class="d-flex justify-content-between input-box">
                <span class="input-rug">
                    <button (click)="onChangeQuantity(i, -1)" class="btn-rug centered" [ngClass]="{ 'disabled': !isChangeable(i, -1) }">
                        <span *ngIf="entry.quantity > 1">-</span>
                        <img *ngIf="entry.quantity <= 1" src="/assets/img/delete.svg" alt="delete" />
                    </button>
                    {{ entry.quantity }}
                    <button (click)="onChangeQuantity(i, +1)" class="btn-rug centered" [ngClass]="{ 'disabled': !isChangeable(i, +1) }">+</button>
                </span>
            </div>
            <span *ngIf="entry.minimum > 0" class="rented">Rented: {{ entry.minimum }}</span>
        </div>
    </div>
    <hr *ngIf="selected.length > 0" />
    <button (click)="onAddAttachment()" class="btn-rug add-button" [ngClass]="{ 'disabled': !options || options.length == 0 || totalCount >= maximum }">ADD ITEM</button>
</div>
<app-modal-selector *ngIf="selectorIndex != null" [options]="options" (selected)="onSelected($event)" (closed)="onCloseSelector()"></app-modal-selector>