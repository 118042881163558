<app-header [canNavigateBack]="false" [showLanguage]="true"></app-header>
<div class="content d-flex flex-column justify-content-between align-items-baseline">
  <div class="scrollable">
    <h1 class="heading">{{ "TUTORIAL.HOW_TO_RENT.TITLE" | translate }}</h1>
    <ol class='list'>
      <li>Select your <span>Rug Doctor Cleaning Products</span> above and pay for them at the store's Checkout aisle. Then return here to rent your <span>Rug Doctor Machine</span>.</li>
      <li *ngFor="let i of [2,3,4,5,6]">{{ "TUTORIAL.HOW_TO_RENT.TEXT.T" + i | translate }}</li>
    </ol>
    <h1 class="heading">{{ "TUTORIAL.HOW_TO_RENT.RETURN" | translate }}</h1>
    <ol class='list'>
      <li *ngFor="let i of [1,2]">{{ "TUTORIAL.HOW_TO_RENT.RETURN_TEXT.T" + i | translate }}</li>
    </ol>
    <div class="fade-out-content"></div>
  </div>
  <button class="btn-rug" (click)="routingService.navigateBack()"><p>{{ "GLOBAL.BACK" | translate }}</p></button>
</div>
