import { Component, OnInit } from '@angular/core'
import { RoutingService } from '@services'
import { BayStatus } from 'src/app/domain/bay-status.model'
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'
import { UtilService } from '@shared/services/util.service'

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public logOutDialog: boolean
  public testModeDialog: boolean
  public currentPage: string
  public currentBay: BayStatus

  public constructor(
    public routingService: RoutingService,
    private authService: AuthenticationService,
    private utilService: UtilService,
  ) {
  }

  public get kioskId(): number {
    return this.authService.kioskId
  }

  public ngOnInit(): void {
    this.setCurrentPage('operations')
    if (!this.authService.kioskId) {
      this.setCurrentPage('kiosk_jde')
    }
  }

  public onSelectBay(bayStatus: BayStatus): void {
    this.currentBay = bayStatus
    this.setCurrentPage('configure-bay')
  }

  public onNewBay(): void {
    this.setCurrentPage('new-bay')
  }

  public onBayConfiguration(): void {
    this.setCurrentPage('bay')
  }

  public setCurrentPage(page: string) {
    this.currentPage = page
  }

  public setLogOutDialog(logOutDialog: boolean): void {
    this.logOutDialog = logOutDialog
  }

  public onBack(): void {
    if (this.currentPage === 'configure-bay') {
      this.setCurrentPage('bay')
    } else if (this.currentPage === 'kiosk_jde') {
      this.setCurrentPage('kiosk')
    } else {
      this.setCurrentPage('operations')
    }
  }

  public onLogOut(): void {
    this.authService.logout()
  }

  public callRotate() {
    this.utilService.rotateScreen().subscribe(
      next => {

      },
      error => {
        console.log(error)
      }
    )
  }

  public updateKiosk() {
    this.routingService.navigateToUpdating()
  }
}
