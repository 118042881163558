import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@shared/services/i18n.service';
import { RoutingService } from '@services';
import { ProductType } from 'src/app/domain/product-type.model';
import { ProductService } from '@shared/services/product.service';
import { CartService } from '@shared/stores/cart.service';
import { StockService } from '@shared/stores/stock.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    public featured: ProductType;
    public kioskJde: string;
    public get price(): number { return (this.featured) ? this.featured.price : null; }

    public constructor(
        public translate: TranslateService,
        public i18n: InternationalizationService,
        public routingService: RoutingService,
        private cartService: CartService,
        private stockService: StockService,
        private productService: ProductService
    ) {
        translate.setDefaultLang(i18n.lang);
    }

    public ngOnInit(): void {
      this.kioskJde = localStorage.getItem('kiosk_jde');
        this.productService.getFeaturedProductType().subscribe(featured => this.featured = featured);
    }

    public onRent(route?: string): void {
        this.cartService.restart();
        // is this restock necessary?
        // this.stockService.restock();
        this.routingService.navigateToRent(route);
    }
}
