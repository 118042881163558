import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-modal-selector',
    templateUrl: './modal-selector.component.html',
    styleUrls: ['./modal-selector.component.scss']
})
export class ModalSelectorComponent implements OnInit {

    @Input() options: any[];
    @Output() selected: EventEmitter<any>;
    @Output() closed: EventEmitter<void>;
    public constructor() {
        this.selected = new EventEmitter();
        this.closed = new EventEmitter();
    }
    public ngOnInit(): void { }
    public onClose(): void {
        this.closed.emit();
    }
    public onListClick(event: Event): void {
        event.stopPropagation();
    }
    public onSelectValue(value: any): void {
        this.selected.emit(value);
    }
}
