import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ProductService } from '@shared/services/product.service';
import { ProductEnvironment, ProductType } from 'src/app/domain/product-type.model';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@shared/services/i18n.service';
import { RentalProduct } from 'src/app/domain/rental-product';
import { CartService } from '@shared/stores/cart.service';
import { PresentationItem } from 'src/app/domain/presentation-item.model';

const SLICK_NAME = 'slickGallery';

export enum GalleryViewType {
    Gallery = 'gallery',
    Grid = 'grid'
}

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnChanges {

    @Input() gridView: boolean;
    @Input() hasGroup: boolean;
    @Input() items: PresentationItem<ProductType>[];
    @Output() next: EventEmitter<void>;
    @ViewChild(SLICK_NAME) slick: any;
    public filtersEnabled: boolean;
    public gridScrollHitTop: boolean;
    public gridScrollHitBottom: boolean;
    public currentSlide: number;
    public view: GalleryViewType;
    public filter: ProductEnvironment;
    public config: object;
    public showDescription = false;
    public descriptionItem: PresentationItem<ProductType>;
    public get filtered(): PresentationItem<ProductType>[] {
        if (this.filter === ProductEnvironment.Indoor) {
            return this.indoor;
        } else if (this.filter === ProductEnvironment.Outdoor) {
            return this.outdoor;
        }
        return this.items;
    }
    public get indoor(): PresentationItem<ProductType>[] {
        return this.items.filter(product => product.originalValue.environment === ProductEnvironment.Indoor);
    }
    public get outdoor(): PresentationItem<ProductType>[] {
        return this.items.filter(product => product.originalValue.environment === ProductEnvironment.Outdoor);
    }

    public constructor(
        public product: ProductService,
        public translate: TranslateService,
        public i18n: InternationalizationService,
        private cartService: CartService
    ) {
        this.view = GalleryViewType.Gallery;
        this.currentSlide = 0;
        this.next = new EventEmitter();
        this.config = {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '19%',
            infinite: false,
            touchThreshold: 25,
            arrow: false
        };
    }

    public ngOnChanges(): void {
        if (this.items) {
            this.filtersEnabled = this.indoor.length > 0 && this.outdoor.length > 0;
            if (!this.filtersEnabled) {
                this.filter = null;
            }
        }
    }

    public onBeforeChange(event): void {
        this.currentSlide = event.nextSlide;
    }

    public onAdd(index: number): void {
        if (this.quantity(index) > 0) {
            return;
        }
        this.cartService.add(new RentalProduct(this.filtered[index].originalValue, 1));
    }

    public quantity(index: number): number {
        return this.cartService.quantity(this.filtered[index].id);
    }

    public changeQuantity(index: number, delta: number): void {
        if (delta < 0 && this.quantity(index) < 2) {
            return;
        }
        if (delta < 0) {
            this.cartService.remove(this.filtered[index].id, 1);
        } else {
            this.cartService.add(new RentalProduct(this.filtered[index].originalValue, 1));
        }
    }

    public onNext(): void {
        this.next.emit();
    }

    public onSkip(): void {
        if (!this.hasGroup) {
            this.onNext();
        }
    }

    public onGridScroll(event): void {
        this.gridScrollHitTop = event.target.scrollTop === 0;
        this.gridScrollHitBottom = event.target.scrollTop === event.target.scrollHeight - event.target.clientHeight;
    }

    public toNextSlide(): void {
        if (this.slick.currentIndex < this.slick.slides.length - 1) {
            this.slick.slickGoTo(this.slick.currentIndex + 1);
        }
    }

    public toPreviousSlide(): void {
        if (this.slick.currentIndex > 0) {
            this.slick.slickGoTo(this.slick.currentIndex - 1);
        }
    }

    public onChangeView(view: GalleryViewType): void {
        this.view = view;
        this.filter = null;
        this.currentSlide = 0;
    }

    public onApplyFilter(filter: ProductEnvironment): void {
        if (filter === this.filter) {
            this.filter = null;
            return;
        }
        this.filter = filter;
    }

    public slidesTrackBy(index: number): number {
        return index;
    }

    public openDescription(item: PresentationItem<ProductType>) {
      this.showDescription = true;
      this.descriptionItem = item;
    }

    public onHideDescription() {
      this.showDescription = false;
    }
}
