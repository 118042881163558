import { Component, OnDestroy, OnInit } from '@angular/core';
import { RoutingService } from '@shared/services';
import { ProductService } from '@shared/services/product.service';
import { TimerData, TimerService } from '@shared/services/timer.service';
import { Subscription } from 'rxjs';

const RECONNECT_COOLDOWN = 120;

@Component({
    selector: 'app-disconnected',
    templateUrl: './disconnected.component.html',
    styleUrls: ['./disconnected.component.scss']
})
export class DisconnectedComponent implements OnInit, OnDestroy {

    public time: number;
    public duration: number;
    public timerTickSubscription: Subscription;

    constructor(
        private timerService: TimerService,
        private productService: ProductService,
        private routingService: RoutingService
    ) {
        this.duration = RECONNECT_COOLDOWN;
    }

    public ngOnInit(): void {
        this.startTimer();
    }

    public ngOnDestroy(): void {
        this.destroyTimer();
    }

    public attemptReconnect(): void {
        this.productService.getTypes().subscribe(() => {
            this.routingService.navigateToSplashScreen();
        }, () => {
            this.startTimer();
        });
    }

    public destroyTimer(): void {
        if (this.timerTickSubscription && !this.timerTickSubscription.closed) {
            this.timerTickSubscription.unsubscribe();
        }
    }

    public startTimer(): void {
        this.destroyTimer();
        this.timerTickSubscription = this.timerService.start(this.duration).subscribe((data: TimerData) => {
            this.time = data.time;
            if (data.complete) {
                this.attemptReconnect();
            }
        });
    }
}
