<div class="background">
</div>
<div class="home-page d-flex flex-column justify-content-between">
  <div class="logo">
    <img src="/assets/img/logo.svg" alt="RugDoctor">
  </div>
  <div class="content">
    <div class="hero">
      <h3>{{ 'HOME.TITLE' | translate }}</h3>
      <h1>{{ 'HOME.MAIN' | translate }}</h1>
      <div class="row description w-100">
        <div class="col-6 hero"><p>{{ 'HOME.DESCRIPTION' | translate}}</p></div>
        <div class="col-6 price"><h1 *ngIf="price">${{ price }}</h1></div>
      </div>
    </div>
    <div class="options">
      <div class="primary-options d-flex justify-content-center">
        <div class="btn-rug centered" (click)="onRent()">{{ 'HOME.RENT' | translate}}</div>
        <div class="btn-rug centered" (click)="routingService.navigateToReturn()">{{ 'HOME.RETURN' | translate}}</div>
      </div>
      <div class="secondary-options d-flex justify-content-center">
        <div class="btn-rug" (click)="routingService.navigateToReservation()">{{ 'HOME.PICK_UP' | translate}}</div>
        <div class="btn-rug" (click)="onRent('solution')">{{ 'HOME.CLEANING_SUPPLIES' | translate}}</div>
      </div>
      <div class="how-to d-flex justify-content-between">
        <div class="btn-rug" (click)="routingService.navigateToHowToUseScreen()">{{ 'TUTORIAL.HOW_TO_USE.TITLE' | translate}}</div>
        <div class="btn-rug" (click)="routingService.navigateToHowToRentScreen()">{{ 'TUTORIAL.HOW_TO_RENT.TITLE' | translate}}</div>
      </div>
      <div class="contact d-flex justify-content-between">
        <!--     TODO (877) 343-0588 is correct but admin should be able to change it-->
        <!--     TODO 8774704111 = mrent is correct but admin should be able to change it-->
              <p>For assistance call: (877) 343-0588</p>
              <p>Kiosk JDE: {{kioskJde ? kioskJde : '/'}}</p>
            </div>
    </div>
  </div>

</div>

<app-admin-link></app-admin-link>
